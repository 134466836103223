/**
 * Checks a specified value within an array.
 *
 * @param value The value to search for.
 * @param array The collection to check.
 * @returns Returns true if value is found, else false.
 */
export function inArray<T>(value: T, array: Array<T>): boolean {
  return array.includes(value);
}

/**
 * Replaces line breaks in plain text to <br /> tags.
 *
 * The behaviour of Browser and Node.js are different.
 *
 * In the case of browser:
 *
 * ```
 * "\n".replace('\n', '<br>')
 * // => "<br>"
 *
 * "\n".replace('\\n', '<br>')
 * // => "
 * "
 * ```
 *
 * Node.js:
 *
 * ```
 * > "\n".replace('\\n', '<br>')
 * '\n'
 *
 * > "\n".replace('\\n', '<br>')
 * '\n'
 * ```
 *
 * This function has two regex formats.
 * The `\\n` format is for browser.
 *
 * @param message
 * @returns Returns string with html formatted line breaks
 */
export function insertBreak(message: string): string {
  return message.replace(/\r?\n|\\n/g, '<br>');
}

/**
 * Insert commas in a numeric string.
 *
 * @param value
 */
export function insertComma(value?: string): string {
  if (!value) {
    return '';
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Insert yen commas in a numeric string.
 *
 * @param value
 */
export function insertYenComma(value?: string): string {
  if (!value) {
    return '';
  }
  return '¥' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
