import { Variant } from '~/utils/variant';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const runtimeConfig = useRuntimeConfig();

  let variant = null;

  if (runtimeConfig.public.APP_ENV !== 'production' && route.query.variant && typeof route.query.variant === 'string') {
    if (route.query.variant === '0') {
      variant = Variant.OFF;
    }
    if (route.query.variant === '1') {
      variant = Variant.ON;
    }
  }

  nuxtApp.vueApp.config.globalProperties.$devVariant = variant;
});
