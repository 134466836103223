import validate from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/middleware/redirect.global.ts";
import manifest_45route_45rule from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  detail: () => import("/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/middleware/detail.ts")
}