export default defineNuxtRouteMiddleware((to) => {
  if (to.path !== '/info') {
    const { isSafari, isAndroid, isIos, userAgent } = useDevice();

    if (isSafari && userAgent?.includes('Mercari_r')) {
      return;
    }
    if (isIos || isAndroid) {
      return;
    }

    return navigateTo('/info', { replace: true });
  }
});
