import { DateTime } from 'luxon';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();

  let currentDateTime = DateTime.fromMillis(Date.now());

  if (process.env.APP_ENV !== 'production' && route.query.date && typeof route.query.date === 'string') {
    currentDateTime = DateTime.fromISO(route.query.date);
  }

  nuxtApp.vueApp.config.globalProperties.$currentDateTime = currentDateTime;
});
