import revive_payload_client_t4hdlIo5Aa from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lCUIJ7wwT0 from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IQV81H53gs from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_JbBEomV7IC from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_4888TRFpKc from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.2.2_vue@3.3.13_typescript@5.2.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/.nuxt/components.plugin.mjs";
import prefetch_client_9icNJ5t0LL from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vue3_t3xjrh7uzx from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/@merpay+nuxt-api-client@1.3.0_rollup@4.18.0/node_modules/@merpay/nuxt-api-client/lib/runtime/plugin-vue3.mjs";
import plugin_hG5Vcuyj8t from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_i7B3bjJfxi from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_U4fsJ7QdpB from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/node_modules/.pnpm/nuxt@3.8.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.5.1_@unocss+reset@0._bugsni7ooazjt3zhfd4l45wwda/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import apiClient_mOiHigfXro from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/apiClient.ts";
import nativeBridge_client_H26jnr6SYg from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/nativeBridge.client.ts";
import sentry_client_KAXFuL2wum from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/sentry.client.ts";
import carousel_client_2SlSRKIyKz from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/carousel.client.ts";
import currentDateTime_AYW5yChfXX from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/currentDateTime.ts";
import devVariant_1VLEkugI0N from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/devVariant.ts";
import modal_saNsw8xGBA from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/modal.ts";
import animation_client_bb0jzVo6st from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/animation.client.ts";
import touchEvents_client_0RXeZuQABJ from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/plugins/touchEvents.client.ts";
export default [
  revive_payload_client_t4hdlIo5Aa,
  unhead_lCUIJ7wwT0,
  router_IQV81H53gs,
  payload_client_JbBEomV7IC,
  plugin_vue3_4888TRFpKc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9icNJ5t0LL,
  plugin_vue3_t3xjrh7uzx,
  plugin_hG5Vcuyj8t,
  chunk_reload_client_i7B3bjJfxi,
  check_outdated_build_client_U4fsJ7QdpB,
  apiClient_mOiHigfXro,
  nativeBridge_client_H26jnr6SYg,
  sentry_client_KAXFuL2wum,
  carousel_client_2SlSRKIyKz,
  currentDateTime_AYW5yChfXX,
  devVariant_1VLEkugI0N,
  modal_saNsw8xGBA,
  animation_client_bb0jzVo6st,
  touchEvents_client_0RXeZuQABJ
]