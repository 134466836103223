export enum LogLevel {
  DEBUG = 1,
  INFO,
  WARN,
  ERROR,
  FATAL,
}

const getLogLevel = (env: string | undefined) => {
  switch (env) {
    case 'production':
      return 4;
    case 'development':
      return 1;
    default:
      return 1;
  }
};

const logLevel = getLogLevel(process.env.APP_ENV);

export default class Logger {
  static debug(...args: [any?, ...any[]]) {
    if (logLevel <= LogLevel.DEBUG) {
      args.unshift('[DEBUG]');
      // eslint-disable-next-line no-console
      console.debug.apply(console, args);
    }
  }

  static info(...args: [any?, ...any[]]) {
    if (logLevel <= LogLevel.INFO) {
      args.unshift('[INFO]');
      // eslint-disable-next-line no-console
      console.info.apply(console, args);
    }
  }

  static warn(...args: [any?, ...any[]]) {
    if (logLevel <= LogLevel.WARN) {
      args.unshift('[WARN]');
      // eslint-disable-next-line no-console
      console.warn.apply(console, args);
    }
  }

  static error(...args: [any?, ...any[]]) {
    if (logLevel <= LogLevel.ERROR) {
      args.unshift('[ERROR]');
      // eslint-disable-next-line no-console
      console.error.apply(console, args);
    }
  }

  static fatal(...args: [any?, ...any[]]) {
    if (logLevel <= LogLevel.FATAL) {
      args.unshift('[FATAL]');
      // eslint-disable-next-line no-console
      console.error.apply(console, args);
    }
  }
}
