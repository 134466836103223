<template>
  <main class="Error">
    <img src="~/assets/img/icon/mercari-disabled.svg" alt="" />
    <p class="Error__Text">
      {{ error.message }}
    </p>
    <div v-if="isDetailPage">
      <base-button kind="primary" @click="onClick"> 他のクーポンを見る </base-button>
    </div>
  </main>
</template>

<script lang="ts">
import type { NuxtError } from '#app';
import { defineComponent, type PropType } from 'vue';
import { ApplicationType } from 'mewkit-native-bridge';
import BaseButton from '~/components/base/BaseButton.vue';
import nativeBridge from '~/plugins/native-bridge/version.json';
import { Coupon } from '~/types/gen/mercari/platform/proto/microservices/merpay-api-jp/api/v1/api_pb';
import logger from '~/utils/logger';
import { detectWebView } from '~/utils/webview';

export default defineComponent({
  components: {
    BaseButton,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default: {
        message: 'エラーが発生しました。',
        statusCode: 500,
      },
    },
  },
  computed: {
    isDetailPage(): boolean {
      return /^\/items\/.+/.test(this.$route.path);
    },
  },
  async mounted() {
    const isWebView = detectWebView(this.$device);

    if (isWebView) {
      try {
        await this.$couponBridge.configure('', nativeBridge.version, {
          applicationType: ApplicationType.History,
          enablePreload: false,
        });
      } catch (_) {
        this.$couponBridge.notSupportedDevice = true;
        logger.error('[error] mounted() bridge does not configured.');
      }

      try {
        await this.$couponBridge.initCoupon(null, null, Coupon.FeatureType.FEATURE_TYPE_UNSPECIFIED, '');
      } catch (error) {
        logger.error('[error] mounted() bridge does not initialized, error:', error);
      }
    }
  },
  methods: {
    onClick() {
      this.$router.push('/auth');
    },
  },
});
</script>

<style scoped>
.Error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 64px 16px;
  background: #f4f4f4;
  color: #999;
}

.Error__Text {
  margin: 16px auto 64px;
}
</style>
