<template>
  <button :type="type" :disabled="disabled" :class="kindClass" class="Button" @click="handleClick($event)">
    <span class="Button__Inner"><slot /></span>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import _ from 'lodash';
import { inArray } from '~/utils';

/**
 * NOTE: The span element is needed for border styles at specific devices. (iPhone SE or iPhone 12 mini)
 *       These devices cannot render border styles correctly when user back to the coupon-web from client UI.
 *       This problem is solved by nesting button elements and apply styles to span element.
 *       JIRA url: https://mercari.atlassian.net/browse/MG-787
 */
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      default: 'button',
      validator: (value: string) => inArray(value, ['button', 'submit', 'reset']),
    },
    kind: {
      type: String,
      default: 'normal',
      validator: (value: string) => inArray(value, ['primary', 'secondary', 'normal']),
    },
  },
  computed: {
    kindClass(): string {
      return `-${this.kind}`;
    },
  },
  methods: {
    handleClick(event: MouseEvent) {
      return _.debounce(() => {
        (event.target as HTMLElement).blur();
        this.$emit('click');
      });
    },
  },
});
</script>

<style scoped>
.Button {
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 1;
}

.Button__Inner {
  display: block;
  box-sizing: border-box;
  padding: 15px 16px;
  border: solid 1px #fff;
  border-radius: 4px;
}

.-primary .Button__Inner {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: #fff;
  font-weight: bold;
}

.-secondary .Button__Inner {
  border-color: currentColor;
  background-color: #fff;
  color: var(--color-primary);
}

.-normal .Button__Inner {
  border-color: #efefef;
  background-color: transparent;
  color: #666;
}

.-primary[disabled] .Button__Inner {
  border-color: var(--color-disabled);
  background-color: var(--color-disabled);
}

.-secondary[disabled] .Button__Inner {
  border-color: currentColor;
  color: var(--color-primary-disabled);
}

.-normal[disabled] .Button__Inner {
  color: var(--color-disabled);
}

.Button.-primary:focus {
  outline-color: var(--color-primary);
}
</style>
