import type { Device } from '@nuxtjs/device/dist/runtime/types';

/**
 * Detect useragent strings is android webview or ios webview.
 *
 * @param device {Device}
 */
export function detectWebView(device: Device): boolean {
  if (device.isIos) {
    // It returns false, because there is window.webkit in Chrome on iOS.
    if (device.isChrome) {
      return false;
    }

    return Boolean(window.webkit && window.webkit.messageHandlers);
  }
  if (device.isAndroid && device.userAgent) {
    return /; wv/i.test(device.userAgent);
  }

  return false;
}
