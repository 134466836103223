import type { APIHandler, APIRequest, Interceptor } from '@merpay/api-client';
import { Env } from '~/constants/env';
import Logger from '~/utils/logger';

export class E2EInterceptor implements Interceptor {
  private env: string;
  private headers: Record<string, string>;

  constructor(env: string, headers: Record<string, string>) {
    this.env = env;
    this.headers = headers;
  }

  intercept(request: APIRequest, handler: APIHandler) {
    if (process.server) {
      if (this.env !== Env.Production) {
        const keys = Object.keys(this.headers);
        for (const key of keys) {
          // eslint-disable-next-line
          if (key.includes('service-router-')) {
            request.headerFields = Object.assign({}, request.headerFields, { [key]: this.headers[key] });
          }
        }
      }
      Logger.debug(this.headers);
    }
    return handler.handle(request);
  }
}
