import { VariantKey } from '~/constants/variantKey';
import Logger from '~/utils/logger';

export enum Variant {
  OFF = 0,
  ON = 1,
}

export function getVariant(variantKey: VariantKey, percent: number, devVariant?: Variant): Variant {
  if (devVariant !== null && (devVariant === Variant.OFF || devVariant === Variant.ON)) {
    return devVariant;
  }
  if (window && window.localStorage) {
    const segmentData = window.localStorage.getItem(variantKey);
    const max = 9999;
    let segment: number;

    if (!segmentData) {
      segment = Math.floor(Math.random() * max);

      try {
        window.localStorage.setItem(variantKey, segment.toString());
      } catch (e) {
        Logger.error(e);
      }
    } else {
      segment = Number(segmentData);
    }

    return segment < max * percent ? Variant.ON : Variant.OFF;
  }

  return Variant.OFF;
}
