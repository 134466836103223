export const appBuildAssetsDir = "/nuxt/"

export const appCdnURL = "https://static-coupon.merpay.com/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"},{"name":"robots","content":"noindex"}],"link":[],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"ja"}}

export const appBaseURL = "/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'