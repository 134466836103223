import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(({ vueApp, $config }) => {
  if ($config.public.sentryDisabled) {
    return;
  }

  Sentry.init({
    dsn: $config.public.sentryDsn as string,
    app: vueApp,
    environment: $config.public.sentryEnvironment as string,
    release: $config.public.sentryRelease as string,
  });
});
