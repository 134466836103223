import { Interceptor, APIRequest, APIHandler } from '@merpay/api-client';
import { Env } from '~/constants/env';
import { GATEWAY_PAT_HEADER_KEY, GATEWAY_MAT_COOKIE_KEY } from '~/constants/httpHeader';

export class AuthInterceptor implements Interceptor {
  private env: string;
  private headers: Record<string, string>;
  private mat: string;

  constructor(env: string, headers: Record<string, string>, mat: string) {
    this.env = env;
    this.headers = headers;
    this.mat = mat;
  }

  intercept(request: APIRequest, handler: APIHandler) {
    if (process.server) {
      if (this.env === Env.Local) {
        if (this.mat) {
          request.headerFields = {
            Cookie: `${GATEWAY_MAT_COOKIE_KEY}=${this.mat}`,
          };
        }
      } else {
        const privateAccessTokenHeader = this.headers[GATEWAY_PAT_HEADER_KEY];
        const privateAccessToken = Array.isArray(privateAccessTokenHeader)
          ? privateAccessTokenHeader[0]
          : privateAccessTokenHeader;

        // set to Authorization header if privateAccessToken exist
        if (privateAccessToken) {
          request.headerFields = {
            Authorization: privateAccessToken.replace(/^Bearer(\s)?/, ''),
          };
        }
      }
    }

    return handler.handle(request);
  }
}
