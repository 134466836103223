import { APIRequest, APIHandler, Interceptor, APIError } from '@merpay/api-client';

export class ErrorInterceptor implements Interceptor {
  intercept(request: APIRequest, handler: APIHandler) {
    return handler.handle(request).catch((error: APIError) => {
      // TODO: The $sentry module needs to be implemented, so it
      //       is commented out for now.
      // const { status, message } = error;

      // // send only 5xx error to sentry
      // if (this.context.$sentry && status && status >= 500) {
      //   this.context.$sentry.captureEvent({
      //     message,
      //   });
      // }

      throw error;
    });
  }
}
