import { CouponBridge } from './CouponBridge';
import { EventCategory } from '~/constants/eventCategory';
import { EventId } from '~/constants/eventId';
import { Coupon, CouponConfig } from '~/types/gen/mercari/platform/proto/microservices/merpay-api-jp/api/v1/api_pb';
import * as couponUtil from '~/utils/coupon';

class PascalLog {
  bridge: CouponBridge;

  constructor(bridge: CouponBridge) {
    this.bridge = bridge;
  }

  async detailBankAnnounceModalDisplay(couponKey: string | undefined, userPaymentType: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailBankAnnounceModalDisplay, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async detailCodeDisplay(
    couponKey: string | undefined,
    userPaymentType: number,
    hasTimeLimit: boolean,
    proviStatus: number,
    isMekuri: boolean,
  ) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    const eventId = isMekuri ? EventId.DetailCodeTurnOverDisplay : EventId.DetailCodeDisplay;

    return await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
      has_timelimit: hasTimeLimit,
      provi_status: proviStatus,
    });
  }

  async detailDisplay(couponKey: string, coupon: Coupon, isEnable: number, referrer: string, hasRecommend: boolean) {
    let eventId = 'merpay_coupon_detail_display';

    const lotteryState = couponUtil.getLotteryState(coupon, Date.now());
    switch (lotteryState) {
      case couponUtil.LotteryState.Drawable:
      case couponUtil.LotteryState.Retriable:
        eventId = 'merpay_coupon_lottery_detail_display';
        break;
    }

    if (lotteryState === couponUtil.LotteryState.Retriable || lotteryState === couponUtil.LotteryState.Expired) {
      isEnable = 0;
    }

    if (!hasRecommend) {
      await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
        coupon_key: couponKey,
        is_enable: isEnable,
        referer: referrer,
      });
    }
  }

  async detailFundsShortageModalDisplay(couponKey: string | undefined, userPaymentType: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailFundsShortageModalDisplay, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async detailLotteryDisplay(couponKey: string | undefined, isWin: boolean) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    const eventId = isWin ? EventId.DetailLotteryWinningDisplay : EventId.DetailLotteryMissDisplay;

    return await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
      coupon_key: couponKey,
    });
  }

  async detailLotteryTap(couponKey: string | undefined) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailLotteryTap, {
      coupon_key: couponKey,
    });
  }

  async detailLotteryTutorialDisplay(couponKey: string | undefined) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailLotteryTutorialDisplay, {
      coupon_key: couponKey,
    });
  }

  async detailMerchantLogoTap(couponKey: string, hasRecommend: boolean) {
    const eventId = hasRecommend ? EventId.DetailMerchantLogoWithRecoTap : EventId.DetailMerchantLogoTap;

    return await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
      coupon_key: couponKey,
    });
  }

  async detailNetpayUsageTap(couponKey: string | undefined) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailNetpayUsageTap, {
      coupon_key: couponKey,
    });
  }

  async detailPointShortageModalDisplay(couponKey: string | undefined, userPaymentType: number) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailPointShortageModalDisplay, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async detailPreProvisioningDisplay(
    couponKey: string | undefined,
    couponConfig: CouponConfig,
    isWebView: boolean,
    isEnable: boolean,
  ) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    const campaignConfig = couponConfig.nazcaProvisioningCampaignConfig;

    if (couponConfig && campaignConfig && campaignConfig.isActive && isWebView) {
      return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailPreProvisioningAddPointDisplay, {
        coupon_key: couponKey,
        is_enable: isEnable,
        referer: document.referrer,
      });
    }
    if (isWebView) {
      return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailPreProvisioningDisplay, {
        coupon_key: couponKey,
        is_enable: isEnable,
        referer: document.referrer,
      });
    }

    return Promise.resolve({ success: false });
  }

  async detailSettingProvisioningStartTap(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailSettingProvisioningStartTap, {
      coupon_key: couponKey,
    });
  }

  async detailUseTap(couponKey: string | undefined, hasRecommend: boolean) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    const eventId = hasRecommend ? EventId.DetailUseWithRecoTap : EventId.DetailUseTap;

    return await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
      coupon_key: couponKey,
    });
  }

  async listTopLogoTap(isFiltered: number, tappedBrandId: string, filteredBrandId?: string) {
    const props = {
      is_filtered: isFiltered,
      tapped_brand_id: tappedBrandId,
      filtered_brand_id: filteredBrandId,
    };

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ListTopLogoTap, props);
  }

  async listFilterCloseTap(brandId: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ListFilterCloseTap, {
      filtered_brand_id: brandId,
    });
  }

  async listFilterNearbyTap(brandId: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ListFilterNearbyTap, {
      filtered_brand_id: brandId,
    });
  }

  async listTopDisplay(
    isSupportProvisioning: number,
    cntWinningCoupons: number,
    isFiltered: number,
    cntLogoDisplay: number,
    nearbyShopDisplay: number,
    filteredBrandId?: string,
  ) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ListTopDisplay, {
      is_support_provisioning: isSupportProvisioning,
      cnt_winning_coupons: cntWinningCoupons,
      is_filtered: isFiltered,
      cnt_logo_display: cntLogoDisplay,
      filtered_brand_id: filteredBrandId,
      nearby_shop_display: nearbyShopDisplay,
    });
  }

  async listDisplayView(referer: string | (string | null)[]) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ListDisplayView, {
      referer,
    });
  }

  async listTap(props: { [key: string]: any }) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ListTap, props);
  }

  async codeProviStartTap(couponKey: string, userPaymentType: number) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.CodeProviStartTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async codeCloseTap(couponKey: string | undefined, userPaymentType: number, proviStatus: number, variant: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.CodeCloseTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
      provi_status: proviStatus,
      MG_860_variant: variant,
    });
  }

  async codeDiaplay(couponKey: string | undefined, userPaymentType: number, hasTimelimit: boolean) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.CodeDiaplay, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
      has_timelimit: hasTimelimit,
    });
  }

  async codePaymentTap(props: { [key: string]: any }) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.CodePaymentTap, props);
  }

  async netPayBannerTap() {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.NetPayBannerTap, {});
  }

  async detailMerchantRechargeTap(couponKey: string, hasRecommend: boolean) {
    const eventId = hasRecommend ? EventId.DetailMerchantRechargeWithRecoTap : EventId.DetailMerchantRechargeTap;

    return await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
      coupon_key: couponKey,
    });
  }

  async detailWithRecoDisplay(
    couponKey: string | undefined,
    isEnable: number,
    referrer: string,
    isFirstView: number,
    cntRecoCoupon: number,
    isVariant: number,
  ) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailWithRecoDisplay, {
      coupon_key: couponKey,
      is_enable: isEnable,
      referer: referrer,
      is_first_view: isFirstView,
      cnt_reco_coupon: cntRecoCoupon,
      MG_946_variant: isVariant,
    });
  }

  async recommendCouponTap(couponKey: string, recommendCouponKey: string, orderColumn: number) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.RecommendCouponTap, {
      main_coupon_key: couponKey,
      recommend_coupon_key: recommendCouponKey,
      order_column: orderColumn,
    });
  }

  async recomendHeadTapFloatDisplay(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.RecomendHeadTapFloatDisplay, {
      coupon_key: couponKey,
    });
  }

  async recomendScrollFloatDisplay(couponKey: string) {
    await this.bridge.sendEventLog(EventCategory.Coupon, EventId.RecomendScrollFloatDisplay, {
      coupon_key: couponKey,
    });
  }

  async detailRecommendHeadDisplay(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailRecommendHeadDisplay, {
      coupon_key: couponKey,
    });
  }

  async detailConditionSeeallWithRecoTap(couponKey: string, hasRecommend: boolean) {
    const eventId = hasRecommend ? EventId.DetailConditionSeeallWithRecoTap : EventId.DetailConditionSeeallTap;

    return await this.bridge.sendEventLog(EventCategory.Coupon, eventId, {
      coupon_key: couponKey,
    });
  }

  async fundsShortageModalDisplay(couponKey: string | undefined, userPaymentType: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.FundsShortageModalDisplay, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async fundsShortageModalCloseTap(couponKey: string | undefined, userPaymentType: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.FundsShortageModalCloseTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async bankAnnounceModalTap(couponKey: string | undefined, userPaymentType: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.BankAnnounceModalTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async bankAnnounceModalCloseTap(couponKey: string | undefined, userPaymentType: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.BankAnnounceModalCloseTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async pointShortageModalTap(couponKey: string, userPaymentType: number) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.PointShortageModalTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async pointShortageModalCloseTap(couponKey: string, userPaymentType: number) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.PointShortageModalCloseTap, {
      coupon_key: couponKey,
      user_payment_type: userPaymentType,
    });
  }

  async lotteryTutorialDisplay(couponKey: string, page: number) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, `merpay_coupon_lottery_tutorial_${page}_display`, {
      coupon_key: couponKey,
    });
  }

  async lotteryTutorialOkTap(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.LotteryTutorialOkTap, {
      coupon_key: couponKey,
    });
  }

  async lotteryTutorialCloseTap(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.LotteryTutorialCloseTap, {
      coupon_key: couponKey,
    });
  }

  async changeSpTap(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ChangeSpTap, {
      coupon_key: couponKey,
    });
  }

  async changeSpModeModalDisplay(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ChangeSpModeModalDisplay, {
      coupon_key: couponKey,
    });
  }

  async changeSpModeModalTap(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ChangeSpModeModalTap, {
      coupon_key: couponKey,
    });
  }

  async changeSpModeModalCloseTap(couponKey: string) {
    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.ChangeSpModeModalCloseTap, {
      coupon_key: couponKey,
    });
  }

  async detailSpSettingDisplay(couponKey: string | undefined, isEnable: boolean, ftaStatus: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailSpSettingDisplay, {
      coupon_key: couponKey,
      is_enable: isEnable ? 1 : 0,
      fta_status: ftaStatus,
    });
  }

  async detailSpSettingTap(couponKey: string | undefined, ftaStatus: number) {
    if (!couponKey) {
      return Promise.resolve({ success: false });
    }

    return await this.bridge.sendEventLog(EventCategory.Coupon, EventId.DetailSpSettingTap, {
      coupon_key: couponKey,
      fta_status: ftaStatus,
    });
  }
}

export { PascalLog };
