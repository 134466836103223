import { default as indexv1RMIi9LlGMeta } from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/pages/index.vue?macro=true";
import { default as infoUBJLMqXvfYMeta } from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/pages/info.vue?macro=true";
import { default as _91itemId_93gtcIf5zJ2VMeta } from "/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/pages/items/[itemId].vue?macro=true";
export default [
  {
    name: indexv1RMIi9LlGMeta?.name ?? "index",
    path: indexv1RMIi9LlGMeta?.path ?? "/",
    meta: indexv1RMIi9LlGMeta || {},
    alias: indexv1RMIi9LlGMeta?.alias || [],
    redirect: indexv1RMIi9LlGMeta?.redirect || undefined,
    component: () => import("/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infoUBJLMqXvfYMeta?.name ?? "info",
    path: infoUBJLMqXvfYMeta?.path ?? "/info",
    meta: infoUBJLMqXvfYMeta || {},
    alias: infoUBJLMqXvfYMeta?.alias || [],
    redirect: infoUBJLMqXvfYMeta?.redirect || undefined,
    component: () => import("/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/pages/info.vue").then(m => m.default || m)
  },
  {
    name: _91itemId_93gtcIf5zJ2VMeta?.name ?? "items-itemId",
    path: _91itemId_93gtcIf5zJ2VMeta?.path ?? "/items/:itemId()",
    meta: _91itemId_93gtcIf5zJ2VMeta || {},
    alias: _91itemId_93gtcIf5zJ2VMeta?.alias || [],
    redirect: _91itemId_93gtcIf5zJ2VMeta?.redirect || undefined,
    component: () => import("/runner/_work/merpay-coupon-web-jp/merpay-coupon-web-jp/modules/app/src/pages/items/[itemId].vue").then(m => m.default || m)
  }
]