import { APIRequest, APIClient, APIHandler, APIError, Interceptor, Code } from '@merpay/api-client';

interface Retry {
  count: number;
}

interface RetryableAPIRequest extends APIRequest {
  retry?: Retry;
}

export class RetryInterceptor implements Interceptor {
  private client: APIClient;
  private timeout: number;
  private maxRetry: number;

  constructor(client: APIClient, timeout: number = 3000, maxRetry: number = 3) {
    this.client = client;
    this.timeout = timeout;
    this.maxRetry = maxRetry;
  }

  intercept(request: RetryableAPIRequest, handler: APIHandler): PromiseLike<any> {
    request = this.initRequest(request);

    return handler.handle(request).catch((error: APIError) => {
      if (
        (error.code === Code.CANCELLED || error.code === Code.UNAVAILABLE || error.status === 503) &&
        request.retry!.count < this.maxRetry
      ) {
        request.retry!.count += 1;
        const delay = request.retry!.count * 500;

        return new Promise((resolve) => setTimeout(resolve, delay)).then(() => this.client.request(request));
      }

      throw error;
    });
  }

  initRequest(request: RetryableAPIRequest): RetryableAPIRequest {
    request.timeoutDuration = this.timeout;

    if (!request.retry || !request.retry.count) {
      request.retry = {
        count: 0,
      };

      return request;
    }

    return request;
  }
}
