import { AuthInterceptor } from './api-client/AuthInterceptor';
import { ErrorInterceptor } from './api-client/ErrorInterceptor';
import { RetryInterceptor } from './api-client/RetryInterceptor';
import { E2EInterceptor } from './api-client/E2EInterceptor';
import { GATEWAY_MAT_COOKIE_KEY } from '~/constants/httpHeader';

export default defineNuxtPlugin(() => {
  const { $apiClient } = useNuxtApp();
  const mat = useCookie(GATEWAY_MAT_COOKIE_KEY);
  const runtimeConfig = useRuntimeConfig();
  const headers = useRequestHeaders();

  const authInterceptor = new AuthInterceptor(runtimeConfig.public.APP_ENV, headers, mat.value);
  const errorInterceptor = new ErrorInterceptor();
  const retryInterceptor = new RetryInterceptor($apiClient);
  const e2EInterceptor = new E2EInterceptor(runtimeConfig.public.APP_ENV, headers);

  $apiClient.use(authInterceptor);
  $apiClient.use(errorInterceptor);
  $apiClient.use(retryInterceptor);
  $apiClient.use(e2EInterceptor);
});
