/* GENERATED FROM mercari/platform/proto/microservices/merpay-api-jp/api/v1/api.proto. DO NOT EDIT MANUALLY. */
/* tslint:disabled */
/* eslint-disable */

import { APIRequest, HTTPMethod } from "@merpay/protoc-gen-jsonpb-ts";

import * as google_rpc_error_details from "../../../../../../../google/rpc/error_details_pb";
import * as google_type_date from "../../../../../../../google/type/date_pb";
import * as google_type_latlng from "../../../../../../../google/type/latlng_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/passcode_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/bank_entity_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/netpayment_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_payment from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/payment_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_nfc from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/nfc_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/defpay_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/lending_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_design_system from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/design_system_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_mercari from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/mercari_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/entity_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/store_payment_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/realcard_pb";
import * as mercari_platform_proto_microservices_merpay_api_jp_api_v1_vcas from "../../../../../../../mercari/platform/proto/microservices/merpay-api-jp/api/v1/vcas_pb";

export namespace MerpayAPI {
  export class GetDashboard
    implements APIRequest<GetDashboardRequest, GetDashboardResponse> {
    _response?: GetDashboardResponse;
    path = "/v1/get_dashboard";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDashboardRequest) {}
  }

  export class GetDashboardMessage
    implements
      APIRequest<GetDashboardMessageRequest, GetDashboardMessageResponse> {
    _response?: GetDashboardMessageResponse;
    path = "/v1/get_dashboard_message";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDashboardMessageRequest) {}
  }

  export class GetMyPage
    implements APIRequest<GetMyPageRequest, GetMyPageResponse> {
    _response?: GetMyPageResponse;
    path = "/v1/get_my_page";
    method: HTTPMethod = "post";
    constructor(public parameter: GetMyPageRequest) {}
  }

  export class GetMerpaySetting
    implements APIRequest<GetMerpaySettingRequest, GetMerpaySettingResponse> {
    _response?: GetMerpaySettingResponse;
    path = "/v1/get_merpay_setting";
    method: HTTPMethod = "post";
    constructor(public parameter: GetMerpaySettingRequest) {}
  }

  export class GetLandingPageLinkForFundsAccountOpening
    implements
      APIRequest<
        GetLandingPageLinkForFundsAccountOpeningRequest,
        GetLandingPageLinkForFundsAccountOpeningResponse
      > {
    _response?: GetLandingPageLinkForFundsAccountOpeningResponse;
    path = "/v1/get_landing_page_link_for_funds_account_opening";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetLandingPageLinkForFundsAccountOpeningRequest
    ) {}
  }

  export class GetBalance
    implements APIRequest<GetBalanceRequest, GetBalanceResponse> {
    _response?: GetBalanceResponse;
    path = "/v1/get_balance";
    method: HTTPMethod = "post";
    constructor(public parameter: GetBalanceRequest) {}
  }

  export class ListCampaigns
    implements APIRequest<ListCampaignsRequest, ListCampaignsResponse> {
    _response?: ListCampaignsResponse;
    path = "/v1/list_campaigns";
    method: HTTPMethod = "post";
    constructor(public parameter: ListCampaignsRequest) {}
  }

  export class GetAfterNFCProvisioningPopup
    implements
      APIRequest<
        GetAfterNFCProvisioningPopupRequest,
        GetAfterNFCProvisioningPopupResponse
      > {
    _response?: GetAfterNFCProvisioningPopupResponse;
    path = "/v1/get_after_nfc_provisioning_popup";
    method: HTTPMethod = "post";
    constructor(public parameter: GetAfterNFCProvisioningPopupRequest) {}
  }

  export class GetAfterNFCProvisioningFTAPopup
    implements
      APIRequest<
        GetAfterNFCProvisioningFTAPopupRequest,
        GetAfterNFCProvisioningFTAPopupResponse
      > {
    _response?: GetAfterNFCProvisioningFTAPopupResponse;
    path = "/v1/get_after_nfc_provisioning_fta_popup";
    method: HTTPMethod = "post";
    constructor(public parameter: GetAfterNFCProvisioningFTAPopupRequest) {}
  }

  export class GetSalesZeroPopup
    implements APIRequest<GetSalesZeroPopupRequest, GetSalesZeroPopupResponse> {
    _response?: GetSalesZeroPopupResponse;
    path = "/v1/get_sales_zero_popup";
    method: HTTPMethod = "post";
    constructor(public parameter: GetSalesZeroPopupRequest) {}
  }

  export class GetBillsTransferBanner
    implements
      APIRequest<
        GetBillsTransferBannerRequest,
        GetBillsTransferBannerResponse
      > {
    _response?: GetBillsTransferBannerResponse;
    path = "/v1/get_bills_transfer_banner";
    method: HTTPMethod = "post";
    constructor(public parameter: GetBillsTransferBannerRequest) {}
  }

  export class ListGuides
    implements APIRequest<ListGuidesRequest, ListGuidesResponse> {
    _response?: ListGuidesResponse;
    path = "/v1/list_guides";
    method: HTTPMethod = "post";
    constructor(public parameter: ListGuidesRequest) {}
  }

  export class ListImagesByUserCondition
    implements
      APIRequest<
        ListImagesByUserConditionRequest,
        ListImagesByUserConditionResponse
      > {
    _response?: ListImagesByUserConditionResponse;
    path = "/v1/list_images_by_user_condition";
    method: HTTPMethod = "post";
    constructor(public parameter: ListImagesByUserConditionRequest) {}
  }

  export class ListCashIntroductions
    implements
      APIRequest<ListCashIntroductionsRequest, ListCashIntroductionsResponse> {
    _response?: ListCashIntroductionsResponse;
    path = "/v1/list_cash_introductions";
    method: HTTPMethod = "post";
    constructor(public parameter: ListCashIntroductionsRequest) {}
  }

  export class GetBenefitPage
    implements APIRequest<GetBenefitPageRequest, GetBenefitPageResponse> {
    _response?: GetBenefitPageResponse;
    path = "/v1/get_benefit_page";
    method: HTTPMethod = "post";
    constructor(public parameter: GetBenefitPageRequest) {}
  }

  export class CreateEntryTypeCampaignEntry
    implements
      APIRequest<
        CreateEntryTypeCampaignEntryRequest,
        CreateEntryTypeCampaignEntryResponse
      > {
    _response?: CreateEntryTypeCampaignEntryResponse;
    path = "/v1/create_entry_type_campaign_entry";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateEntryTypeCampaignEntryRequest) {}
  }

  export class GetRealcardInitialActivationBenefitMessage
    implements
      APIRequest<
        GetRealcardInitialActivationBenefitMessageRequest,
        GetRealcardInitialActivationBenefitMessageResponse
      > {
    _response?: GetRealcardInitialActivationBenefitMessageResponse;
    path = "/v1/get_realcard_initial_activation_benefit_message";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetRealcardInitialActivationBenefitMessageRequest
    ) {}
  }

  export class CreatePointRecharge
    implements
      APIRequest<CreatePointRechargeRequest, CreatePointRechargeResponse> {
    _response?: CreatePointRechargeResponse;
    path = "/v1/create_point_recharge";
    method: HTTPMethod = "post";
    constructor(public parameter: CreatePointRechargeRequest) {}
  }

  export class GetCodePaymentStatus
    implements
      APIRequest<GetCodePaymentStatusRequest, GetCodePaymentStatusResponse> {
    _response?: GetCodePaymentStatusResponse;
    path = "/v1/get_code_payment_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetCodePaymentStatusRequest) {}
  }

  export class GetCodePaymentAvailability
    implements
      APIRequest<
        GetCodePaymentAvailabilityRequest,
        GetCodePaymentAvailabilityResponse
      > {
    _response?: GetCodePaymentAvailabilityResponse;
    path = "/v1/get_code_payment_availability";
    method: HTTPMethod = "post";
    constructor(public parameter: GetCodePaymentAvailabilityRequest) {}
  }

  export class SetCodePaymentUsePasscode
    implements
      APIRequest<
        SetCodePaymentUsePasscodeRequest,
        SetCodePaymentUsePasscodeResponse
      > {
    _response?: SetCodePaymentUsePasscodeResponse;
    path = "/v1/set_code_payment_use_passcode";
    method: HTTPMethod = "post";
    constructor(public parameter: SetCodePaymentUsePasscodeRequest) {}
  }

  export class GetHasFundsAccount
    implements
      APIRequest<GetHasFundsAccountRequest, GetHasFundsAccountResponse> {
    _response?: GetHasFundsAccountResponse;
    path = "/v1/get_has_funds_account";
    method: HTTPMethod = "post";
    constructor(public parameter: GetHasFundsAccountRequest) {}
  }

  export class GetUserEvent
    implements APIRequest<GetUserEventRequest, GetUserEventResponse> {
    _response?: GetUserEventResponse;
    path = "/v1/get_user_event";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserEventRequest) {}
  }

  export class AgreeToTermsAndConditionsByScene
    implements
      APIRequest<
        AgreeToTermsAndConditionsBySceneRequest,
        AgreeToTermsAndConditionsBySceneResponse
      > {
    _response?: AgreeToTermsAndConditionsBySceneResponse;
    path = "/v1/agree_to_terms_and_conditions_by_scene";
    method: HTTPMethod = "post";
    constructor(public parameter: AgreeToTermsAndConditionsBySceneRequest) {}
  }

  export class AgreeToTermsAndConditionsByTypes
    implements
      APIRequest<
        AgreeToTermsAndConditionsByTypesRequest,
        AgreeToTermsAndConditionsByTypesResponse
      > {
    _response?: AgreeToTermsAndConditionsByTypesResponse;
    path = "/v1/agree_to_terms_and_conditions_by_types";
    method: HTTPMethod = "post";
    constructor(public parameter: AgreeToTermsAndConditionsByTypesRequest) {}
  }

  export class ListTermsAndConditionsToAgree
    implements
      APIRequest<
        ListTermsAndConditionsToAgreeRequest,
        ListTermsAndConditionsToAgreeResponse
      > {
    _response?: ListTermsAndConditionsToAgreeResponse;
    path = "/v1/list_terms_and_conditions_to_agree";
    method: HTTPMethod = "post";
    constructor(public parameter: ListTermsAndConditionsToAgreeRequest) {}
  }

  export class ListTermsAndConditionsToMustAgreeForFeature
    implements
      APIRequest<
        ListTermsAndConditionsToMustAgreeForFeatureRequest,
        ListTermsAndConditionsToMustAgreeForFeatureResponse
      > {
    _response?: ListTermsAndConditionsToMustAgreeForFeatureResponse;
    path = "/v1/list_terms_and_conditions_to_must_agree_for_feature";
    method: HTTPMethod = "get";
    constructor(
      public parameter: ListTermsAndConditionsToMustAgreeForFeatureRequest
    ) {}
  }

  export class ListFundsHistories
    implements
      APIRequest<ListFundsHistoriesRequest, ListFundsHistoriesResponse> {
    _response?: ListFundsHistoriesResponse;
    path = "/v1/list_funds_histories";
    method: HTTPMethod = "post";
    constructor(public parameter: ListFundsHistoriesRequest) {}
  }

  export class GetFundsHistory
    implements APIRequest<GetFundsHistoryRequest, GetFundsHistoryResponse> {
    _response?: GetFundsHistoryResponse;
    path = "/v1/get_funds_history";
    method: HTTPMethod = "post";
    constructor(public parameter: GetFundsHistoryRequest) {}
  }

  export class ListPointHistories
    implements
      APIRequest<ListPointHistoriesRequest, ListPointHistoriesResponse> {
    _response?: ListPointHistoriesResponse;
    path = "/v1/list_point_histories";
    method: HTTPMethod = "post";
    constructor(public parameter: ListPointHistoriesRequest) {}
  }

  export class GetPointHistory
    implements APIRequest<GetPointHistoryRequest, GetPointHistoryResponse> {
    _response?: GetPointHistoryResponse;
    path = "/v1/get_point_history";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPointHistoryRequest) {}
  }

  export class GetDeferredHistory
    implements
      APIRequest<GetDeferredHistoryRequest, GetDeferredHistoryResponse> {
    _response?: GetDeferredHistoryResponse;
    path = "/v1/get_deferred_history";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDeferredHistoryRequest) {}
  }

  export class SearchPointHistoryByMetadataHistoryKey
    implements
      APIRequest<
        SearchPointHistoryByMetadataHistoryKeyRequest,
        GetPointHistoryResponse
      > {
    _response?: GetPointHistoryResponse;
    path = "/v1/search_point_history_by_metadata_history_key";
    method: HTTPMethod = "post";
    constructor(
      public parameter: SearchPointHistoryByMetadataHistoryKeyRequest
    ) {}
  }

  export class ListPointExpiryDates
    implements
      APIRequest<ListPointExpiryDatesRequest, ListPointExpiryDatesResponse> {
    _response?: ListPointExpiryDatesResponse;
    path = "/v1/list_point_expiry_dates";
    method: HTTPMethod = "post";
    constructor(public parameter: ListPointExpiryDatesRequest) {}
  }

  export class ListSalesExpiryDates
    implements
      APIRequest<ListSalesExpiryDatesRequest, ListSalesExpiryDatesResponse> {
    _response?: ListSalesExpiryDatesResponse;
    path = "/v1/list_sales_expiry_dates";
    method: HTTPMethod = "post";
    constructor(public parameter: ListSalesExpiryDatesRequest) {}
  }

  export class GetActionableHistorySummary
    implements
      APIRequest<
        GetActionableHistorySummaryRequest,
        GetActionableHistorySummaryResponse
      > {
    _response?: GetActionableHistorySummaryResponse;
    path = "/v1/get_actionable_history_summary";
    method: HTTPMethod = "post";
    constructor(public parameter: GetActionableHistorySummaryRequest) {}
  }

  export class GetActionableHistory
    implements
      APIRequest<GetActionableHistoryRequest, GetActionableHistoryResponse> {
    _response?: GetActionableHistoryResponse;
    path = "/v1/get_actionable_history";
    method: HTTPMethod = "post";
    constructor(public parameter: GetActionableHistoryRequest) {}
  }

  export class ListActionableHistories
    implements
      APIRequest<
        ListActionableHistoriesRequest,
        ListActionableHistoriesResponse
      > {
    _response?: ListActionableHistoriesResponse;
    path = "/v1/list_actionable_histories";
    method: HTTPMethod = "post";
    constructor(public parameter: ListActionableHistoriesRequest) {}
  }

  export class GetPaymentAmountSummary
    implements
      APIRequest<
        GetPaymentAmountSummaryRequest,
        GetPaymentAmountSummaryResponse
      > {
    _response?: GetPaymentAmountSummaryResponse;
    path = "/v1/get_payment_amount_summary";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPaymentAmountSummaryRequest) {}
  }

  export class UpdatePaymentAmountLimitation
    implements
      APIRequest<
        UpdatePaymentAmountLimitationRequest,
        UpdatePaymentAmountLimitationResponse
      > {
    _response?: UpdatePaymentAmountLimitationResponse;
    path = "/v1/update_payment_amount_limitation";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdatePaymentAmountLimitationRequest) {}
  }

  export class GetPasscodeStatus
    implements
      APIRequest<
        GetPasscodeStatusRequest,
        mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode.PasscodeStatus
      > {
    _response?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode.PasscodeStatus;
    path = "/v1/get_passcode_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPasscodeStatusRequest) {}
  }

  export class CreatePasscode
    implements APIRequest<CreatePasscodeRequest, CreatePasscodeResponse> {
    _response?: CreatePasscodeResponse;
    path = "/v1/create_passcode";
    method: HTTPMethod = "post";
    constructor(public parameter: CreatePasscodeRequest) {}
  }

  export class UpdatePasscode
    implements APIRequest<UpdatePasscodeRequest, UpdatePasscodeResponse> {
    _response?: UpdatePasscodeResponse;
    path = "/v1/update_passcode";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdatePasscodeRequest) {}
  }

  export class CheckPasscodeFormat
    implements
      APIRequest<CheckPasscodeFormatRequest, CheckPasscodeFormatResponse> {
    _response?: CheckPasscodeFormatResponse;
    path = "/v1/check_passcode_format";
    method: HTTPMethod = "post";
    constructor(public parameter: CheckPasscodeFormatRequest) {}
  }

  export class VerifyPasscode
    implements APIRequest<VerifyPasscodeRequest, VerifyPasscodeResponse> {
    _response?: VerifyPasscodeResponse;
    path = "/v1/verify_passcode";
    method: HTTPMethod = "post";
    constructor(public parameter: VerifyPasscodeRequest) {}
  }

  export class SendPasscodeResetOTP
    implements
      APIRequest<SendPasscodeResetOTPRequest, SendPasscodeResetOTPResponse> {
    _response?: SendPasscodeResetOTPResponse;
    path = "/v1/send_passcode_reset_otp";
    method: HTTPMethod = "post";
    constructor(public parameter: SendPasscodeResetOTPRequest) {}
  }

  export class VerifyPasscodeResetOTP
    implements
      APIRequest<
        VerifyPasscodeResetOTPRequest,
        VerifyPasscodeResetOTPResponse
      > {
    _response?: VerifyPasscodeResetOTPResponse;
    path = "/v1/verify_passcode_reset_otp";
    method: HTTPMethod = "post";
    constructor(public parameter: VerifyPasscodeResetOTPRequest) {}
  }

  export class ResetPasscodeWithOTP
    implements
      APIRequest<ResetPasscodeWithOTPRequest, ResetPasscodeWithOTPResponse> {
    _response?: ResetPasscodeWithOTPResponse;
    path = "/v1/reset_passcode_with_otp";
    method: HTTPMethod = "post";
    constructor(public parameter: ResetPasscodeWithOTPRequest) {}
  }

  export class GetPasscodeSettings
    implements
      APIRequest<GetPasscodeSettingsRequest, GetPasscodeSettingsResponse> {
    _response?: GetPasscodeSettingsResponse;
    path = "/v1/get_passcode_settings";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPasscodeSettingsRequest) {}
  }

  export class RaiseError
    implements APIRequest<RaiseErrorRequest, RaiseErrorResponse> {
    _response?: RaiseErrorResponse;
    path = "/v1/raise_error";
    method: HTTPMethod = "post";
    constructor(public parameter: RaiseErrorRequest) {}
  }

  export class GetNFCMaintenanceRegistration
    implements APIRequest<GetNFCMaintenanceRegistrationRequest, {}> {
    _response?: {};
    path = "/v1/get_nfc_maintenance_registration";
    method: HTTPMethod = "post";
    constructor(public parameter: GetNFCMaintenanceRegistrationRequest) {}
  }

  export class CreateVirtualCardContract
    implements
      APIRequest<
        CreateVirtualCardContractRequest,
        CreateVirtualCardContractResponse
      > {
    _response?: CreateVirtualCardContractResponse;
    path = "/v1/create_virtual_card_contract";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateVirtualCardContractRequest) {}
  }

  export class CreateApplePayDevice
    implements
      APIRequest<CreateApplePayDeviceRequest, CreateApplePayDeviceResponse> {
    _response?: CreateApplePayDeviceResponse;
    path = "/v1/create_apple_pay_device";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateApplePayDeviceRequest) {}
  }

  export class SignAndroidNFC
    implements APIRequest<SignAndroidNFCRequest, SignAndroidNFCResponse> {
    _response?: SignAndroidNFCResponse;
    path = "/v1/sign_android_nfc";
    method: HTTPMethod = "post";
    constructor(public parameter: SignAndroidNFCRequest) {}
  }

  export class InitNFCPIN
    implements APIRequest<InitNFCPINRequest, InitNFCPINResponse> {
    _response?: InitNFCPINResponse;
    path = "/v1/init_nfc_pin";
    method: HTTPMethod = "post";
    constructor(public parameter: InitNFCPINRequest) {}
  }

  export class UpdateNFCPIN
    implements APIRequest<UpdateNFCPINRequest, UpdateNFCPINResponse> {
    _response?: UpdateNFCPINResponse;
    path = "/v1/update_nfc_pin";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateNFCPINRequest) {}
  }

  export class GetSMSStatusNFC
    implements APIRequest<GetSMSStatusNFCRequest, GetSMSStatusNFCResponse> {
    _response?: GetSMSStatusNFCResponse;
    path = "/v1/get_sms_status_nfc";
    method: HTTPMethod = "post";
    constructor(public parameter: GetSMSStatusNFCRequest) {}
  }

  export class GetNFCRegistrationStatus
    implements
      APIRequest<
        GetNFCRegistrationStatusRequest,
        GetNFCRegistrationStatusResponse
      > {
    _response?: GetNFCRegistrationStatusResponse;
    path = "/v1/get_nfc_registration_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetNFCRegistrationStatusRequest) {}
  }

  export class GetOnlinePaymentSetupInfo
    implements
      APIRequest<
        GetOnlinePaymentSetupInfoRequest,
        GetOnlinePaymentSetupInfoResponse
      > {
    _response?: GetOnlinePaymentSetupInfoResponse;
    path = "/v1/get_online_payment_setup_info";
    method: HTTPMethod = "post";
    constructor(public parameter: GetOnlinePaymentSetupInfoRequest) {}
  }

  export class ActivateOnlinePayment
    implements
      APIRequest<ActivateOnlinePaymentRequest, ActivateOnlinePaymentResponse> {
    _response?: ActivateOnlinePaymentResponse;
    path = "/v1/activate_online_payment";
    method: HTTPMethod = "post";
    constructor(public parameter: ActivateOnlinePaymentRequest) {}
  }

  export class SwitchOnlinePaymentStatus
    implements
      APIRequest<
        SwitchOnlinePaymentStatusRequest,
        SwitchOnlinePaymentStatusResponse
      > {
    _response?: SwitchOnlinePaymentStatusResponse;
    path = "/v1/switch_online_payment_status";
    method: HTTPMethod = "post";
    constructor(public parameter: SwitchOnlinePaymentStatusRequest) {}
  }

  export class RegisterCardKey
    implements APIRequest<RegisterCardKeyRequest, RegisterCardKeyResponse> {
    _response?: RegisterCardKeyResponse;
    path = "/v1/register_card_key";
    method: HTTPMethod = "post";
    constructor(public parameter: RegisterCardKeyRequest) {}
  }

  export class GetOnlinePaymentDetail
    implements
      APIRequest<
        GetOnlinePaymentDetailRequest,
        GetOnlinePaymentDetailResponse
      > {
    _response?: GetOnlinePaymentDetailResponse;
    path = "/v1/get_online_payment_detail";
    method: HTTPMethod = "post";
    constructor(public parameter: GetOnlinePaymentDetailRequest) {}
  }

  export class GetEncryptedCardURL
    implements
      APIRequest<GetEncryptedCardURLRequest, GetEncryptedCardURLResponse> {
    _response?: GetEncryptedCardURLResponse;
    path = "/v1/get_encrypted_card_url";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEncryptedCardURLRequest) {}
  }

  export class CreateOneTimeCode
    implements APIRequest<CreateOneTimeCodeRequest, CreateOneTimeCodeResponse> {
    _response?: CreateOneTimeCodeResponse;
    path = "/v1/create_one_time_code";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateOneTimeCodeRequest) {}
  }

  export class GetUserPaymentConfig
    implements APIRequest<GetUserPaymentConfigRequest, UserPaymentConfig> {
    _response?: UserPaymentConfig;
    path = "/v1/get_user_payment_config";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserPaymentConfigRequest) {}
  }

  export class UpdateUserPaymentConfigPostPayPaymentMode
    implements
      APIRequest<
        UpdateUserPaymentConfigPostPayPaymentModeRequest,
        UserPaymentConfig
      > {
    _response?: UserPaymentConfig;
    path = "/v1/update_user_payment_config_post_pay_payment_mode";
    method: HTTPMethod = "post";
    constructor(
      public parameter: UpdateUserPaymentConfigPostPayPaymentModeRequest
    ) {}
  }

  export class GetAccessoryMessageForSwitchPaymentMode
    implements
      APIRequest<
        GetAccessoryMessageForSwitchPaymentModeRequest,
        GetAccessoryMessageForSwitchPaymentModeResponse
      > {
    _response?: GetAccessoryMessageForSwitchPaymentModeResponse;
    path = "/v1/get_accessory_message_for_switch_payment_mode";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetAccessoryMessageForSwitchPaymentModeRequest
    ) {}
  }

  export class CreateStaticMPMSession
    implements
      APIRequest<
        CreateStaticMPMSessionRequest,
        CreateStaticMPMSessionResponse
      > {
    _response?: CreateStaticMPMSessionResponse;
    path = "/v1/codepayments/staticmpm/create_session";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateStaticMPMSessionRequest) {}
  }

  export class CreateStaticMPMCharge
    implements
      APIRequest<CreateStaticMPMChargeRequest, CreateStaticMPMChargeResponse> {
    _response?: CreateStaticMPMChargeResponse;
    path = "/v1/codepayments/staticmpm/create_charge";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateStaticMPMChargeRequest) {}
  }

  export class GetUserLegacyTransactionStatus
    implements
      APIRequest<
        GetUserLegacyTransactionStatusRequest,
        UserLegacyTransactionStatusResponse
      > {
    _response?: UserLegacyTransactionStatusResponse;
    path = "/v1/get_user_legacy_transaction_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserLegacyTransactionStatusRequest) {}
  }

  export class GetUserOngoingPayoutRequestStatus
    implements
      APIRequest<
        GetUserOngoingPayoutRequestStatusRequest,
        UserOngoingPayoutRequestStatus
      > {
    _response?: UserOngoingPayoutRequestStatus;
    path = "/v1/get_user_ongoing_payout_request_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserOngoingPayoutRequestStatusRequest) {}
  }

  export class GetUserIsBusinessAccountStatus
    implements
      APIRequest<
        GetUserIsBusinessAccountStatusRequest,
        UserIsBusinessAccountStatusResponse
      > {
    _response?: UserIsBusinessAccountStatusResponse;
    path = "/v1/get_user_is_business_account_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserIsBusinessAccountStatusRequest) {}
  }

  export class CreateUserPayout
    implements APIRequest<CreateUserPayoutRequest, CreateUserPayoutResponse> {
    _response?: CreateUserPayoutResponse;
    path = "/v1/create_user_payout";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateUserPayoutRequest) {}
  }

  export class CancelUserPayout
    implements APIRequest<CancelUserPayoutRequest, CancelUserPayoutResponse> {
    _response?: CancelUserPayoutResponse;
    path = "/v1/cancel_user_payout";
    method: HTTPMethod = "post";
    constructor(public parameter: CancelUserPayoutRequest) {}
  }

  export class ConfirmCreditCardThreeDomainSecurePayment
    implements
      APIRequest<
        ConfirmCreditCardThreeDomainSecurePaymentRequest,
        ConfirmCreditCardThreeDomainSecurePaymentResponse
      > {
    _response?: ConfirmCreditCardThreeDomainSecurePaymentResponse;
    path = "/v1/confirm_credit_card_three_domain_secure_payment";
    method: HTTPMethod = "post";
    constructor(
      public parameter: ConfirmCreditCardThreeDomainSecurePaymentRequest
    ) {}
  }

  export class InitializeCreditCardThreeDomainSecureForWeb
    implements
      APIRequest<
        InitializeCreditCardThreeDomainSecureForWebRequest,
        InitializeCreditCardThreeDomainSecureForWebResponse
      > {
    _response?: InitializeCreditCardThreeDomainSecureForWebResponse;
    path = "/v1/initialize_credit_card_three_domain_secure_for_web";
    method: HTTPMethod = "post";
    constructor(
      public parameter: InitializeCreditCardThreeDomainSecureForWebRequest
    ) {}
  }

  export class ListUserBankAccounts
    implements
      APIRequest<ListUserBankAccountsRequest, ListUserBankAccountsResponse> {
    _response?: ListUserBankAccountsResponse;
    path = "/v1/list_user_bank_accounts";
    method: HTTPMethod = "post";
    constructor(public parameter: ListUserBankAccountsRequest) {}
  }

  export class GetPersonalInformationForBankRegistration
    implements
      APIRequest<
        GetPersonalInformationForBankRegistrationRequest,
        PersonalInformationForBankRegistration
      > {
    _response?: PersonalInformationForBankRegistration;
    path = "/v1/get_personal_information_for_bank_registration";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetPersonalInformationForBankRegistrationRequest
    ) {}
  }

  export class GetUserDeletableStatus
    implements APIRequest<GetUserDeletableStatusRequest, UserDeletableStatus> {
    _response?: UserDeletableStatus;
    path = "/v1/get_user_deletable_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserDeletableStatusRequest) {}
  }

  export class CreateBankAccountContract
    implements
      APIRequest<
        CreateBankAccountContractRequest,
        CreateBankAccountContractResponse
      > {
    _response?: CreateBankAccountContractResponse;
    path = "/v1/create_bank_account_contract";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateBankAccountContractRequest) {}
  }

  export class ListBanks
    implements APIRequest<ListBanksRequest, ListBanksResponse> {
    _response?: ListBanksResponse;
    path = "/v1/list_banks";
    method: HTTPMethod = "post";
    constructor(public parameter: ListBanksRequest) {}
  }

  export class ListBankBranches
    implements APIRequest<ListBankBranchesRequest, ListBankBranchesResponse> {
    _response?: ListBankBranchesResponse;
    path = "/v1/list_bank_branches";
    method: HTTPMethod = "post";
    constructor(public parameter: ListBankBranchesRequest) {}
  }

  export class DeleteUserBankAccount
    implements APIRequest<DeleteUserBankAccountRequest, {}> {
    _response?: {};
    path = "/v1/delete_user_bank_account";
    method: HTTPMethod = "post";
    constructor(public parameter: DeleteUserBankAccountRequest) {}
  }

  export class SetDefaultBankAccount
    implements APIRequest<SetDefaultBankAccountRequest, {}> {
    _response?: {};
    path = "/v1/set_default_bank_account";
    method: HTTPMethod = "post";
    constructor(public parameter: SetDefaultBankAccountRequest) {}
  }

  export class CreateFundsRecharge
    implements
      APIRequest<
        CreateFundsRechargeRequest,
        mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.FundsRecharge
      > {
    _response?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.FundsRecharge;
    path = "/v1/create_funds_recharge";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateFundsRechargeRequest) {}
  }

  export class CreateBalanceWithdrawal
    implements
      APIRequest<
        CreateBalanceWithdrawalRequest,
        mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BalanceWithdrawal
      > {
    _response?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BalanceWithdrawal;
    path = "/v1/create_balance_withdrawal";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateBalanceWithdrawalRequest) {}
  }

  export class GetFundsRechargeStatus
    implements
      APIRequest<
        GetFundsRechargeStatusRequest,
        GetFundsRechargeStatusResponse
      > {
    _response?: GetFundsRechargeStatusResponse;
    path = "/v1/get_funds_recharge_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetFundsRechargeStatusRequest) {}
  }

  export class GetBalanceWithdrawal
    implements
      APIRequest<
        GetBalanceWithdrawalRequest,
        mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BalanceWithdrawal
      > {
    _response?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BalanceWithdrawal;
    path = "/v1/get_balance_withdrawal";
    method: HTTPMethod = "post";
    constructor(public parameter: GetBalanceWithdrawalRequest) {}
  }

  export class GetFundsRechargeOptions
    implements
      APIRequest<
        GetFundsRechargeOptionsRequest,
        GetFundsRechargeOptionsResponse
      > {
    _response?: GetFundsRechargeOptionsResponse;
    path = "/v1/get_funds_recharge_options";
    method: HTTPMethod = "post";
    constructor(public parameter: GetFundsRechargeOptionsRequest) {}
  }

  export class GetAddressFromZipCode
    implements APIRequest<GetAddressFromZipCodeRequest, Address> {
    _response?: Address;
    path = "/v1/get_address_from_zip_code";
    method: HTTPMethod = "post";
    constructor(public parameter: GetAddressFromZipCodeRequest) {}
  }

  export class ListUserRechargeProviders
    implements
      APIRequest<
        ListUserRechargeProvidersRequest,
        ListUserRechargeProvidersResponse
      > {
    _response?: ListUserRechargeProvidersResponse;
    path = "/v1/list_user_recharge_providers";
    method: HTTPMethod = "post";
    constructor(public parameter: ListUserRechargeProvidersRequest) {}
  }

  export class ListUserPayoutSchedules
    implements
      APIRequest<
        ListUserPayoutSchedulesRequest,
        ListUserPayoutSchedulesResponse
      > {
    _response?: ListUserPayoutSchedulesResponse;
    path = "/v1/list_user_payout_schedules";
    method: HTTPMethod = "post";
    constructor(public parameter: ListUserPayoutSchedulesRequest) {}
  }

  export class GetUserPayoutInformation
    implements
      APIRequest<
        GetUserPayoutInformationRequest,
        GetUserPayoutInformationResponse
      > {
    _response?: GetUserPayoutInformationResponse;
    path = "/v1/get_user_payout_information";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserPayoutInformationRequest) {}
  }

  export class GetUserPayoutBankAccount
    implements
      APIRequest<
        GetUserPayoutBankAccountRequest,
        GetUserPayoutBankAccountResponse
      > {
    _response?: GetUserPayoutBankAccountResponse;
    path = "/v1/get_user_payout_bank_account";
    method: HTTPMethod = "post";
    constructor(public parameter: GetUserPayoutBankAccountRequest) {}
  }

  export class SetUserPayoutBankAccount
    implements
      APIRequest<
        SetUserPayoutBankAccountRequest,
        SetUserPayoutBankAccountResponse
      > {
    _response?: SetUserPayoutBankAccountResponse;
    path = "/v1/set_user_payout_bank_account";
    method: HTTPMethod = "post";
    constructor(public parameter: SetUserPayoutBankAccountRequest) {}
  }

  export class ListUserPayouts
    implements APIRequest<ListUserPayoutsRequest, ListUserPayoutsResponse> {
    _response?: ListUserPayoutsResponse;
    path = "/v1/list_user_payouts";
    method: HTTPMethod = "post";
    constructor(public parameter: ListUserPayoutsRequest) {}
  }

  export class ListUserPayoutBanks
    implements
      APIRequest<ListUserPayoutBanksRequest, ListUserPayoutBanksResponse> {
    _response?: ListUserPayoutBanksResponse;
    path = "/v1/list_user_payout_banks";
    method: HTTPMethod = "post";
    constructor(public parameter: ListUserPayoutBanksRequest) {}
  }

  export class RedeemQRCodeCampaign
    implements
      APIRequest<RedeemQRCodeCampaignRequest, RedeemQRCodeCampaignResponse> {
    _response?: RedeemQRCodeCampaignResponse;
    path = "/v1/redeem_qr_code_campaign";
    method: HTTPMethod = "post";
    constructor(public parameter: RedeemQRCodeCampaignRequest) {}
  }

  export class ListCoupons
    implements APIRequest<ListCouponsRequest, ListCouponsResponse> {
    _response?: ListCouponsResponse;
    path = "/v1/list_coupons";
    method: HTTPMethod = "post";
    constructor(public parameter: ListCouponsRequest) {}
  }

  export class GetCoupon
    implements APIRequest<GetCouponRequest, GetCouponResponse> {
    _response?: GetCouponResponse;
    path = "/v1/get_coupon";
    method: HTTPMethod = "post";
    constructor(public parameter: GetCouponRequest) {}
  }

  export class IssueCoupon
    implements APIRequest<IssueCouponRequest, IssueCouponResponse> {
    _response?: IssueCouponResponse;
    path = "/v1/issue_coupon";
    method: HTTPMethod = "post";
    constructor(public parameter: IssueCouponRequest) {}
  }

  export class GetPopupCoupon
    implements APIRequest<GetPopupCouponRequest, GetPopupCouponResponse> {
    _response?: GetPopupCouponResponse;
    path = "/v1/popup_coupon";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPopupCouponRequest) {}
  }

  export class ListDashboardCoupons
    implements
      APIRequest<ListDashboardCouponsRequest, ListDashboardCouponsResponse> {
    _response?: ListDashboardCouponsResponse;
    path = "/v1/list_dashboard_coupons";
    method: HTTPMethod = "post";
    constructor(public parameter: ListDashboardCouponsRequest) {}
  }

  export class DrawCoupon
    implements APIRequest<DrawCouponRequest, DrawCouponResponse> {
    _response?: DrawCouponResponse;
    path = "/v1/draw_coupon";
    method: HTTPMethod = "post";
    constructor(public parameter: DrawCouponRequest) {}
  }

  export class GetCouponCampaign201912Status
    implements
      APIRequest<
        GetCouponCampaign201912StatusRequest,
        GetCouponCampaign201912StatusResponse
      > {
    _response?: GetCouponCampaign201912StatusResponse;
    path = "/v1/get_coupon_campaign_201912_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetCouponCampaign201912StatusRequest) {}
  }

  export class GetCouponCampaign202003Status
    implements
      APIRequest<
        GetCouponCampaign202003StatusRequest,
        GetCouponCampaign202003StatusResponse
      > {
    _response?: GetCouponCampaign202003StatusResponse;
    path = "/v1/get_coupon_campaign_202003_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetCouponCampaign202003StatusRequest) {}
  }

  export class GetMercariHomeHeaderState
    implements
      APIRequest<
        GetMercariHomeHeaderStateRequest,
        GetMercariHomeHeaderStateResponse
      > {
    _response?: GetMercariHomeHeaderStateResponse;
    path = "/v1/get_mercari_home_header_state";
    method: HTTPMethod = "post";
    constructor(public parameter: GetMercariHomeHeaderStateRequest) {}
  }

  export class GetOuterPayRecExperimentItem
    implements
      APIRequest<
        GetOuterPayRecExperimentItemRequest,
        GetOuterPayRecExperimentItemResponse
      > {
    _response?: GetOuterPayRecExperimentItemResponse;
    path = "/v1/get_outer_pay_rec_experiment_item";
    method: HTTPMethod = "post";
    constructor(public parameter: GetOuterPayRecExperimentItemRequest) {}
  }

  export class GetDeferredPaymentUser
    implements
      APIRequest<
        GetDeferredPaymentUserRequest,
        GetDeferredPaymentUserResponse
      > {
    _response?: GetDeferredPaymentUserResponse;
    path = "/v1/defer/get_user";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDeferredPaymentUserRequest) {}
  }

  export class GetDeferredPaymentBills
    implements
      APIRequest<
        GetDeferredPaymentBillsRequest,
        GetDeferredPaymentBillsResponse
      > {
    _response?: GetDeferredPaymentBillsResponse;
    path = "/v1/defer/get_bills";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDeferredPaymentBillsRequest) {}
  }

  export class GetDeferredPaymentRepayment
    implements
      APIRequest<
        GetDeferredPaymentRepaymentRequest,
        GetDeferredPaymentRepaymentResponse
      > {
    _response?: GetDeferredPaymentRepaymentResponse;
    path = "/v1/defer/get_repayment";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDeferredPaymentRepaymentRequest) {}
  }

  export class PrepareDeferredPaymentRepayment
    implements
      APIRequest<
        PrepareDeferredPaymentRepaymentRequest,
        PrepareDeferredPaymentRepaymentResponse
      > {
    _response?: PrepareDeferredPaymentRepaymentResponse;
    path = "/v1/defer/prepare_repayment";
    method: HTTPMethod = "post";
    constructor(public parameter: PrepareDeferredPaymentRepaymentRequest) {}
  }

  export class GetDeferredPaymentRepaymentReminder
    implements
      APIRequest<
        GetDeferredPaymentRepaymentReminderRequest,
        GetDeferredPaymentRepaymentReminderResponse
      > {
    _response?: GetDeferredPaymentRepaymentReminderResponse;
    path = "/v1/get_deferred_payment_repayment_reminder";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDeferredPaymentRepaymentReminderRequest) {}
  }

  export class CreateDeferredPaymentRepayment
    implements
      APIRequest<
        CreateDeferredPaymentRepaymentRequest,
        CreateDeferredPaymentRepaymentResponse
      > {
    _response?: CreateDeferredPaymentRepaymentResponse;
    path = "/v1/defer/create_repayment";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateDeferredPaymentRepaymentRequest) {}
  }

  export class UpdateDeferredPaymentRepayment
    implements
      APIRequest<
        UpdateDeferredPaymentRepaymentRequest,
        UpdateDeferredPaymentRepaymentResponse
      > {
    _response?: UpdateDeferredPaymentRepaymentResponse;
    path = "/v1/defer/update_repayment";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateDeferredPaymentRepaymentRequest) {}
  }

  export class UpdateDeferredPaymentScheduledRepaymentSetting
    implements
      APIRequest<
        UpdateDeferredPaymentScheduledRepaymentSettingRequest,
        UpdateDeferredPaymentScheduledRepaymentSettingResponse
      > {
    _response?: UpdateDeferredPaymentScheduledRepaymentSettingResponse;
    path = "/v1/defer/update_scheduled_repayment_setting";
    method: HTTPMethod = "post";
    constructor(
      public parameter: UpdateDeferredPaymentScheduledRepaymentSettingRequest
    ) {}
  }

  export class ListCVS implements APIRequest<ListCVSRequest, ListCVSResponse> {
    _response?: ListCVSResponse;
    path = "/v1/list_cvs";
    method: HTTPMethod = "post";
    constructor(public parameter: ListCVSRequest) {}
  }

  export class GetPostPayConfig
    implements APIRequest<GetPostPayConfigRequest, GetPostPayConfigResponse> {
    _response?: GetPostPayConfigResponse;
    path = "/v1/defer/get_post_pay_config";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPostPayConfigRequest) {}
  }

  export class UpdatePostPayConfig
    implements
      APIRequest<UpdatePostPayConfigRequest, UpdatePostPayConfigResponse> {
    _response?: UpdatePostPayConfigResponse;
    path = "/v1/defer/update_post_pay_config";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdatePostPayConfigRequest) {}
  }

  export class UpdatePostPayConfigAmountLimit
    implements
      APIRequest<
        UpdatePostPayConfigAmountLimitRequest,
        UpdatePostPayConfigResponse
      > {
    _response?: UpdatePostPayConfigResponse;
    path = "/v1/defer/update_post_pay_config_amount_limit";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdatePostPayConfigAmountLimitRequest) {}
  }

  export class GetKYCPostPaySetupProgress
    implements
      APIRequest<
        GetKYCPostPaySetupProgressRequest,
        GetKYCPostPaySetupProgressResponse
      > {
    _response?: GetKYCPostPaySetupProgressResponse;
    path = "/v1/defer/get_kyc_post_pay_setup_progress";
    method: HTTPMethod = "post";
    constructor(public parameter: GetKYCPostPaySetupProgressRequest) {}
  }

  export class AgreeToUseOfPostPay
    implements
      APIRequest<AgreeToUseOfPostPayRequest, AgreeToUseOfPostPayResponse> {
    _response?: AgreeToUseOfPostPayResponse;
    path = "/v1/defer/agree_to_the_use_of_post_pay";
    method: HTTPMethod = "post";
    constructor(public parameter: AgreeToUseOfPostPayRequest) {}
  }

  export class GetPostPayEligibility
    implements
      APIRequest<GetPostPayEligibilityRequest, GetPostPayEligibilityResponse> {
    _response?: GetPostPayEligibilityResponse;
    path = "/v1/defer/get_post_pay_eligibility";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPostPayEligibilityRequest) {}
  }

  export class CreateParentalConsent
    implements
      APIRequest<CreateParentalConsentRequest, CreateParentalConsentResponse> {
    _response?: CreateParentalConsentResponse;
    path = "/v1/defer/create_parental_consent";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateParentalConsentRequest) {}
  }

  export class InitializePostPayUser
    implements
      APIRequest<InitializePostPayUserRequest, InitializePostPayUserResponse> {
    _response?: InitializePostPayUserResponse;
    path = "/v1/defer/initialize_post_pay_user";
    method: HTTPMethod = "post";
    constructor(public parameter: InitializePostPayUserRequest) {}
  }

  export class GetSmartpayOnboarding
    implements
      APIRequest<GetSmartpayOnboardingRequest, GetSmartpayOnboardingResponse> {
    _response?: GetSmartpayOnboardingResponse;
    path = "/v1/defer/get_smartpay_onboarding";
    method: HTTPMethod = "post";
    constructor(public parameter: GetSmartpayOnboardingRequest) {}
  }

  export class UpdateSmartpayOnboarding
    implements APIRequest<UpdateSmartpayOnboardingRequest, {}> {
    _response?: {};
    path = "/v1/defer/update_smartpay_onboarding";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateSmartpayOnboardingRequest) {}
  }

  export class GetEasypayApplicationInformation
    implements
      APIRequest<
        GetEasypayApplicationInformationRequest,
        GetEasypayApplicationInformationResponse
      > {
    _response?: GetEasypayApplicationInformationResponse;
    path = "/v1/defer/get_easypay_application_information";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayApplicationInformationRequest) {}
  }

  export class SaveEasypayApplicantInformation
    implements
      APIRequest<
        SaveEasypayApplicantInformationRequest,
        SaveEasypayApplicantInformationResponse
      > {
    _response?: SaveEasypayApplicantInformationResponse;
    path = "/v1/defer/save_easypay_applicant_information";
    method: HTTPMethod = "post";
    constructor(public parameter: SaveEasypayApplicantInformationRequest) {}
  }

  export class GetEasypayRepaymentSetting
    implements
      APIRequest<
        GetEasypayRepaymentSettingRequest,
        GetEasypayRepaymentSettingResponse
      > {
    _response?: GetEasypayRepaymentSettingResponse;
    path = "/v1/defer/get_easypay_repayment_setting";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayRepaymentSettingRequest) {}
  }

  export class UpdateEasypayRepaymentSetting
    implements
      APIRequest<
        UpdateEasypayRepaymentSettingRequest,
        UpdateEasypayRepaymentSettingResponse
      > {
    _response?: UpdateEasypayRepaymentSettingResponse;
    path = "/v1/defer/update_easypay_repayment_setting";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateEasypayRepaymentSettingRequest) {}
  }

  export class SaveTemporalEasypayRepaymentSetting
    implements
      APIRequest<
        SaveTemporalEasypayRepaymentSettingRequest,
        SaveTemporalEasypayRepaymentSettingResponse
      > {
    _response?: SaveTemporalEasypayRepaymentSettingResponse;
    path = "/v1/defer/save_temporal_easypay_repayment_setting";
    method: HTTPMethod = "post";
    constructor(public parameter: SaveTemporalEasypayRepaymentSettingRequest) {}
  }

  export class GetTemporalEasypayRepaymentSetting
    implements
      APIRequest<
        GetTemporalEasypayRepaymentSettingRequest,
        GetTemporalEasypayRepaymentSettingResponse
      > {
    _response?: GetTemporalEasypayRepaymentSettingResponse;
    path = "/v1/defer/get_temporal_easypay_repayment_setting";
    method: HTTPMethod = "post";
    constructor(public parameter: GetTemporalEasypayRepaymentSettingRequest) {}
  }

  export class GetEasypayApplicantCondition
    implements
      APIRequest<
        GetEasypayApplicantConditionRequest,
        GetEasypayApplicantConditionResponse
      > {
    _response?: GetEasypayApplicantConditionResponse;
    path = "/v1/defer/get_easypay_applicant_condition";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayApplicantConditionRequest) {}
  }

  export class ApplyEasypay
    implements APIRequest<ApplyEasypayRequest, ApplyEasypayResponse> {
    _response?: ApplyEasypayResponse;
    path = "/v1/defer/apply_easypay";
    method: HTTPMethod = "post";
    constructor(public parameter: ApplyEasypayRequest) {}
  }

  export class GetEasypayApplicability
    implements
      APIRequest<
        GetEasypayApplicabilityRequest,
        GetEasypayApplicabilityResponse
      > {
    _response?: GetEasypayApplicabilityResponse;
    path = "/v1/defer/get_easypay_applicability";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayApplicabilityRequest) {}
  }

  export class CreateEasypayApplicationReservation
    implements
      APIRequest<
        CreateEasypayApplicationReservationRequest,
        CreateEasypayApplicationReservationResponse
      > {
    _response?: CreateEasypayApplicationReservationResponse;
    path = "/v1/defer/create_easypay_application_reservation";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateEasypayApplicationReservationRequest) {}
  }

  export class GetEasypayAgeRequirement
    implements
      APIRequest<
        GetEasypayAgeRequirementRequest,
        GetEasypayAgeRequirementResponse
      > {
    _response?: GetEasypayAgeRequirementResponse;
    path = "/v1/defer/get_easypay_age_requirement";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayAgeRequirementRequest) {}
  }

  export class CancelEasypayContract
    implements
      APIRequest<CancelEasypayContractRequest, CancelEasypayContractResponse> {
    _response?: CancelEasypayContractResponse;
    path = "/v1/defer/cancel_easypay_contract";
    method: HTTPMethod = "post";
    constructor(public parameter: CancelEasypayContractRequest) {}
  }

  export class GetEasypayStartToUsePasscodeStatus
    implements
      APIRequest<
        GetEasypayStartToUsePasscodeStatusRequest,
        GetEasypayStartToUsePasscodeStatusResponse
      > {
    _response?: GetEasypayStartToUsePasscodeStatusResponse;
    path = "/v1/defer/get_easypay_start_to_use_passcode_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayStartToUsePasscodeStatusRequest) {}
  }

  export class VerifyEasypayStartToUsePasscodeStatus
    implements
      APIRequest<
        VerifyEasypayStartToUsePasscodeStatusRequest,
        VerifyEasypayStartToUsePasscodeStatusResponse
      > {
    _response?: VerifyEasypayStartToUsePasscodeStatusResponse;
    path = "/v1/defer/verify_easypay_start_to_use_passcode_status";
    method: HTTPMethod = "post";
    constructor(
      public parameter: VerifyEasypayStartToUsePasscodeStatusRequest
    ) {}
  }

  export class GetEasypayRepaymentTop
    implements
      APIRequest<
        GetEasypayRepaymentTopRequest,
        GetEasypayRepaymentTopResponse
      > {
    _response?: GetEasypayRepaymentTopResponse;
    path = "/v1/defer/get_easypay_repayment_top";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayRepaymentTopRequest) {}
  }

  export class GetMonthlyClearPayment
    implements
      APIRequest<
        GetMonthlyClearPaymentRequest,
        GetMonthlyClearPaymentResponse
      > {
    _response?: GetMonthlyClearPaymentResponse;
    path = "/v1/defer/get_monthly_clear_payment";
    method: HTTPMethod = "post";
    constructor(public parameter: GetMonthlyClearPaymentRequest) {}
  }

  export class ListRepayableMonthlyClearPayments
    implements
      APIRequest<
        ListRepayableMonthlyClearPaymentsRequest,
        ListRepayableMonthlyClearPaymentsResponse
      > {
    _response?: ListRepayableMonthlyClearPaymentsResponse;
    path = "/v1/defer/list_repayable_monthly_clear_payments";
    method: HTTPMethod = "post";
    constructor(public parameter: ListRepayableMonthlyClearPaymentsRequest) {}
  }

  export class ConvertMonthlyClearToEasypay
    implements
      APIRequest<
        ConvertMonthlyClearToEasypayRequest,
        ConvertMonthlyClearToEasypayResponse
      > {
    _response?: ConvertMonthlyClearToEasypayResponse;
    path = "/v1/defer/convert_monthly_clear_to_easypay";
    method: HTTPMethod = "post";
    constructor(public parameter: ConvertMonthlyClearToEasypayRequest) {}
  }

  export class CanConvertMonthlyClearToEasypay
    implements
      APIRequest<
        CanConvertMonthlyClearToEasypayRequest,
        CanConvertMonthlyClearToEasypayResponse
      > {
    _response?: CanConvertMonthlyClearToEasypayResponse;
    path = "/v1/defer/can_convert_monthly_clear_to_easypay";
    method: HTTPMethod = "post";
    constructor(public parameter: CanConvertMonthlyClearToEasypayRequest) {}
  }

  export class GetEasypayAgreement
    implements
      APIRequest<GetEasypayAgreementRequest, GetEasypayAgreementResponse> {
    _response?: GetEasypayAgreementResponse;
    path = "/v1/defer/get_easypay_agreement";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayAgreementRequest) {}
  }

  export class UpdateEasypayMonthlyInstallments
    implements
      APIRequest<
        UpdateEasypayMonthlyInstallmentsRequest,
        UpdateEasypayMonthlyInstallmentsResponse
      > {
    _response?: UpdateEasypayMonthlyInstallmentsResponse;
    path = "/v1/defer/update_easypay_monthly_installments";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateEasypayMonthlyInstallmentsRequest) {}
  }

  export class GetEasypayInvoiceRepaymentInformationForFund
    implements
      APIRequest<
        GetEasypayInvoiceRepaymentInformationForFundRequest,
        GetEasypayInvoiceRepaymentInformationForFundResponse
      > {
    _response?: GetEasypayInvoiceRepaymentInformationForFundResponse;
    path = "/v1/defer/get_easypay_invoice_repayment_information_for_fund";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetEasypayInvoiceRepaymentInformationForFundRequest
    ) {}
  }

  export class GetEasypayInvoiceRepaymentInformationForCVS
    implements
      APIRequest<
        GetEasypayInvoiceRepaymentInformationForCVSRequest,
        GetEasypayInvoiceRepaymentInformationForCVSResponse
      > {
    _response?: GetEasypayInvoiceRepaymentInformationForCVSResponse;
    path = "/v1/defer/get_easypay_invoice_repayment_information_for_cvs";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetEasypayInvoiceRepaymentInformationForCVSRequest
    ) {}
  }

  export class GetInvoiceRepaymentInformationForOnDemand
    implements
      APIRequest<
        GetInvoiceRepaymentInformationForOnDemandRequest,
        GetInvoiceRepaymentInformationForOnDemandResponse
      > {
    _response?: GetInvoiceRepaymentInformationForOnDemandResponse;
    path = "/v1/defer/get_invoice_repayment_information_for_on_demand";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetInvoiceRepaymentInformationForOnDemandRequest
    ) {}
  }

  export class CreateEasypayInvoiceRepaymentForFund
    implements
      APIRequest<
        CreateEasypayInvoiceRepaymentForFundRequest,
        CreateEasypayInvoiceRepaymentForFundResponse
      > {
    _response?: CreateEasypayInvoiceRepaymentForFundResponse;
    path = "/v1/defer/create_easypay_invoice_repayment_for_fund";
    method: HTTPMethod = "post";
    constructor(
      public parameter: CreateEasypayInvoiceRepaymentForFundRequest
    ) {}
  }

  export class CreateEasypayInvoiceRepaymentForCVS
    implements
      APIRequest<
        CreateEasypayInvoiceRepaymentForCVSRequest,
        CreateEasypayInvoiceRepaymentForCVSResponse
      > {
    _response?: CreateEasypayInvoiceRepaymentForCVSResponse;
    path = "/v1/defer/create_easypay_invoice_repayment_for_cvs";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateEasypayInvoiceRepaymentForCVSRequest) {}
  }

  export class CreateInvoiceRepaymentForOnDemand
    implements
      APIRequest<
        CreateInvoiceRepaymentForOnDemandRequest,
        CreateInvoiceRepaymentForOnDemandResponse
      > {
    _response?: CreateInvoiceRepaymentForOnDemandResponse;
    path = "/v1/defer/create_invoice_repayment_for_on_demand";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateInvoiceRepaymentForOnDemandRequest) {}
  }

  export class GetEasypayPayment
    implements APIRequest<GetEasypayPaymentRequest, GetEasypayPaymentResponse> {
    _response?: GetEasypayPaymentResponse;
    path = "/v1/defer/get_easypay_payment";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayPaymentRequest) {}
  }

  export class GetEasypayBill
    implements APIRequest<GetEasypayBillRequest, GetEasypayBillResponse> {
    _response?: GetEasypayBillResponse;
    path = "/v1/defer/get_easypay_bill";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayBillRequest) {}
  }

  export class GetEasypayHistoryTop
    implements
      APIRequest<GetEasypayHistoryTopRequest, GetEasypayHistoryTopResponse> {
    _response?: GetEasypayHistoryTopResponse;
    path = "/v1/defer/get_easypay_history_top";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayHistoryTopRequest) {}
  }

  export class GetEasypayConvertiblePaymentList
    implements
      APIRequest<
        GetEasypayConvertiblePaymentListRequest,
        GetEasypayConvertiblePaymentListResponse
      > {
    _response?: GetEasypayConvertiblePaymentListResponse;
    path = "/v1/defer/get_easypay_convertible_payment_list";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayConvertiblePaymentListRequest) {}
  }

  export class GetEasypayTop
    implements APIRequest<GetEasypayTopRequest, GetEasypayTopResponse> {
    _response?: GetEasypayTopResponse;
    path = "/v1/defer/get_easypay_top";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayTopRequest) {}
  }

  export class GetEasypayDestinationForApplication
    implements
      APIRequest<
        GetEasypayDestinationForApplicationRequest,
        GetEasypayDestinationForApplicationResponse
      > {
    _response?: GetEasypayDestinationForApplicationResponse;
    path = "/v1/defer/get_easypay_destination_for_application";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEasypayDestinationForApplicationRequest) {}
  }

  export class UpdateZipCode
    implements APIRequest<UpdateZipCodeRequest, UpdateZipCodeResponse> {
    _response?: UpdateZipCodeResponse;
    path = "/v1/defer/update_zipcode";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateZipCodeRequest) {}
  }

  export class CalcEasypayRepaymentPlan
    implements
      APIRequest<
        CalcEasypayRepaymentPlanRequest,
        CalcEasypayRepaymentPlanResponse
      > {
    _response?: CalcEasypayRepaymentPlanResponse;
    path = "/v1/defer/calc_easypay_repayment_plan";
    method: HTTPMethod = "post";
    constructor(public parameter: CalcEasypayRepaymentPlanRequest) {}
  }

  export class GetLendingUser
    implements APIRequest<GetLendingUserRequest, GetLendingUserResponse> {
    _response?: GetLendingUserResponse;
    path = "/v1/lending/get_user";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingUserRequest) {}
  }

  export class GetLendingApplicationCondition
    implements
      APIRequest<
        GetLendingApplicationConditionRequest,
        GetLendingApplicationConditionResponse
      > {
    _response?: GetLendingApplicationConditionResponse;
    path = "/v1/lending/get_application_condition";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingApplicationConditionRequest) {}
  }

  export class GetLendingRepaymentSimulation
    implements
      APIRequest<
        GetLendingRepaymentSimulationRequest,
        GetLendingRepaymentSimulationResponse
      > {
    _response?: GetLendingRepaymentSimulationResponse;
    path = "/v1/lending/get_repayment_simulation";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingRepaymentSimulationRequest) {}
  }

  export class GetLendingRepaymentHistorySimulation
    implements
      APIRequest<
        GetLendingRepaymentHistorySimulationRequest,
        GetLendingRepaymentHistorySimulationResponse
      > {
    _response?: GetLendingRepaymentHistorySimulationResponse;
    path = "/v1/lending/get_repayment_history_simulation";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetLendingRepaymentHistorySimulationRequest
    ) {}
  }

  export class GetLendingProvisionalLoanableAmount
    implements
      APIRequest<
        GetLendingProvisionalLoanableAmountRequest,
        GetLendingProvisionalLoanableAmountResponse
      > {
    _response?: GetLendingProvisionalLoanableAmountResponse;
    path = "/v1/lending/get_provisional_loanable_amount";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingProvisionalLoanableAmountRequest) {}
  }

  export class ListLendingMonthlyRepayAmountChoices
    implements
      APIRequest<
        ListLendingMonthlyRepayAmountChoicesRequest,
        ListLendingMonthlyRepayAmountChoicesResponse
      > {
    _response?: ListLendingMonthlyRepayAmountChoicesResponse;
    path = "/v1/lending/get_monthly_repay_amount_choices";
    method: HTTPMethod = "post";
    constructor(
      public parameter: ListLendingMonthlyRepayAmountChoicesRequest
    ) {}
  }

  export class GetLendingPrecontractDocument
    implements
      APIRequest<
        GetLendingPrecontractDocumentRequest,
        GetLendingPrecontractDocumentResponse
      > {
    _response?: GetLendingPrecontractDocumentResponse;
    path = "/v1/lending/get_precontract_document";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingPrecontractDocumentRequest) {}
  }

  export class CreateLendingApplication
    implements
      APIRequest<
        CreateLendingApplicationRequest,
        CreateLendingApplicationResponse
      > {
    _response?: CreateLendingApplicationResponse;
    path = "/v1/lending/create_application";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateLendingApplicationRequest) {}
  }

  export class UpdateLendingApplication
    implements
      APIRequest<
        UpdateLendingApplicationRequest,
        UpdateLendingApplicationResponse
      > {
    _response?: UpdateLendingApplicationResponse;
    path = "/v1/lending/update_application";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateLendingApplicationRequest) {}
  }

  export class CancelLendingApplication
    implements
      APIRequest<
        CancelLendingApplicationRequest,
        CancelLendingApplicationResponse
      > {
    _response?: CancelLendingApplicationResponse;
    path = "/v1/lending/cancel_application";
    method: HTTPMethod = "post";
    constructor(public parameter: CancelLendingApplicationRequest) {}
  }

  export class GetLendingContractDocument
    implements
      APIRequest<
        GetLendingContractDocumentRequest,
        GetLendingContractDocumentResponse
      > {
    _response?: GetLendingContractDocumentResponse;
    path = "/v1/lending/get_contract_document";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingContractDocumentRequest) {}
  }

  export class GetLendingApplication
    implements
      APIRequest<GetLendingApplicationRequest, GetLendingApplicationResponse> {
    _response?: GetLendingApplicationResponse;
    path = "/v1/lending/get_application";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingApplicationRequest) {}
  }

  export class GetLendingTop
    implements APIRequest<GetLendingTopRequest, GetLendingTopResponse> {
    _response?: GetLendingTopResponse;
    path = "/v1/lending/get_lending_top";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingTopRequest) {}
  }

  export class ListLendingUnpaidInvoices
    implements
      APIRequest<
        ListLendingUnpaidInvoicesRequest,
        ListLendingUnpaidInvoicesResponse
      > {
    _response?: ListLendingUnpaidInvoicesResponse;
    path = "/v1/lending/list_unpaid_invoices";
    method: HTTPMethod = "post";
    constructor(public parameter: ListLendingUnpaidInvoicesRequest) {}
  }

  export class UpdateLendingMonthlyRepayAmount
    implements
      APIRequest<
        UpdateLendingMonthlyRepayAmountRequest,
        UpdateLendingMonthlyRepayAmountResponse
      > {
    _response?: UpdateLendingMonthlyRepayAmountResponse;
    path = "/v1/lending/update_monthly_repay_amount";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateLendingMonthlyRepayAmountRequest) {}
  }

  export class UpdateLendingRepaymentScheduledDay
    implements
      APIRequest<
        UpdateLendingRepaymentScheduledDayRequest,
        UpdateLendingRepaymentScheduledDayResponse
      > {
    _response?: UpdateLendingRepaymentScheduledDayResponse;
    path = "/v1/lending/update_repayment_scheduled_day";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateLendingRepaymentScheduledDayRequest) {}
  }

  export class UpdateLendingScheduledRepaymentType
    implements
      APIRequest<
        UpdateLendingScheduledRepaymentTypeRequest,
        UpdateLendingScheduledRepaymentTypeResponse
      > {
    _response?: UpdateLendingScheduledRepaymentTypeResponse;
    path = "/v1/lending/update_scheduled_repayment_type";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateLendingScheduledRepaymentTypeRequest) {}
  }

  export class ListLendingDocuments
    implements
      APIRequest<ListLendingDocumentsRequest, ListLendingDocumentsResponse> {
    _response?: ListLendingDocumentsResponse;
    path = "/v1/lending/list_documents";
    method: HTTPMethod = "post";
    constructor(public parameter: ListLendingDocumentsRequest) {}
  }

  export class GetLendingRepaymentDocument
    implements
      APIRequest<
        GetLendingRepaymentDocumentRequest,
        GetLendingRepaymentDocumentResponse
      > {
    _response?: GetLendingRepaymentDocumentResponse;
    path = "/v1/lending/get_repayment_document";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingRepaymentDocumentRequest) {}
  }

  export class GetLendingInvoiceRepaymentConfirmationForBalance
    implements
      APIRequest<
        GetLendingInvoiceRepaymentConfirmationForBalanceRequest,
        GetLendingInvoiceRepaymentConfirmationForBalanceResponse
      > {
    _response?: GetLendingInvoiceRepaymentConfirmationForBalanceResponse;
    path = "/v1/lending/get_invoice_repayment_confirmation_for_balance";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetLendingInvoiceRepaymentConfirmationForBalanceRequest
    ) {}
  }

  export class CreateLendingInvoiceRepaymentForBalance
    implements
      APIRequest<
        CreateLendingInvoiceRepaymentForBalanceRequest,
        CreateLendingInvoiceRepaymentForBalanceResponse
      > {
    _response?: CreateLendingInvoiceRepaymentForBalanceResponse;
    path = "/v1/lending/create_invoice_repayment_for_balance";
    method: HTTPMethod = "post";
    constructor(
      public parameter: CreateLendingInvoiceRepaymentForBalanceRequest
    ) {}
  }

  export class GetLendingInvoiceRepaymentConfirmationForApplication
    implements
      APIRequest<
        GetLendingInvoiceRepaymentConfirmationForApplicationRequest,
        GetLendingInvoiceRepaymentConfirmationForApplicationResponse
      > {
    _response?: GetLendingInvoiceRepaymentConfirmationForApplicationResponse;
    path = "/v1/lending/get_invoice_repayment_confirmation_for_application";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetLendingInvoiceRepaymentConfirmationForApplicationRequest
    ) {}
  }

  export class CreateLendingInvoiceRepaymentForApplication
    implements
      APIRequest<
        CreateLendingInvoiceRepaymentForApplicationRequest,
        CreateLendingInvoiceRepaymentForApplicationResponse
      > {
    _response?: CreateLendingInvoiceRepaymentForApplicationResponse;
    path = "/v1/lending/create_invoice_repayment_for_application";
    method: HTTPMethod = "post";
    constructor(
      public parameter: CreateLendingInvoiceRepaymentForApplicationRequest
    ) {}
  }

  export class GetLendingServiceDescription
    implements
      APIRequest<
        GetLendingServiceDescriptionRequest,
        GetLendingServiceDescriptionResponse
      > {
    _response?: GetLendingServiceDescriptionResponse;
    path = "/v1/lending/get_service_description";
    method: HTTPMethod = "post";
    constructor(public parameter: GetLendingServiceDescriptionRequest) {}
  }

  export class GetNetpaymentOrderDetail
    implements
      APIRequest<
        GetNetpaymentOrderDetailRequest,
        GetNetpaymentOrderDetailResponse
      > {
    _response?: GetNetpaymentOrderDetailResponse;
    path = "/v1/get_netpayment_order_detail";
    method: HTTPMethod = "post";
    constructor(public parameter: GetNetpaymentOrderDetailRequest) {}
  }

  export class UpdateNetpaymentDeliveryAddress
    implements
      APIRequest<
        UpdateNetpaymentDeliveryAddressRequest,
        UpdateNetpaymentDeliveryAddressResponse
      > {
    _response?: UpdateNetpaymentDeliveryAddressResponse;
    path = "/v1/update_netpayment_delivery_address";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateNetpaymentDeliveryAddressRequest) {}
  }

  export class InvalidateNetpayment
    implements
      APIRequest<InvalidateNetpaymentRequest, InvalidateNetpaymentResponse> {
    _response?: InvalidateNetpaymentResponse;
    path = "/v1/invalidate_netpayment";
    method: HTTPMethod = "post";
    constructor(public parameter: InvalidateNetpaymentRequest) {}
  }

  export class ConfirmNetpayment
    implements APIRequest<ConfirmNetpaymentRequest, ConfirmNetpaymentResponse> {
    _response?: ConfirmNetpaymentResponse;
    path = "/v1/confirm_netpayment";
    method: HTTPMethod = "post";
    constructor(public parameter: ConfirmNetpaymentRequest) {}
  }

  export class IdentifyQRCodeType
    implements
      APIRequest<IdentifyQRCodeTypeRequest, IdentifyQRCodeTypeResponse> {
    _response?: IdentifyQRCodeTypeResponse;
    path = "/v1/identify_qr_code_type";
    method: HTTPMethod = "post";
    constructor(public parameter: IdentifyQRCodeTypeRequest) {}
  }

  export class GetPaymentStatus
    implements APIRequest<GetPaymentStatusRequest, GetPaymentStatusResponse> {
    _response?: GetPaymentStatusResponse;
    path = "/v1/get_payment_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPaymentStatusRequest) {}
  }

  export class GetPreapprovedPaymentMethod
    implements
      APIRequest<
        GetPreapprovedPaymentMethodRequest,
        GetPreapprovedPaymentMethodResponse
      > {
    _response?: GetPreapprovedPaymentMethodResponse;
    path = "/v1/get_preapproved_payment_method";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPreapprovedPaymentMethodRequest) {}
  }

  export class UpdatePreapprovedPaymentMethod
    implements
      APIRequest<
        UpdatePreapprovedPaymentMethodRequest,
        UpdatePreapprovedPaymentMethodResponse
      > {
    _response?: UpdatePreapprovedPaymentMethodResponse;
    path = "/v1/update_preapproved_payment_method";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdatePreapprovedPaymentMethodRequest) {}
  }

  export class ListPreapprovedServices
    implements
      APIRequest<
        ListPreapprovedServicesRequest,
        ListPreapprovedServicesResponse
      > {
    _response?: ListPreapprovedServicesResponse;
    path = "/v1/list_preapproved_services";
    method: HTTPMethod = "post";
    constructor(public parameter: ListPreapprovedServicesRequest) {}
  }

  export class GetGoogleClientInfo
    implements
      APIRequest<GetGoogleClientInfoRequest, GetGoogleClientInfoResponse> {
    _response?: GetGoogleClientInfoResponse;
    path = "/v1/get_google_client_info";
    method: HTTPMethod = "post";
    constructor(public parameter: GetGoogleClientInfoRequest) {}
  }

  export class CreateGSPAssociation
    implements
      APIRequest<CreateGSPAssociationRequest, CreateGSPAssociationResponse> {
    _response?: CreateGSPAssociationResponse;
    path = "/v1/create_gsp_association";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateGSPAssociationRequest) {}
  }

  export class ListTopupProviders
    implements
      APIRequest<ListTopupProvidersRequest, ListTopupProvidersResponse> {
    _response?: ListTopupProvidersResponse;
    path = "/v1/list_topup_providers";
    method: HTTPMethod = "post";
    constructor(public parameter: ListTopupProvidersRequest) {}
  }

  export class CreateSecureInformationForSevenBank
    implements
      APIRequest<
        CreateSecureInformationForSevenBankRequest,
        CreateSecureInformationForSevenBankResponse
      > {
    _response?: CreateSecureInformationForSevenBankResponse;
    path = "/v1/create_secure_information_for_seven_bank";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateSecureInformationForSevenBankRequest) {}
  }

  export class GetPersonalInformation
    implements
      APIRequest<
        GetPersonalInformationRequest,
        GetPersonalInformationResponse
      > {
    _response?: GetPersonalInformationResponse;
    path = "/v1/kyc/get_personal_information";
    method: HTTPMethod = "post";
    constructor(public parameter: GetPersonalInformationRequest) {}
  }

  export class CheckIDKYCAllowed
    implements APIRequest<CheckIDKYCAllowedRequest, CheckIDKYCAllowedResponse> {
    _response?: CheckIDKYCAllowedResponse;
    path = "/v1/kyc/check_id_kyc_allowed";
    method: HTTPMethod = "post";
    constructor(public parameter: CheckIDKYCAllowedRequest) {}
  }

  export class UpdateIDPhotos
    implements APIRequest<UpdateIDPhotosRequest, UpdateIDPhotosResponse> {
    _response?: UpdateIDPhotosResponse;
    path = "/v1/kyc/update_id_photos";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateIDPhotosRequest) {}
  }

  export class UpdateSupplementaryDocument
    implements
      APIRequest<
        UpdateSupplementaryDocumentRequest,
        UpdateSupplementaryDocumentResponse
      > {
    _response?: UpdateSupplementaryDocumentResponse;
    path = "/v1/kyc/update_supplementary_document";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateSupplementaryDocumentRequest) {}
  }

  export class RequestIDKYCAudit
    implements APIRequest<RequestIDKYCAuditRequest, RequestIDKYCAuditResponse> {
    _response?: RequestIDKYCAuditResponse;
    path = "/v1/kyc/request_id_kyc_audit";
    method: HTTPMethod = "post";
    constructor(public parameter: RequestIDKYCAuditRequest) {}
  }

  export class UpdateEKYCIDPhoto
    implements APIRequest<UpdateEKYCIDPhotoRequest, UpdateEKYCIDPhotoResponse> {
    _response?: UpdateEKYCIDPhotoResponse;
    path = "/v1/kyc/update_ekyc_photo";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateEKYCIDPhotoRequest) {}
  }

  export class UpdateEKYCSupplementaryDocument
    implements
      APIRequest<
        UpdateEKYCSupplementaryDocumentRequest,
        UpdateEKYCSupplementaryDocumentResponse
      > {
    _response?: UpdateEKYCSupplementaryDocumentResponse;
    path = "/v1/kyc/update_ekyc_supplementary_document";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateEKYCSupplementaryDocumentRequest) {}
  }

  export class RequestEKYCAudit
    implements APIRequest<RequestEKYCAuditRequest, RequestEKYCAuditResponse> {
    _response?: RequestEKYCAuditResponse;
    path = "/v1/kyc/request_ekyc_audit";
    method: HTTPMethod = "post";
    constructor(public parameter: RequestEKYCAuditRequest) {}
  }

  export class RequestICChipKYCAudit
    implements
      APIRequest<RequestICChipKYCAuditRequest, RequestICChipKYCAuditResponse> {
    _response?: RequestICChipKYCAuditResponse;
    path = "/v1/kyc/request_ic_chip_kyc_audit";
    method: HTTPMethod = "post";
    constructor(public parameter: RequestICChipKYCAuditRequest) {}
  }

  export class GetICChipTextToBeSigned
    implements
      APIRequest<
        GetICChipTextToBeSignedRequest,
        GetICChipTextToBeSignedResponse
      > {
    _response?: GetICChipTextToBeSignedResponse;
    path = "/v1/kyc/get_ic_chip_text_to_be_signed";
    method: HTTPMethod = "post";
    constructor(public parameter: GetICChipTextToBeSignedRequest) {}
  }

  export class CheckICChipKYCAllowed
    implements
      APIRequest<CheckICChipKYCAllowedRequest, CheckICChipKYCAllowedResponse> {
    _response?: CheckICChipKYCAllowedResponse;
    path = "/v1/kyc/check_ic_chip_kyc_allowed";
    method: HTTPMethod = "post";
    constructor(public parameter: CheckICChipKYCAllowedRequest) {}
  }

  export class UpdatePersonalInformation
    implements
      APIRequest<
        UpdatePersonalInformationRequest,
        UpdatePersonalInformationResponse
      > {
    _response?: UpdatePersonalInformationResponse;
    path = "/v1/kyc/update_personal_information";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdatePersonalInformationRequest) {}
  }

  export class CheckEKYCAllowed
    implements APIRequest<CheckEKYCAllowedRequest, CheckEKYCAllowedResponse> {
    _response?: CheckEKYCAllowedResponse;
    path = "/v1/kyc/check_ekyc_allowed";
    method: HTTPMethod = "post";
    constructor(public parameter: CheckEKYCAllowedRequest) {}
  }

  export class GetPersonalInformationAndUserAttribute
    implements
      APIRequest<
        GetPersonalInformationAndUserAttributeRequest,
        GetPersonalInformationAndUserAttributeResponse
      > {
    _response?: GetPersonalInformationAndUserAttributeResponse;
    path = "/v1/kyc/get_personal_information_and_user_attribute";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetPersonalInformationAndUserAttributeRequest
    ) {}
  }

  export class UpdatePersonalInformationAndUserAttribute
    implements
      APIRequest<
        UpdatePersonalInformationAndUserAttributeRequest,
        UpdatePersonalInformationAndUserAttributeResponse
      > {
    _response?: UpdatePersonalInformationAndUserAttributeResponse;
    path = "/v1/kyc/update_personal_information_and_user_attribute";
    method: HTTPMethod = "post";
    constructor(
      public parameter: UpdatePersonalInformationAndUserAttributeRequest
    ) {}
  }

  export class SearchShops
    implements APIRequest<SearchShopsRequest, SearchShopsResponse> {
    _response?: SearchShopsResponse;
    path = "/v1/search_shops";
    method: HTTPMethod = "post";
    constructor(public parameter: SearchShopsRequest) {}
  }

  export class UpdateP2PTransferUserProfile
    implements
      APIRequest<
        UpdateP2PTransferUserProfileRequest,
        UpdateP2PTransferUserProfileResponse
      > {
    _response?: UpdateP2PTransferUserProfileResponse;
    path = "/v1/p2p_transfer/update_user_profile";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateP2PTransferUserProfileRequest) {}
  }

  export class UpdateP2PTransferUserProfileImage
    implements
      APIRequest<
        UpdateP2PTransferUserProfileImageRequest,
        UpdateP2PTransferUserProfileImageResponse
      > {
    _response?: UpdateP2PTransferUserProfileImageResponse;
    path = "/v1/p2p_transfer/update_user_profile_image";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateP2PTransferUserProfileImageRequest) {}
  }

  export class GetP2PTransferUserProfile
    implements
      APIRequest<
        GetP2PTransferUserProfileRequest,
        GetP2PTransferUserProfileResponse
      > {
    _response?: GetP2PTransferUserProfileResponse;
    path = "/v1/p2p_transfer/get_user_profile";
    method: HTTPMethod = "post";
    constructor(public parameter: GetP2PTransferUserProfileRequest) {}
  }

  export class CreateP2PTransfer
    implements APIRequest<CreateP2PTransferRequest, CreateP2PTransferResponse> {
    _response?: CreateP2PTransferResponse;
    path = "/v1/p2p_transfer/create_transfer";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateP2PTransferRequest) {}
  }

  export class ReceiveP2PTransfer
    implements
      APIRequest<ReceiveP2PTransferRequest, ReceiveP2PTransferResponse> {
    _response?: ReceiveP2PTransferResponse;
    path = "/v1/p2p_transfer/receive_transfer";
    method: HTTPMethod = "post";
    constructor(public parameter: ReceiveP2PTransferRequest) {}
  }

  export class CancelP2PTransfer
    implements APIRequest<CancelP2PTransferRequest, CancelP2PTransferResponse> {
    _response?: CancelP2PTransferResponse;
    path = "/v1/p2p_transfer/cancel_transfer";
    method: HTTPMethod = "post";
    constructor(public parameter: CancelP2PTransferRequest) {}
  }

  export class GetP2PTransfer
    implements APIRequest<GetP2PTransferRequest, GetP2PTransferResponse> {
    _response?: GetP2PTransferResponse;
    path = "/v1/p2p_transfer/get_transfer";
    method: HTTPMethod = "post";
    constructor(public parameter: GetP2PTransferRequest) {}
  }

  export class GetP2PTransferByToken
    implements
      APIRequest<GetP2PTransferByTokenRequest, GetP2PTransferByTokenResponse> {
    _response?: GetP2PTransferByTokenResponse;
    path = "/v1/p2p_transfer/get_transfer_by_token";
    method: HTTPMethod = "post";
    constructor(public parameter: GetP2PTransferByTokenRequest) {}
  }

  export class ConfirmP2PTransferRecipient
    implements
      APIRequest<
        ConfirmP2PTransferRecipientRequest,
        ConfirmP2PTransferRecipientResponse
      > {
    _response?: ConfirmP2PTransferRecipientResponse;
    path = "/v1/p2p_transfer/confirm_recipient";
    method: HTTPMethod = "post";
    constructor(public parameter: ConfirmP2PTransferRecipientRequest) {}
  }

  export class ListP2PTransfersSent
    implements
      APIRequest<ListP2PTransfersSentRequest, ListP2PTransfersSentResponse> {
    _response?: ListP2PTransfersSentResponse;
    path = "/v1/p2p_transfer/list_transfers_sent";
    method: HTTPMethod = "post";
    constructor(public parameter: ListP2PTransfersSentRequest) {}
  }

  export class ListP2PTransfersReceived
    implements
      APIRequest<
        ListP2PTransfersReceivedRequest,
        ListP2PTransfersReceivedResponse
      > {
    _response?: ListP2PTransfersReceivedResponse;
    path = "/v1/p2p_transfer/list_transfers_received";
    method: HTTPMethod = "post";
    constructor(public parameter: ListP2PTransfersReceivedRequest) {}
  }

  export class ListP2PTransferMessageCardsByCategory
    implements
      APIRequest<
        ListP2PTransferMessageCardsByCategoryRequest,
        ListP2PTransferMessageCardsByCategoryResponse
      > {
    _response?: ListP2PTransferMessageCardsByCategoryResponse;
    path = "/v1/p2p_transfer/list_message_cards_by_category";
    method: HTTPMethod = "post";
    constructor(
      public parameter: ListP2PTransferMessageCardsByCategoryRequest
    ) {}
  }

  export class CreateDocomoConnection
    implements
      APIRequest<
        CreateDocomoConnectionRequest,
        CreateDocomoConnectionResponse
      > {
    _response?: CreateDocomoConnectionResponse;
    path = "/v1/create_docomo_connection";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateDocomoConnectionRequest) {}
  }

  export class CreateDocomoNonce
    implements APIRequest<CreateDocomoNonceRequest, CreateDocomoNonceResponse> {
    _response?: CreateDocomoNonceResponse;
    path = "/v1/create_docomo_nonce";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateDocomoNonceRequest) {}
  }

  export class GetDocomoConnection
    implements
      APIRequest<GetDocomoConnectionRequest, GetDocomoConnectionResponse> {
    _response?: GetDocomoConnectionResponse;
    path = "/v1/get_docomo_connection";
    method: HTTPMethod = "post";
    constructor(public parameter: GetDocomoConnectionRequest) {}
  }

  export class DeleteDocomoConnection
    implements APIRequest<DeleteDocomoConnectionRequest, {}> {
    _response?: {};
    path = "/v1/delete_docomo_connection";
    method: HTTPMethod = "post";
    constructor(public parameter: DeleteDocomoConnectionRequest) {}
  }

  export class ActivateRealcard
    implements APIRequest<ActivateRealcardRequest, ActivateRealcardResponse> {
    _response?: ActivateRealcardResponse;
    path = "/v1/activate_realcard";
    method: HTTPMethod = "post";
    constructor(public parameter: ActivateRealcardRequest) {}
  }

  export class GetWaitActivationRealcard
    implements
      APIRequest<
        GetWaitActivationRealcardRequest,
        GetWaitActivationRealcardResponse
      > {
    _response?: GetWaitActivationRealcardResponse;
    path = "/v1/get_wait_activation_realcard";
    method: HTTPMethod = "post";
    constructor(public parameter: GetWaitActivationRealcardRequest) {}
  }

  export class ListRealcard
    implements APIRequest<ListRealcardRequest, ListRealcardResponse> {
    _response?: ListRealcardResponse;
    path = "/v1/list_realcard";
    method: HTTPMethod = "post";
    constructor(public parameter: ListRealcardRequest) {}
  }

  export class GetRealcardDetail
    implements APIRequest<GetRealcardDetailRequest, GetRealcardDetailResponse> {
    _response?: GetRealcardDetailResponse;
    path = "/v1/get_realcard_detail";
    method: HTTPMethod = "post";
    constructor(public parameter: GetRealcardDetailRequest) {}
  }

  export class OpenRealcardLandingPage
    implements
      APIRequest<
        OpenRealcardLandingPageRequest,
        OpenRealcardLandingPageResponse
      > {
    _response?: OpenRealcardLandingPageResponse;
    path = "/v1/open_realcard_landing_page";
    method: HTTPMethod = "post";
    constructor(public parameter: OpenRealcardLandingPageRequest) {}
  }

  export class GetRealcardApplicationStatus
    implements
      APIRequest<
        GetRealcardApplicationStatusRequest,
        GetRealcardApplicationStatusResponse
      > {
    _response?: GetRealcardApplicationStatusResponse;
    path = "/v1/get_realcard_application_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetRealcardApplicationStatusRequest) {}
  }

  export class GetRealcardApplicationInformation
    implements
      APIRequest<
        GetRealcardApplicationInformationRequest,
        GetRealcardApplicationInformationResponse
      > {
    _response?: GetRealcardApplicationInformationResponse;
    path = "/v1/get_realcard_application_information";
    method: HTTPMethod = "post";
    constructor(public parameter: GetRealcardApplicationInformationRequest) {}
  }

  export class GetRealcardApplicationSMSVerification
    implements
      APIRequest<
        GetRealcardApplicationSMSVerificationRequest,
        GetRealcardApplicationSMSVerificationResponse
      > {
    _response?: GetRealcardApplicationSMSVerificationResponse;
    path = "/v1/get_realcard_application_sms_verification";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetRealcardApplicationSMSVerificationRequest
    ) {}
  }

  export class PreValidateRealcardApplication
    implements
      APIRequest<
        PreValidateRealcardApplicationRequest,
        PreValidateRealcardApplicationResponse
      > {
    _response?: PreValidateRealcardApplicationResponse;
    path = "/v1/pre_validate_realcard_application";
    method: HTTPMethod = "post";
    constructor(public parameter: PreValidateRealcardApplicationRequest) {}
  }

  export class ValidateRealcardApplication
    implements
      APIRequest<
        ValidateRealcardApplicationRequest,
        ValidateRealcardApplicationResponse
      > {
    _response?: ValidateRealcardApplicationResponse;
    path = "/v1/validate_realcard_application";
    method: HTTPMethod = "post";
    constructor(public parameter: ValidateRealcardApplicationRequest) {}
  }

  export class GetRealcardAgeRequirement
    implements
      APIRequest<
        GetRealcardAgeRequirementRequest,
        GetRealcardAgeRequirementResponse
      > {
    _response?: GetRealcardAgeRequirementResponse;
    path = "/v1/get_realcard_age_requirement";
    method: HTTPMethod = "post";
    constructor(public parameter: GetRealcardAgeRequirementRequest) {}
  }

  export class InitiateRealcardApplication
    implements
      APIRequest<
        InitiateRealcardApplicationRequest,
        InitiateRealcardApplicationResponse
      > {
    _response?: InitiateRealcardApplicationResponse;
    path = "/v1/initiate_realcard_application";
    method: HTTPMethod = "post";
    constructor(public parameter: InitiateRealcardApplicationRequest) {}
  }

  export class CreateRealcardApplication
    implements
      APIRequest<
        CreateRealcardApplicationRequest,
        CreateRealcardApplicationResponse
      > {
    _response?: CreateRealcardApplicationResponse;
    path = "/v1/create_realcard_application";
    method: HTTPMethod = "post";
    constructor(public parameter: CreateRealcardApplicationRequest) {}
  }

  export class GetRealcardToken
    implements APIRequest<GetRealcardTokenRequest, GetRealcardTokenResponse> {
    _response?: GetRealcardTokenResponse;
    path = "/v1/get_realcard_token";
    method: HTTPMethod = "post";
    constructor(public parameter: GetRealcardTokenRequest) {}
  }

  export class RegisterPublicKey
    implements APIRequest<RegisterPublicKeyRequest, RegisterPublicKeyResponse> {
    _response?: RegisterPublicKeyResponse;
    path = "/v1/register_public_key";
    method: HTTPMethod = "post";
    constructor(public parameter: RegisterPublicKeyRequest) {}
  }

  export class CancelRealcard
    implements APIRequest<CancelRealcardRequest, CancelRealcardResponse> {
    _response?: CancelRealcardResponse;
    path = "/v1/cancel_realcard";
    method: HTTPMethod = "post";
    constructor(public parameter: CancelRealcardRequest) {}
  }

  export class UpdateRealcardLock
    implements
      APIRequest<UpdateRealcardLockRequest, UpdateRealcardLockResponse> {
    _response?: UpdateRealcardLockResponse;
    path = "/v1/update_realcard_lock";
    method: HTTPMethod = "post";
    constructor(public parameter: UpdateRealcardLockRequest) {}
  }

  export class GetRealcardReissueReasons
    implements
      APIRequest<
        GetRealcardReissueReasonsRequest,
        GetRealcardReissueReasonsResponse
      > {
    _response?: GetRealcardReissueReasonsResponse;
    path = "/v1/get_realcard_reissue_reasons";
    method: HTTPMethod = "post";
    constructor(public parameter: GetRealcardReissueReasonsRequest) {}
  }

  export class GetRealcardApplicationDeliveryStatus
    implements
      APIRequest<
        GetRealcardApplicationDeliveryStatusRequest,
        GetRealcardApplicationDeliveryStatusResponse
      > {
    _response?: GetRealcardApplicationDeliveryStatusResponse;
    path = "/v1/get_realcard_application_delivery_status";
    method: HTTPMethod = "post";
    constructor(
      public parameter: GetRealcardApplicationDeliveryStatusRequest
    ) {}
  }

  export class Get3DSTransactionInfo
    implements
      APIRequest<Get3DSTransactionInfoRequest, Get3DSTransactionInfoResponse> {
    _response?: Get3DSTransactionInfoResponse;
    path = "/v1/get_3ds_transaction_info";
    method: HTTPMethod = "post";
    constructor(public parameter: Get3DSTransactionInfoRequest) {}
  }

  export class Confirm3DS
    implements APIRequest<Confirm3DSRequest, Confirm3DSResponse> {
    _response?: Confirm3DSResponse;
    path = "/v1/confirm_3ds";
    method: HTTPMethod = "post";
    constructor(public parameter: Confirm3DSRequest) {}
  }

  export class Cancel3DS
    implements APIRequest<Cancel3DSRequest, Cancel3DSResponse> {
    _response?: Cancel3DSResponse;
    path = "/v1/cancel_3ds";
    method: HTTPMethod = "post";
    constructor(public parameter: Cancel3DSRequest) {}
  }

  export class GetBankKYCClosureStatus
    implements
      APIRequest<
        GetBankKYCClosureStatusRequest,
        GetBankKYCClosureStatusResponse
      > {
    _response?: GetBankKYCClosureStatusResponse;
    path = "/v1/kyc/get_bankkyc_closure_status";
    method: HTTPMethod = "post";
    constructor(public parameter: GetBankKYCClosureStatusRequest) {}
  }

  export class GetEKYCRecords
    implements APIRequest<GetEKYCRecordsRequest, GetEKYCRecordsResponse> {
    _response?: GetEKYCRecordsResponse;
    path = "/v1/kyc/get_ekyc_records";
    method: HTTPMethod = "post";
    constructor(public parameter: GetEKYCRecordsRequest) {}
  }
}

export interface ListOption {
  pageSize?: number;
  pageToken?: string;
}

export interface Pager {
  nextPageToken?: string;
}

export interface CreatePointRechargeRequest {
  amount?: number;
  paymentMethod?: PaymentMethod;
}

export interface CreatePointRechargeResponse {
  amount?: number;
  currentPoints?: string;
  currentSales?: string;
}

export interface CreateBankAccountContractRequest {
  familyName?: string;
  firstName?: string;
  familyNameKana?: string;
  firstNameKana?: string;
  birthday?: google_type_date.Date;
  purpose?: string;
  address?: Address;
  job?: Job;
  bankCode?: string;
  branchCode?: string;
  accountType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccountType;
  accountNumber?: string;
  fatcaApplicable?: boolean;
  gender?: Gender;
  resetPasscode?: boolean;
  purposes?: PurposeOfUse[];
}

export interface CreateBankAccountContractResponse {
  startUrl?: string;
}

export interface CreateOneTimeCodeRequest {
  allowLenientRefresh?: boolean;
}

export interface CreateOneTimeCodeResponse {
  barcode?: string;
  qrCode?: string;
  expireTime?: string;
}

export interface FeatureFlag {
  name?: string;
  variantValue?: number;
}

export interface GetDashboardRequest {
  isNazcaCompatible?: boolean;
  featureFlags?: FeatureFlag[];
}

export interface GetDashboardResponse {
  balance?: UserBalance;
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  nfcRegistrationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_nfc.NFCRegistrationStatus;
  deferredPaymentOverdue?: boolean;
  paymentStatus?: PaymentStatus;
  unavailableFields?: GetDashboardResponse.DashboardField[];
  guides?: GetDashboardResponse.Guide[];
  wideButtons?: GetDashboardResponse.WideButton[];
  tutorialConditions?: GetDashboardResponse.TutorialConditions;
  postPayAppeal?: GetDashboardResponse.PostPayAppeal;
  nextActionSupportArea?: DashboardNextActionSupportItem[];
  campaignBanners?: Campaign[];
  repaymentReminder?: GetDashboardResponse.RepaymentReminder;
  postpayLimitLogInfo?: LogInfoV1;
  easypayUserInfo?: GetDashboardResponse.EasypayUserInfo;
  dashboardLogInfo?: GetDashboardResponse.DashboardLogInfo;
  dashboardBrazeInfo?: GetDashboardResponse.DashboardBrazeInfo;
  cpmUserEventPollingDelaySeconds?: string;
  cpmUserEventPollingIntervalSeconds?: string;
  codePaymentStatus?: GetDashboardResponse.CodePaymentStatus;
  realcardStatus?: GetDashboardResponse.RealcardStatus;
  benefitModule?: GetDashboardResponse.BenefitModule;
  listMenu?: GetDashboardResponse.ListMenu;
  recentPaymentHistories?: GetDashboardResponse.DashboardActionableHistory[];
  rewardsStatus?: GetDashboardResponse.RewardsStatus;
  lendingUserInfo?: GetDashboardResponse.LendingUserInfo;
  matsuriTypeBeta?: DashboardMatsuriTypeBeta;
  matsuriTypeV3?: DashboardMatsuriTypeV3;
}

export namespace GetDashboardResponse {
  export interface Guide {
    imageUrl?: string;
    pageUrl?: string;
    title?: string;
    description?: string;
    iconImageUrl?: string;
    backgroundColor?: GetDashboardResponse.Guide.Color;
    logInfo?: LogInfoV1;
  }

  export namespace Guide {
    export enum Color {
      COLOR_UNSPECIFIED = "COLOR_UNSPECIFIED",
      ID_PRIMARY = "ID_PRIMARY",
      CHERRY = "CHERRY",
      SKY = "SKY",
      MINT = "MINT",
      MARINE = "MARINE",
      ORANGE = "ORANGE",
      BLACK = "BLACK",
      DARK_MINT = "DARK_MINT",
      BLOOD_ORANGE = "BLOOD_ORANGE"
    }
  }

  export interface TutorialConditions {
    canShowBankTutorial?: boolean;
  }

  export interface WideButton {
    wideButtonContentsMerpayDeepLink?: GetDashboardResponse.WideButton.WideButtonContentsMerpayDeepLink;
    wideButtonContentsNfc?: GetDashboardResponse.WideButton.WideButtonContentsNFC;
  }

  export namespace WideButton {
    export interface WideButtonContentsNFC {
      beforeProvisioning?: GetDashboardResponse.WideButton.WideButtonContentsNFC.WideButtonContentNFC;
      afterProvisioning?: GetDashboardResponse.WideButton.WideButtonContentsNFC.WideButtonContentNFC;
    }

    export namespace WideButtonContentsNFC {
      export interface WideButtonNFCVideoLink {
        isShow?: boolean;
      }

      export interface WideButtonContentNFC {
        headingLabel?: GetDashboardResponse.WideButton.WideButtonLabel;
        subLabel?: GetDashboardResponse.WideButton.WideButtonLabel;
        logInfo?: GetDashboardResponse.WideButton.WideButtonLogInfo;
        statusText?: string;
        headBalloon?: GetDashboardResponse.WideButton.WideButtonHeadBalloon;
        footer?: GetDashboardResponse.WideButton.WideButtonFooter;
        nfcVideoLink?: GetDashboardResponse.WideButton.WideButtonContentsNFC.WideButtonNFCVideoLink;
      }
    }

    export interface WideButtonContentsMerpayDeepLink {
      headingLabel?: GetDashboardResponse.WideButton.WideButtonLabel;
      subLabel?: GetDashboardResponse.WideButton.WideButtonLabel;
      url?: string;
      logInfo?: GetDashboardResponse.WideButton.WideButtonLogInfo;
      statusText?: string;
      headBalloon?: GetDashboardResponse.WideButton.WideButtonHeadBalloon;
      footer?: GetDashboardResponse.WideButton.WideButtonFooter;
    }

    export interface WideButtonLabel {
      text?: string;
      colorType?: GetDashboardResponse.WideButton.WideButtonLabelColorType;
      isBold?: boolean;
    }

    export interface WideButtonLogInfo {
      eventId?: string;
      propViewNameValue?: string;
      propDecorationNameValue?: string;
    }

    export interface WideButtonHeadBalloon {
      isShow?: boolean;
      text?: string;
    }

    export interface WideButtonFooter {
      isShow?: boolean;
      imageUrl?: string;
      imageUrls?: string[];
      externalImagesType?: ImagesByUserConditionType;
    }

    export enum WideButtonLabelColorType {
      WIDE_BUTTON_LABEL_COLOR_TYPE_UNSPECIFIED = "WIDE_BUTTON_LABEL_COLOR_TYPE_UNSPECIFIED",
      PRIMARY = "PRIMARY",
      SECONDARY = "SECONDARY",
      ACCENT = "ACCENT"
    }
  }

  export interface PostPayAppeal {
    isShow?: boolean;
    expectedAmountText?: string;
    buttonText?: string;
    linkUrl?: string;
    logInfo?: GetDashboardResponse.PostPayAppeal.PostPayAppealLogInfo;
    contentsTypeImage?: GetDashboardResponse.PostPayAppeal.PostPayAppealContentsTypeImage;
    contentsTypeLottie?: GetDashboardResponse.PostPayAppeal.PostPayAppealContentsTypeLottie;
  }

  export namespace PostPayAppeal {
    export interface PostPayAppealContentsTypeImage {
      url?: string;
    }

    export interface PostPayAppealContentsTypeLottie {
      url?: string;
    }

    export interface PostPayAppealLogInfo {
      eventId?: string;
      propViewNameValue?: string;
      propIsFundsValue?: number;
    }
  }

  export interface RepaymentReminder {
    titleTextTypeAttention?: GetDashboardResponse.RepaymentReminder.AttentionLabel;
    titleTextTypeNormal?: GetDashboardResponse.RepaymentReminder.NormalLabel;
    mainText?: string;
    subText?: string;
    colorType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_design_system.DesignSystemStyle.IconColorType;
    logInfo?: GetDashboardResponse.RepaymentReminder.LogInfo;
  }

  export namespace RepaymentReminder {
    export interface AttentionLabel {
      text?: string;
      iconUrl?: string;
    }

    export interface NormalLabel {
      text?: string;
    }

    export interface LogInfo {
      props?: GetDashboardResponse.RepaymentReminder.LogInfo.PropsEntry[];
    }

    export namespace LogInfo {
      export interface PropsEntry {
        key?: string;
        value?: string;
      }
    }
  }

  export interface EasypayUserInfo {
    easypayStatus?: GetDashboardResponse.EasypayUserInfo.EasypayStatus;
    completedInvoiceMigration?: boolean;
  }

  export namespace EasypayUserInfo {
    export enum EasypayStatus {
      EASYPAY_STATUS_UNSPECIFIED = "EASYPAY_STATUS_UNSPECIFIED",
      NOT_SIGNED = "NOT_SIGNED",
      SIGNED = "SIGNED",
      CANCELED = "CANCELED"
    }
  }

  export interface DashboardLogInfo {
    top?: LogInfoV1;
    postpayChangeBtn?: LogInfoV1;
    useHistory?: LogInfoV1;
    cashIntro?: LogInfoV1;
    postpayLimit?: LogInfoV1;
    easypaySettingMenu?: LogInfoV1;
    defpayBtn?: LogInfoV1;
    codeFailure?: LogInfoV1;
    codeSuccess?: LogInfoV1;
    balanceNonDisplay?: LogInfoV1;
  }

  export interface DashboardBrazeInfo {
    top?: BrazeInfoV1;
  }

  export interface CodePaymentStatus {
    usePasscode?: CodePaymentUsePasscode;
  }

  export interface RealcardStatus {
    applicationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardApplicationStatus;
    cardDetail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardDetail;
    isAvailable?: boolean;
  }

  export interface BenefitModule {
    imageUrl?: string;
    outerPayImageUrl?: string;
    logInfo?: LogInfoV1;
    isAvailable?: boolean;
    detailAppPayment?: GetDashboardResponse.BenefitModule.BenefitModuleDetail;
    detailRealcardPayment?: GetDashboardResponse.BenefitModule.BenefitModuleDetail;
    detailVcPayment?: GetDashboardResponse.BenefitModule.BenefitModuleDetail;
  }

  export namespace BenefitModule {
    export interface BenefitModuleDetail {
      imageUrl?: string;
      pageUrl?: string;
    }
  }

  export interface ListMenu {
    listMenuSections?: GetDashboardResponse.ListMenu.ListMenuSection[];
  }

  export namespace ListMenu {
    export interface ListMenuSection {
      title?: string;
      listMenuItems?: GetDashboardResponse.ListMenu.ListMenuItem[];
    }

    export interface ListMenuItem {
      label?: string;
      linkUrl?: string;
      type?: GetDashboardResponse.ListMenu.ListMenuItem.ListMenuItemType;
      logInfo?: LogInfoV1;
    }

    export namespace ListMenuItem {
      export enum ListMenuItemType {
        LIST_MENU_ITEM_TYPE_UNSPECIFIED = "LIST_MENU_ITEM_TYPE_UNSPECIFIED",
        URL = "URL",
        PAYMENT_SETTING = "PAYMENT_SETTING",
        CARD_LIST = "CARD_LIST",
        EXTERNAL_URL = "EXTERNAL_URL",
        SHOP_MAP = "SHOP_MAP"
      }
    }
  }

  export interface DashboardActionableHistory {
    history?: ActionableHistory;
  }

  export interface RewardsStatus {
    isVisible?: boolean;
    innerRewardsRate?: number;
    outerRewardsRate?: number;
  }

  export interface LendingUserInfo {
    hasLendingContractEver?: boolean;
  }

  export enum DashboardField {
    DASHBOARD_FIELD_UNSPECIFIED = "DASHBOARD_FIELD_UNSPECIFIED",
    BALANCE = "BALANCE",
    DEFAULT_BANK_ACCOUNT = "DEFAULT_BANK_ACCOUNT",
    NFC_REGISTRATION_STATUS = "NFC_REGISTRATION_STATUS"
  }
}

export interface LogInfoV1 {
  props?: LogInfoV1.PropsEntry[];
  eventId?: string;
}

export namespace LogInfoV1 {
  export interface PropsEntry {
    key?: string;
    value?: string;
  }
}

export interface BrazeInfoV1 {
  customEventProps?: BrazeInfoV1.CustomEventPropsEntry[];
}

export namespace BrazeInfoV1 {
  export interface CustomEventPropsEntry {
    key?: string;
    value?: BrazeInfoV1.CustomEventProp;
  }

  export interface CustomEventProp {
    booleanValue?: boolean;
    numberValue?: number;
    stringValue?: string;
    timeValue?: string;
  }
}

export interface DashboardNextActionSupportItem {
  attentionLabel?: DashboardNextActionSupportItem.AttentionLabel;
  boldText?: string;
  normalText?: string;
  linkUrl?: string;
  logInfo?: DashboardNextActionSupportItem.LogInfo;
  variantType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_design_system.DesignSystemStyleV3.InformationBubbleVariantType;
  leftMediaTypeImage?: DashboardNextActionSupportItem.MediaTypeImage;
  leftMediaTypeLottie?: DashboardNextActionSupportItem.MediaTypeLottie;
  rightMediaTypeImage?: DashboardNextActionSupportItem.MediaTypeImage;
  rightMediaTypeLottie?: DashboardNextActionSupportItem.MediaTypeLottie;
  iconTypeImage?: DashboardNextActionSupportItem.MediaTypeImage;
}

export namespace DashboardNextActionSupportItem {
  export interface AttentionLabel {
    text?: string;
    colorType?: DashboardNextActionSupportItem.AttentionLabel.ColorType;
  }

  export namespace AttentionLabel {
    export enum ColorType {
      COLOR_TYPE_UNSPECIFIED = "COLOR_TYPE_UNSPECIFIED",
      INFO = "INFO",
      GROWTH = "GROWTH",
      SUCCESS = "SUCCESS",
      WARNING = "WARNING",
      ALERT = "ALERT"
    }
  }

  export interface MediaTypeImage {
    url?: string;
  }

  export interface MediaTypeLottie {
    url?: string;
  }

  export interface LogInfo {
    prop?: DashboardNextActionSupportItem.LogInfo.PropEntry[];
  }

  export namespace LogInfo {
    export interface PropEntry {
      key?: string;
      value?: string;
    }
  }
}

export interface DashboardMatsuriTypeBeta {
  valueHeaderColorType?: DashboardMatsuriTypeBeta.ValueHeaderColorType;
  backgroundImageUrl?: string;
  secondaryBackgroundImageUrl?: string;
  matsuriButton?: DashboardMatsuriTypeBeta.Button;
  logInfo?: DashboardMatsuriTypeBeta.LogInfo;
  mainContentsTypeImage?: DashboardMatsuriTypeBeta.ContentsTypeImage;
  mainContentsTypeLottie?: DashboardMatsuriTypeBeta.ContentsTypeLottie;
  mainContentsTypeBlank?: DashboardMatsuriTypeBeta.ContentsTypeBlank;
}

export namespace DashboardMatsuriTypeBeta {
  export interface Button {
    text?: string;
    linkUrl?: string;
  }

  export interface ContentsTypeImage {
    url?: string;
    height?: number;
  }

  export interface ContentsTypeLottie {
    url?: string;
    height?: number;
  }

  export interface ContentsTypeBlank {
    height?: number;
  }

  export interface LogInfo {
    props?: DashboardMatsuriTypeBeta.LogInfo.PropsEntry[];
  }

  export namespace LogInfo {
    export interface PropsEntry {
      key?: string;
      value?: string;
    }
  }

  export enum ValueHeaderColorType {
    VALUE_HEADER_COLOR_TYPE_DEFAULT = "VALUE_HEADER_COLOR_TYPE_DEFAULT",
    LIGHT = "LIGHT"
  }
}

export interface DashboardMatsuriTypeV3 {
  backgroundImageUrl?: string;
  secondaryBackgroundImageUrl?: string;
  payAreaStyleType?: DashboardPayAreaStyleType;
  logInfo?: LogInfoV1;
  mainContentsTypeImage?: DashboardMatsuriTypeV3.ContentsTypeImage;
  mainContentsTypeLottie?: DashboardMatsuriTypeV3.ContentsTypeLottie;
}

export namespace DashboardMatsuriTypeV3 {
  export interface ContentsTypeImage {
    url?: string;
    linkUrl?: string;
  }

  export interface ContentsTypeLottie {
    url?: string;
    linkUrl?: string;
  }
}

export interface GetDashboardMessageRequest {}

export interface GetDashboardMessageResponse {
  message?: string;
  extend?: DashboardMessageExtend;
  messageType?: GetDashboardMessageResponse.MessageType;
}

export namespace GetDashboardMessageResponse {
  export enum MessageType {
    UNKNOWN = "UNKNOWN",
    INFO = "INFO",
    ERROR = "ERROR"
  }
}

export interface DashboardMessageExtend {
  key?: string;
  valString?: string;
  valIntent?: DashboardMessageIntent;
}

export interface DashboardMessageIntent {
  activity?: string;
  extra?: string;
}

export interface PostPayConfig {
  amountLimit?: number;
  isPostPaySetup?: boolean;
  easypayMonthlyInstallments?: number;
  defaultAmountLimit?: number;
  amountLimitNotSet?: boolean;
  amountLimitOptions?: number[];
  easypayMonthlyInstallmentsOptions?: number[];
  remainingCredit?: number;
  arrearage?: number;
  creditLine?: number;
}

export interface GetLandingPageLinkForFundsAccountOpeningRequest {}

export interface GetLandingPageLinkForFundsAccountOpeningResponse {
  message?: string;
  subMessage?: string;
  messageType?: GetLandingPageLinkForFundsAccountOpeningResponse.MessageType;
}

export namespace GetLandingPageLinkForFundsAccountOpeningResponse {
  export enum MessageType {
    UNKNOWN = "UNKNOWN",
    ONLY_EKYC = "ONLY_EKYC",
    CONNECT_BANKS = "CONNECT_BANKS",
    POST_PAY_AND_EKYC = "POST_PAY_AND_EKYC"
  }
}

export interface GetBalanceRequest {}

export interface GetBalanceResponse {
  balance?: UserBalance;
}

export interface UserBalance {
  balance?: string;
  point?: string;
  freePoint?: string;
  prepaidPoint?: string;
  hasFundsAccount?: boolean;
}

export interface GetHasFundsAccountRequest {}

export interface GetHasFundsAccountResponse {
  hasFundsAccount?: boolean;
}

export interface ListFundsHistoriesRequest {
  listOption?: ListOption;
}

export interface ListFundsHistoriesResponse {
  results?: ListFundsHistoriesResponse.FundsHistory[];
  pager?: Pager;
}

export namespace ListFundsHistoriesResponse {
  export interface FundsHistory {
    id?: string;
    title?: string;
    merchantLogoUrl?: string;
    datetime?: string;
    changedAmount?: string;
    type?: FundsHistoryType;
    mercariExtraInfo?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_mercari.HistoryMercariExtraInfo;
  }
}

export interface GetFundsHistoryRequest {
  id?: string;
}

export interface HistoryEntityNFCPayment {
  merchantName?: string;
  logoUrl?: string;
  paymentType?: HistoryEntityNFCPayment.PaymentType;
  totalAmount?: number;
  fundsAmount?: number;
  pointAmount?: number;
  datetime?: string;
  inquiryCode?: string;
  inquiryId?: InquiryID;
}

export namespace HistoryEntityNFCPayment {
  export enum PaymentType {
    UNKNOWN = "UNKNOWN",
    NFC_APPLE_PAY_ID = "NFC_APPLE_PAY_ID",
    NFC_APPLE_PAY_EC = "NFC_APPLE_PAY_EC",
    NFC_ANDROID_ID = "NFC_ANDROID_ID",
    NFC_VIRTUAL_CARD = "NFC_VIRTUAL_CARD"
  }
}

export interface HistoryEntityCodePayment {
  merchantName?: string;
  logoUrl?: string;
  paymentType?: string;
  totalAmount?: number;
  fundsAmount?: number;
  pointAmount?: number;
  datetime?: string;
  inquiryCode?: string;
  inquiryId?: InquiryID;
  contact?: HistoryEntityCodePayment.Contact;
}

export namespace HistoryEntityCodePayment {
  export interface Contact {
    phoneNumber?: string;
  }
}

export interface HistoryEntityNetpayment {
  merchantName?: string;
  logoUrl?: string;
  paymentType?: string;
  totalAmount?: number;
  fundsAmount?: number;
  pointAmount?: number;
  deferAmount?: number;
  datetime?: string;
  inquiryCode?: string;
  inquiryId?: InquiryID;
}

export interface HistoryEntityBankCharge {
  bankName?: string;
  amount?: number;
  datetime?: string;
}

export interface HistoryEntityCampaign {
  title?: string;
  logoUrl?: string;
  pointAmount?: number;
  datetime?: string;
  historyPopupNone?: HistoryPopupNone;
  historyPopupInApp?: HistoryPopupInApp;
}

export interface HistoryEntityRecharge {
  title?: string;
  from?: string;
  logoUrl?: string;
  fundsAmount?: number;
  pointAmount?: number;
  datetime?: string;
}

export interface HistoryEntityPersonalTransfer {
  transferId?: string;
}

export interface HistoryEntityConnect {
  merchantName?: string;
  logoUrl?: string;
  paymentType?: string;
  totalAmount?: number;
  fundsAmount?: number;
  pointAmount?: number;
  deferAmount?: number;
  datetime?: string;
  inquiryCode?: string;
  inquiryId?: InquiryID;
  partnerInquiryCode?: string;
  partnerId?: string;
}

export interface HistoryEntityConnectTransfer {
  merchantName?: string;
  logoUrl?: string;
  paymentType?: string;
  totalAmount?: number;
  fundsAmount?: number;
  pointAmount?: number;
  datetime?: string;
  inquiryCode?: string;
  inquiryId?: InquiryID;
  partnerInquiryCode?: string;
  partnerId?: string;
}

export interface HistoryEntityRealcard {
  merchantName?: string;
  logoUrl?: string;
  refund?: HistoryEntityRealcard.Refund;
  charge?: HistoryEntityRealcard.Charge;
}

export namespace HistoryEntityRealcard {
  export interface Refund {
    totalAmount?: number;
    fundsAmount?: number;
    pointAmount?: number;
    localAmount?: string;
    exchangeRate?: string;
    inquiryId?: InquiryID;
    executeTime?: string;
  }

  export interface Charge {
    totalAmount?: number;
    paymentType?: string;
    localAmount?: string;
    exchangeRate?: string;
    executeTime?: string;
  }
}

export interface HistoryPopupNone {}

export interface HistoryPopupInApp {
  imageId?: number;
  title?: string;
  detail?: string;
  pointAmount?: number;
}

export interface HistoryEntityRefund {
  name?: string;
  logoUrl?: string;
  totalAmount?: number;
  fundsAmount?: number;
  pointAmount?: number;
  datetime?: string;
  inquiryCode?: string;
  inquiryId?: InquiryID;
  withAuthorization?: boolean;
  deferAmount?: number;
  deferReimburseType?: HistoryEntityRefund.DeferReimburseType;
}

export namespace HistoryEntityRefund {
  export enum DeferReimburseType {
    DEFER_REIMBURSE_TYPE_UNSPECIFIED = "DEFER_REIMBURSE_TYPE_UNSPECIFIED",
    FUNDS = "FUNDS",
    SALES = "SALES"
  }
}

export interface GetFundsHistoryResponse {
  id?: string;
  historyType?: FundsHistoryType;
  nfcPayment?: HistoryEntityNFCPayment;
  codePayment?: HistoryEntityCodePayment;
  bankCharge?: HistoryEntityBankCharge;
  netpayment?: HistoryEntityNetpayment;
  recharge?: HistoryEntityRecharge;
  personalTransfer?: HistoryEntityPersonalTransfer;
  connect?: HistoryEntityConnect;
  connectTransfer?: HistoryEntityConnectTransfer;
  realcard?: HistoryEntityRealcard;
  refund?: HistoryEntityRefund;
  refunds?: HistoryEntityRefund[];
}

export interface ListPointHistoriesRequest {
  listOption?: ListOption;
}

export interface ListPointHistoriesResponse {
  results?: ListPointHistoriesResponse.PointHistory[];
  pager?: Pager;
}

export namespace ListPointHistoriesResponse {
  export interface PointHistory {
    id?: string;
    title?: string;
    merchantLogoUrl?: string;
    datetime?: string;
    changedAmount?: string;
    type?: PointHistoryType;
    mercariExtraInfo?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_mercari.HistoryMercariExtraInfo;
  }
}

export interface GetPointHistoryRequest {
  id?: string;
}

export interface SearchPointHistoryByMetadataHistoryKeyRequest {
  key?: string;
}

export interface GetPointHistoryResponse {
  id?: string;
  historyType?: PointHistoryType;
  nfcPayment?: HistoryEntityNFCPayment;
  codePayment?: HistoryEntityCodePayment;
  netpayment?: HistoryEntityNetpayment;
  campaign?: HistoryEntityCampaign;
  recharge?: HistoryEntityRecharge;
  personalTransfer?: HistoryEntityPersonalTransfer;
  connect?: HistoryEntityConnect;
  connectTransfer?: HistoryEntityConnectTransfer;
  realcard?: HistoryEntityRealcard;
  refund?: HistoryEntityRefund;
  refunds?: HistoryEntityRefund[];
}

export interface GetDeferredHistoryRequest {
  id?: string;
}

export interface GetDeferredHistoryResponse {
  id?: string;
  payment?: GetDeferredHistoryResponse.Payment;
}

export namespace GetDeferredHistoryResponse {
  export interface Payment {
    merchantName?: string;
    logoUrl?: string;
    paymentType?: string;
    totalAmount?: number;
    datetime?: string;
    inquiryId?: InquiryID;
  }
}

export interface GetActionableHistorySummaryRequest {
  yearmonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  featureFlags?: FeatureFlag[];
}

export interface GetActionableHistorySummaryResponse {
  totalUsageAmount?: string;
  alreadyPaidAmount?: string;
  smartpay?: GetActionableHistorySummaryResponse.SmartpaySummary;
  easypay?: GetActionableHistorySummaryResponse.EasypaySummary;
  repaymentReminder?: GetActionableHistorySummaryResponse.SmartpayRepaymentReminder;
  appealBanner?: GetActionableHistorySummaryResponse.AppealBanner;
}

export namespace GetActionableHistorySummaryResponse {
  export interface SmartpaySummary {
    invoiceStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.Invoice.Status;
    usageAmount?: string;
  }

  export interface EasypaySummary {
    userStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentUser.EasypayStatus;
    usageAmount?: string;
  }

  export interface SmartpayRepaymentReminder {
    invoiceStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.Invoice.Status;
    useMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
    amount?: string;
    defaultPayType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentRepaymentType;
  }

  export interface LogInfo {
    id?: string;
    props?: GetActionableHistorySummaryResponse.LogInfo.PropsEntry[];
  }

  export namespace LogInfo {
    export interface PropsEntry {
      key?: string;
      value?: string;
    }
  }

  export interface AppealBanner {
    iconUrl?: string;
    title?: string;
    body?: string;
    linkUrl?: string;
    displayLogInfo?: GetActionableHistorySummaryResponse.LogInfo;
    tapLogInfo?: GetActionableHistorySummaryResponse.LogInfo;
  }
}

export interface ActionableHistory {
  paymentId?: string;
  paymentResourceType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_payment.PaymentResourceType;
  description?: string;
  thumbnailUrl?: string;
  occuredAt?: string;
  inquiryId?: InquiryID;
  paymentType?: ActionableHistory.PaymentType;
  mercariExtraInfo?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_mercari.HistoryMercariExtraInfo;
  promotions?: ActionableHistoryPromotion[];
  point?: ActionableHistory.RecordEntry;
  fund?: ActionableHistory.RecordEntry;
  defpay?: ActionableHistory.RecordEntry;
  foreignExchange?: ActionableHistory.ForeignExchange;
  reward?: ActionableHistory.Reward;
}

export namespace ActionableHistory {
  export interface RecordEntry {
    symbol?: ActionableHistory.RecordEntry.Symbol;
    amount?: string;
    status?: ActionableHistory.RecordEntry.Status;
    defpay?: ActionableHistory.RecordEntry.DefpayDetail;
  }

  export namespace RecordEntry {
    export interface DefpayDetail {
      smartpayType?: ActionableHistory.RecordEntry.DefpayDetail.SmartpayType;
      paidAmount?: string;
      feeRate?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayFeeRate[];
      campaignInfo?: GetMonthlyClearPaymentResponse.ApplicableCampaignInfo;
      convertibleUntil?: string;
      expectedBillingDate?: google_type_date.Date;
      inquiry?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage;
    }

    export namespace DefpayDetail {
      export enum SmartpayType {
        UNKNOWN = "UNKNOWN",
        MONTHLY_CLEAR = "MONTHLY_CLEAR",
        EASYPAY = "EASYPAY"
      }
    }

    export enum Status {
      NONE = "NONE",
      UNPAID = "UNPAID",
      PAYABLE = "PAYABLE",
      PAID = "PAID",
      CANCELED = "CANCELED",
      EASYPAY_CONVERTED = "EASYPAY_CONVERTED",
      OVERDUE = "OVERDUE",
      IN_PROGRESS = "IN_PROGRESS",
      VALIDITY_CHECK = "VALIDITY_CHECK"
    }

    export enum Symbol {
      YEN = "YEN",
      POINT = "POINT"
    }
  }

  export interface Reward {
    status?: ActionableHistory.Reward.RewardStatus;
    name?: string;
    totalAmount?: string;
    campaigns?: ActionableHistory.Reward.Campaign[];
  }

  export namespace Reward {
    export interface Campaign {
      name?: string;
      rewardAmount?: string;
      rewardRate?: number;
    }

    export enum RewardStatus {
      UNKNOWN = "UNKNOWN",
      REWARDED = "REWARDED",
      BEFORE_REWARDED = "BEFORE_REWARDED",
      NO_REWARD = "NO_REWARD"
    }
  }

  export interface ForeignExchange {
    currencyName?: string;
    symbol?: string;
    amount?: string;
    precision?: number;
    exchangeRate?: number;
    canceledExchangeRate?: number;
  }

  export enum PaymentType {
    UNKNOWN = "UNKNOWN",
    CODE_PAYMENT = "CODE_PAYMENT",
    NET_PAYMENT = "NET_PAYMENT",
    CONNECT = "CONNECT",
    REAL_CARD = "REAL_CARD",
    NFC_APPLE_PAY_ID = "NFC_APPLE_PAY_ID",
    NFC_APPLE_PAY_EC = "NFC_APPLE_PAY_EC",
    NFC_ANDROID_ID = "NFC_ANDROID_ID",
    NFC_VIRTUAL_CARD = "NFC_VIRTUAL_CARD"
  }
}

export interface ActionableHistoryPromotion {
  type?: ActionableHistoryPromotion.Type;
  payLaterWithSmartmoney?: ActionableHistoryPromotionPayLaterWithSmartmoney;
}

export namespace ActionableHistoryPromotion {
  export enum Type {
    NONE = "NONE",
    PAY_LATER_WITH_SMARTMONEY = "PAY_LATER_WITH_SMARTMONEY"
  }
}

export interface ActionableHistoryPromotionPayLaterWithSmartmoney {
  listMonthlyRepayAmountChoices?: string[];
  hasContract?: boolean;
}

export interface GetActionableHistoryRequest {
  paymentId?: string;
}

export interface GetActionableHistoryResponse {
  history?: ActionableHistory;
  showAppealBanner?: boolean;
  appealBanner?: GetActionableHistoryResponse.AppealBanner;
}

export namespace GetActionableHistoryResponse {
  export interface LogInfo {
    id?: string;
    props?: GetActionableHistoryResponse.LogInfo.PropsEntry[];
  }

  export namespace LogInfo {
    export interface PropsEntry {
      key?: string;
      value?: string;
    }
  }

  export interface AppealBanner {
    iconUrl?: string;
    title?: string;
    body?: string;
    linkUrl?: string;
    displayLogInfo?: GetActionableHistoryResponse.LogInfo;
    tapLogInfo?: GetActionableHistoryResponse.LogInfo;
  }
}

export interface ListActionableHistoriesRequest {
  listOption?: ListOption;
  filterOption?: ListActionableHistoriesRequest.FilterOption;
}

export namespace ListActionableHistoriesRequest {
  export interface FilterOption {
    yearmonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  }
}

export interface ListActionableHistoriesResponse {
  pager?: Pager;
  entries?: ActionableHistory[];
}

export interface ListPointExpiryDatesRequest {}

export interface ListPointExpiryDatesResponse {
  expiryDates?: ListPointExpiryDatesResponse.ExpiryDate[];
}

export namespace ListPointExpiryDatesResponse {
  export interface ExpiryDate {
    expireDate?: google_type_date.Date;
    amount?: string;
    purchased?: boolean;
  }
}

export interface ListSalesExpiryDatesRequest {}

export interface ListSalesExpiryDatesResponse {
  expiryDates?: ListSalesExpiryDatesResponse.ExpiryDate[];
}

export namespace ListSalesExpiryDatesResponse {
  export interface ExpiryDate {
    expireDate?: google_type_date.Date;
    amount?: string;
  }
}

export interface ListUserBankAccountsRequest {}

export interface ListUserBankAccountsResponse {
  bankAccounts?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount[];
}

export interface ListBanksRequest {}

export interface ListBanksResponse {
  banks?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.Bank[];
}

export interface ListBankBranchesRequest {
  bankCode?: string;
}

export interface ListBankBranchesResponse {
  bankBranches?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankBranch[];
}

export interface GetUserDeletableStatusRequest {}

export interface UserDeletableStatus {
  deletable?: boolean;
  reasons?: UserUndeletableReason[];
}

export interface GetUserPaymentConfigRequest {}

export interface UpdateUserPaymentConfigPostPayPaymentModeRequest {
  postPayPaymentMode?: UserPaymentConfig.PostPayPaymentMode;
}

export interface GetAccessoryMessageForSwitchPaymentModeRequest {}

export interface GetAccessoryMessageForSwitchPaymentModeResponse {
  postPayMessage?: GetAccessoryMessageForSwitchPaymentModeResponse.PostPayMessage;
}

export namespace GetAccessoryMessageForSwitchPaymentModeResponse {
  export interface PostPayMessage {
    hasMessage?: boolean;
    message?: string;
  }
}

export interface IdentifyQRCodeTypeRequest {
  payload?: string;
}

export interface IdentifyQRCodeTypeResponse {
  type?: QRCodeType;
  information?: QRCodeInformation;
}

export interface QRCodeInformation {
  sevenBankInfo?: QRCodeInformation.SevenBankInformation;
  openBrowserInfo?: QRCodeInformation.OpenBrowserInformation;
}

export namespace QRCodeInformation {
  export interface SevenBankInformation {
    companyCode?: string;
    isForbidden?: boolean;
  }

  export interface OpenBrowserInformation {
    openUrl?: string;
  }
}

export interface CreateStaticMPMSessionRequest {
  payload?: string;
}

export interface CreateStaticMPMSessionResponse {
  token?: string;
  displayName?: string;
  oneTimeAmountLimit?: number;
  paymentStatus?: PaymentStatus;
  displayAmount?: number;
  changeableAmount?: boolean;
}

export interface CreateStaticMPMChargeRequest {
  token?: string;
  totalAmount?: number;
  ignoreWarning?: boolean;
}

export interface CreateStaticMPMChargeResponse {
  charge?: CreateStaticMPMChargeResponse.Charge;
}

export namespace CreateStaticMPMChargeResponse {
  export interface Charge {
    totalAmount?: number;
    fundsAmount?: number;
    pointAmount?: number;
    inquiryId?: InquiryID;
    executeTime?: string;
  }
}

export interface InquiryID {
  executeDate?: google_type_date.Date;
  code?: string;
}

export interface GetUserLegacyTransactionStatusRequest {}

export interface UserLegacyTransactionStatusResponse {
  hasAny?: boolean;
}

export interface GetUserOngoingPayoutRequestStatusRequest {}

export interface UserOngoingPayoutRequestStatus {
  hasAny?: boolean;
}

export interface GetUserIsBusinessAccountStatusRequest {}

export interface UserIsBusinessAccountStatusResponse {
  isBusinessAccount?: boolean;
}

export interface GetPasscodeStatusRequest {}

export interface CreatePasscodeRequest {
  passcode?: string;
}

export interface CreatePasscodeResponse {}

export interface UpdatePasscodeRequest {
  oldPasscode?: string;
  newPasscode?: string;
}

export interface UpdatePasscodeResponse {}

export interface CheckPasscodeFormatRequest {
  passcode?: string;
}

export interface CheckPasscodeFormatResponse {}

export interface VerifyPasscodeRequest {
  passcode?: string;
}

export interface VerifyPasscodeResponse {
  result?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode.PasscodeVerificationResult;
}

export interface SendPasscodeResetOTPRequest {
  bankAccountNumber?: string;
}

export interface SendPasscodeResetOTPResponse {
  result?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode.SendPasscodeResetOTPResult;
}

export interface VerifyPasscodeResetOTPRequest {
  otp?: string;
}

export interface VerifyPasscodeResetOTPResponse {
  result?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode.VerifyPasscodeResetOTPResult;
}

export interface ResetPasscodeWithOTPRequest {
  otp?: string;
  newPasscode?: string;
}

export interface ResetPasscodeWithOTPResponse {}

export interface GetPasscodeSettingsRequest {}

export interface GetPasscodeSettingsResponse {
  usePasscodeForCodePayment?: CodePaymentUsePasscode;
}

export interface ListCampaignsRequest {}

export interface ListCampaignsResponse {
  campaigns?: Campaign[];
}

export interface Campaign {
  id?: string;
  campaignImageUrl?: string;
  campaignPageUrl?: string;
  startedAt?: string;
  expiredAt?: string;
  campaignType?: Campaign.CampaignType;
}

export namespace Campaign {
  export enum CampaignType {
    CAMPAIGN_TYPE_UNSPECIFIED = "CAMPAIGN_TYPE_UNSPECIFIED",
    GENERIC = "GENERIC",
    COUPON = "COUPON"
  }
}

export interface GetAfterNFCProvisioningPopupRequest {
  dashboardType?: ClientDashboardType;
}

export interface GetAfterNFCProvisioningPopupResponse {
  hasPopup?: boolean;
  url?: string;
}

export interface GetAfterNFCProvisioningFTAPopupRequest {}

export interface GetAfterNFCProvisioningFTAPopupResponse {
  isShow?: boolean;
  imageUrl?: string;
  actionButtonInfo?: GetAfterNFCProvisioningFTAPopupResponse.MerpayDeepLinkActionButtonInfo;
  closeButtonInfo?: GetAfterNFCProvisioningFTAPopupResponse.CloseButtonInfo;
  logInfo?: GetAfterNFCProvisioningFTAPopupResponse.LogInfo;
}

export namespace GetAfterNFCProvisioningFTAPopupResponse {
  export interface LogInfo {
    eventId?: string;
  }

  export interface MerpayDeepLinkActionButtonInfo {
    label?: string;
    url?: string;
    logInfo?: GetAfterNFCProvisioningFTAPopupResponse.LogInfo;
  }

  export interface CloseButtonInfo {
    label?: string;
    logInfo?: GetAfterNFCProvisioningFTAPopupResponse.LogInfo;
  }
}

export interface GetSalesZeroPopupRequest {}

export interface GetSalesZeroPopupResponse {
  title?: string;
  imageUrl?: string;
  actionButtonInfo?: GetSalesZeroPopupResponse.MerpayDeepLinkActionButtonInfo;
  closeButtonInfo?: GetSalesZeroPopupResponse.CloseButtonInfo;
  logInfo?: GetSalesZeroPopupResponse.LogInfo;
  isShow?: boolean;
}

export namespace GetSalesZeroPopupResponse {
  export interface LogInfo {
    eventId?: string;
  }

  export interface MerpayDeepLinkActionButtonInfo {
    label?: string;
    url?: string;
    logInfo?: GetSalesZeroPopupResponse.LogInfo;
  }

  export interface CloseButtonInfo {
    label?: string;
    logInfo?: GetSalesZeroPopupResponse.LogInfo;
  }
}

export interface GetBillsTransferBannerRequest {
  isNazcaCompatible?: boolean;
}

export interface GetBillsTransferBannerResponse {
  billsTransferBannerMerpayDeepLink?: GetBillsTransferBannerResponse.BillsTransferBannerMerpayDeepLink;
  billsTransferBannersNfc?: GetBillsTransferBannerResponse.BillsTransferBannersNFC;
}

export namespace GetBillsTransferBannerResponse {
  export interface LogInfo {
    eventId?: string;
    propBannerNameValue?: string;
  }

  export interface BillsTransferBannerMerpayDeepLink {
    isShow?: boolean;
    imageUrl?: string;
    linkUrl?: string;
    logInfo?: GetBillsTransferBannerResponse.LogInfo;
  }

  export interface BillsTransferBannersNFC {
    nfcRegistrationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_nfc.NFCRegistrationStatus;
    beforeProvisioning?: GetBillsTransferBannerResponse.BillsTransferBannerMerpayDeepLink;
    afterProvisioning?: GetBillsTransferBannerResponse.BillsTransferBannerMerpayDeepLink;
  }
}

export interface ListCouponsRequest {
  paymentTypes?: CouponPaymentType[];
}

export interface ListCouponsResponse {
  couponConfig?: CouponConfig;
  brands?: BrandForCoupon[];
  coupons?: Coupon[];
}

export interface GetCouponRequest {
  id?: string;
}

export interface GetCouponResponse {
  couponConfig?: CouponConfig;
  coupon?: Coupon;
}

export interface IssueCouponRequest {
  id?: string;
  brandId?: string;
}

export interface IssueCouponResponse {
  couponConfig?: CouponConfig;
  coupon?: Coupon;
}

export interface GetPopupCouponRequest {
  paymentTypes?: CouponPaymentType[];
  ignoreCouponIds?: string[];
}

export interface GetPopupCouponResponse {
  couponConfig?: CouponConfig;
  coupon?: PopupCoupon;
}

export interface ListDashboardCouponsRequest {
  paymentTypes?: CouponPaymentType[];
}

export interface ListDashboardCouponsResponse {
  coupons?: DashboardCoupon[];
  seeMoreImageUrl?: string;
  listPageUrl?: string;
}

export interface DrawCouponRequest {
  id?: string;
  brandId?: string;
}

export interface DrawCouponResponse {
  couponConfig?: CouponConfig;
  coupon?: Coupon;
}

export interface GetCouponCampaign201912StatusRequest {}

export interface GetCouponCampaign201912StatusResponse {
  phase?: string;
}

export interface GetCouponCampaign202003StatusRequest {
  paymentTypes?: CouponPaymentType[];
}

export interface GetCouponCampaign202003StatusResponse {
  ftaStatus?: GetCouponCampaign202003StatusResponse.FTAStatus;
  ftaStatusPhase2?: GetCouponCampaign202003StatusResponse.FTAStatus;
  ppStatus?: GetCouponCampaign202003StatusResponse.PPStatus;
  coupons?: Coupon[];
  lpImage?: string;
  exhausted?: boolean;
  isLandingPageVisible?: boolean;
  isLandingPageVisiblePhase2?: boolean;
  rewardCoupons?: GetCouponCampaign202003StatusResponse.RewardCoupon[];
  rewardStartTime?: string;
  rewardEndTime?: string;
  rewardNotices?: string[];
}

export namespace GetCouponCampaign202003StatusResponse {
  export interface RewardCoupon {
    lpThumbnailUrl?: string;
    listThumbnailUrl?: string;
  }

  export enum FTAStatus {
    FTA_NONE = "FTA_NONE",
    FTA_CREATED_BEFORE_CAMPAIGN = "FTA_CREATED_BEFORE_CAMPAIGN",
    FTA_CREATED_IN_CAMPAIGN = "FTA_CREATED_IN_CAMPAIGN",
    FTA_NOT_CREATED = "FTA_NOT_CREATED"
  }

  export enum PPStatus {
    PP_NONE = "PP_NONE",
    PP_CONSENTED_BEFORE_CAMPAIGN = "PP_CONSENTED_BEFORE_CAMPAIGN",
    PP_CONSENTED_IN_CAMPAIGN = "PP_CONSENTED_IN_CAMPAIGN",
    PP_NOT_CONSENTED = "PP_NOT_CONSENTED"
  }
}

export interface CouponConfig {
  pushConfig?: CouponMercariTopPopupConfig;
  nazcaProvisioningCampaignConfig?: CouponNazcaProvisioningCampaignConfig;
}

export interface CouponMercariTopPopupConfig {
  heavyUserPopupDuration?: string;
  lightUserPopupDuration?: string;
  popupDisplayDuration?: string;
}

export interface CouponNazcaProvisioningCampaignConfig {
  isActive?: boolean;
  pointAmount?: string;
}

export interface BrandForCoupon {
  id?: string;
  name?: string;
  logoImageUrl?: string;
  mapSearchWord?: string;
}

export interface CouponBrand {
  brand?: BrandForCoupon;
  conditionDetails?: string;
  whenUsingNoticeMessage?: string;
  conditionNoticeFirst?: string;
  conditionNoticeSecond?: string;
  codeImage?: CouponBrand.CouponCodeImage;
  codeBarcode?: CouponBrand.CouponCodeBarcode;
  codeQrcode?: CouponBrand.CouponCodeQrcode;
  codeNetshop?: CouponBrand.CouponCodeNetshop;
}

export namespace CouponBrand {
  export interface CouponCodeImage {
    url?: string;
  }

  export interface CouponCodeData {
    couponData?: string;
    couponDataString?: string;
    couponDetails?: string;
  }

  export interface CouponCodeBarcode {
    barcodeType?: CouponBrand.CouponBarcodeType;
    data?: CouponBrand.CouponCodeData;
  }

  export interface CouponCodeQrcode {
    qrcodeType?: CouponBrand.CouponQrcodeType;
    data?: CouponBrand.CouponCodeData;
  }

  export interface CouponCodeNetshop {
    noticeImageUrl?: string;
    shopUrl?: string;
  }

  export enum CouponBarcodeType {
    COUPON_BARCODE_TYPE_UNSPECIFIED = "COUPON_BARCODE_TYPE_UNSPECIFIED",
    COUPON_BARCODE_TYPE_NW7 = "COUPON_BARCODE_TYPE_NW7",
    COUPON_BARCODE_TYPE_TOF = "COUPON_BARCODE_TYPE_TOF"
  }

  export enum CouponQrcodeType {
    COUPON_QRCODE_TYPE_UNSPECIFIED = "COUPON_QRCODE_TYPE_UNSPECIFIED",
    COUPON_QRCODE_TYPE_MODEL2 = "COUPON_QRCODE_TYPE_MODEL2"
  }
}

export interface Coupon {
  id?: string;
  title?: string;
  isLimitReached?: boolean;
  couponBrands?: CouponBrand[];
  publishTime?: string;
  startTime?: string;
  endTime?: string;
  paymentTypes?: CouponPaymentType[];
  invalidationConditionNone?: Coupon.CouponInvalidationConditionNone;
  invalidationConditionTime?: Coupon.CouponInvalidationConditionTime;
  invalidationConditionPointback?: Coupon.CouponInvalidationConditionPointback;
  featureType?: Coupon.FeatureType;
  taxType?: Coupon.TaxType;
  price?: string;
  discountAmount?: string;
  priceString?: string;
  priceDisplayType?: Coupon.PriceDisplayType;
  regularPrice?: string;
  regularPriceTaxType?: Coupon.TaxType;
  priceNotice?: string;
  indexImageUrl?: string;
  detailImageUrl?: string;
  dashboardImageUrl?: string;
  mercariTopImageUrl?: string;
  detailPageUrl?: string;
  metadataImage?: Coupon.CouponMetadataImage;
  isLottery?: boolean;
  lotteryStatus?: LotteryCouponStatus;
  isPrivate?: boolean;
  isPointback?: boolean;
  isSmartpayLimited?: boolean;
}

export namespace Coupon {
  export interface CouponInvalidationConditionNone {}

  export interface CouponInvalidationConditionTime {
    validityDuration?: string;
    remainingDuration?: string;
    expireTime?: string;
  }

  export interface CouponInvalidationConditionPointback {
    hasEntry?: boolean;
    isUpperLimit?: boolean;
  }

  export interface CouponMetadataImage {
    imageUrl?: string;
  }

  export enum FeatureType {
    FEATURE_TYPE_UNSPECIFIED = "FEATURE_TYPE_UNSPECIFIED",
    FEATURE_TYPE_STANDARD = "FEATURE_TYPE_STANDARD",
    FEATURE_TYPE_PREMIUM = "FEATURE_TYPE_PREMIUM",
    FEATURE_TYPE_POINTBACK = "FEATURE_TYPE_POINTBACK"
  }

  export enum TaxType {
    TAX_TYPE_UNSPECIFIED = "TAX_TYPE_UNSPECIFIED",
    TAX_TYPE_INCLUDED = "TAX_TYPE_INCLUDED",
    TAX_TYPE_EXCLUDED = "TAX_TYPE_EXCLUDED"
  }

  export enum PriceDisplayType {
    PRICE_DISPLAY_TYPE_UNSPECIFIED = "PRICE_DISPLAY_TYPE_UNSPECIFIED",
    PRICE_DISPLAY_TYPE_DISCOUNTED_PRICE_WITH_REGULAR_PRICE = "PRICE_DISPLAY_TYPE_DISCOUNTED_PRICE_WITH_REGULAR_PRICE",
    PRICE_DISPLAY_TYPE_DISCOUNTED_PRICE = "PRICE_DISPLAY_TYPE_DISCOUNTED_PRICE",
    PRICE_DISPLAY_TYPE_DISCOUNT_AMOUNT = "PRICE_DISPLAY_TYPE_DISCOUNT_AMOUNT",
    PRICE_DISPLAY_TYPE_DISCOUNT_AMOUNT_PERCENTAGE = "PRICE_DISPLAY_TYPE_DISCOUNT_AMOUNT_PERCENTAGE",
    PRICE_DISPLAY_TYPE_POINTBACK_AMOUNT = "PRICE_DISPLAY_TYPE_POINTBACK_AMOUNT",
    PRICE_DISPLAY_TYPE_POINTBACK_AMOUNT_PERCENTAGE = "PRICE_DISPLAY_TYPE_POINTBACK_AMOUNT_PERCENTAGE"
  }
}

export interface PopupCoupon {
  id?: string;
  imageUrl?: string;
  pageUrl?: string;
  endTime?: string;
}

export interface DashboardCoupon {
  id?: string;
  imageUrl?: string;
  pageUrl?: string;
}

export interface LotteryCouponStatus {
  isDrawn?: boolean;
  isWin?: boolean;
  nextDrawableAt?: string;
}

export interface GetMercariHomeHeaderStateRequest {}

export interface GetMercariHomeHeaderStateResponse {
  button?: MercariHomeHeaderButton;
}

export interface GetOuterPayRecExperimentItemRequest {
  id?: string;
}

export interface GetOuterPayRecExperimentItemResponse {
  itemName?: string;
  brandName?: string;
  price?: string;
  notice?: string;
  imageUrl?: string;
  couponPageUrl?: string;
  isCouponUsed?: boolean;
}

export interface GetNetpaymentOrderDetailRequest {
  transactionId?: string;
}

export interface GetNetpaymentOrderDetailResponse {
  transactionId?: string;
  orderName?: string;
  orderImageUrl?: string;
  amount?: string;
  useShippingFee?: boolean;
  isFixedShippingFee?: boolean;
  shippingFee?: string;
  useDeliveryAddress?: boolean;
  deliveryAddresses?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentDeliveryAddress[];
  cancelUrl?: string;
  listTermsAndConditionsToAgree?: TermsAndConditions[];
  availablePaymentMethodsOld?: GetNetpaymentOrderDetailResponse.AvailablePaymentMethod[];
  prepaidPointUnavailable?: boolean;
  freePointUnavailable?: boolean;
  forcePasscode?: boolean;
  availablePaymentMethods?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentAvailablePaymentMethod[];
  paymentContractType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentPaymentContractType;
  preapprovedPaymentMethod?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentPreapprovedPaymentMethod;
  orderDescription?: string;
  isExpired?: boolean;
  canPaylisting?: boolean;
  transactionStatus?: GetNetpaymentOrderDetailResponse.TransactionStatus;
  requiresAuthorization?: boolean;
  fundsRechargeTriggerType?: CreateFundsRechargeRequest.TriggerType;
  easypayCampaignInfo?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentEasypayCampaignInfo;
}

export namespace GetNetpaymentOrderDetailResponse {
  export interface AvailablePaymentMethod {
    balance?: GetNetpaymentOrderDetailResponse.Balance;
    deferred?: GetNetpaymentOrderDetailResponse.Deferred;
  }

  export interface Balance {}

  export interface Deferred {}

  export enum TransactionStatus {
    TRANSACTION_STATUS_UNKNOWN = "TRANSACTION_STATUS_UNKNOWN",
    TRANSACTION_STATUS_PENDING = "TRANSACTION_STATUS_PENDING",
    TRANSACTION_STATUS_EXPIRED = "TRANSACTION_STATUS_EXPIRED",
    TRANSACTION_STATUS_AUTHORIZED = "TRANSACTION_STATUS_AUTHORIZED",
    TRANSACTION_STATUS_CAPTURED = "TRANSACTION_STATUS_CAPTURED",
    TRANSACTION_STATUS_CANCELED = "TRANSACTION_STATUS_CANCELED",
    TRANSACTION_STATUS_REFUNDED = "TRANSACTION_STATUS_REFUNDED"
  }
}

export interface UpdateNetpaymentDeliveryAddressRequest {
  transactionId?: string;
  deliveryAddressId?: string;
}

export interface UpdateNetpaymentDeliveryAddressResponse {
  transactionId?: string;
  shippingFee?: string;
}

export interface InvalidateNetpaymentRequest {
  transactionId?: string;
}

export interface InvalidateNetpaymentResponse {}

export interface ConfirmNetpaymentRequest {
  transactionId?: string;
  funds?: string;
  point?: string;
  paymentMethodType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentPaymentMethodType;
  paymentMethod?: ConfirmNetpaymentRequest.PaymentMethod;
  preapprovedPaymentMethod?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentPreapprovedPaymentMethod;
  publishItems?: boolean;
  idToken?: string;
}

export namespace ConfirmNetpaymentRequest {
  export interface PaymentMethod {
    balance?: ConfirmNetpaymentRequest.Balance;
    deferred?: ConfirmNetpaymentRequest.Deferred;
  }

  export interface Balance {
    point?: string;
    funds?: string;
    freePoint?: string;
    prepaidPoint?: string;
  }

  export interface Deferred {
    amount?: string;
    isEasypay?: boolean;
    easypayCampaignIds?: string[];
  }
}

export interface ConfirmNetpaymentResponse {
  transactionId?: string;
  returnUrl?: string;
}

export interface ListGuidesRequest {
  isNazcaCompatible?: boolean;
}

export interface ListGuidesResponse {
  guides?: ListGuidesResponse.Guide[];
}

export namespace ListGuidesResponse {
  export interface Guide {
    imageUrl?: string;
    pageUrl?: string;
  }
}

export interface ListImagesByUserConditionRequest {
  imagesType?: ImagesByUserConditionType;
}

export interface ListImagesByUserConditionResponse {
  imageUrls?: string[];
}

export interface ListCashIntroductionsRequest {
  featureFlags?: FeatureFlag[];
}

export interface ListCashIntroductionsResponse {
  cashIntroductions?: ListCashIntroductionsResponse.CashIntroduction[];
  launchItemIndex?: number;
  logInfo?: LogInfoV1;
}

export namespace ListCashIntroductionsResponse {
  export interface CashIntroduction {
    topContentsImageUrl?: string;
    title?: string;
    enabled?: boolean;
    descriptionRows?: ListCashIntroductionsResponse.CashIntroduction.DescriptionRow[];
    ctaButtonTypeNormal?: ListCashIntroductionsResponse.CashIntroduction.ButtonTypeNormal;
    ctaButtonTypeLabel?: ListCashIntroductionsResponse.CashIntroduction.ButtonTypeLabel;
  }

  export namespace CashIntroduction {
    export interface ButtonTypeNormal {
      text?: string;
      linkUrl?: string;
      logInfo?: LogInfoV1;
    }

    export interface ButtonTypeLabel {
      text?: string;
    }

    export interface DescriptionRow {
      titleText?: string;
      mainText?: string;
      linkText?: string;
      linkUrl?: string;
      thumbnailImageUrl?: string;
      logInfo?: LogInfoV1;
    }
  }
}

export interface GetBenefitPageRequest {}

export interface GetBenefitPageResponse {
  innerPayReductionRate?: number;
  outerPayReductionRate?: number;
  backgroundImageUrl?: string;
  backgroundAnimationUrl?: string;
  backgroundColorCode?: string;
  hint?: GetBenefitPageResponse.Hint;
  availablePromotions?: GetBenefitPageResponse.EntryTypeCampaignPromotionContent[];
  endedPromotions?: GetBenefitPageResponse.EntryTypeCampaignPromotionContent[];
  innerPayReductionContent?: GetBenefitPageResponse.InnerPayReductionContent;
  outerPayReductionContent?: GetBenefitPageResponse.OuterPayReductionContent;
}

export namespace GetBenefitPageResponse {
  export interface Hint {
    hintId?: string;
    subject?: string;
    body?: string;
    detailPageUrl?: string;
  }

  export interface EntryTypeCampaignPromotionContent {
    imageUrl?: string;
    title?: string;
    description?: string;
    promotionPeriod?: string;
    logoUrls?: string[];
    detailPageUrl?: string;
    promotion?: EntryTypeCampaignPromotion;
  }

  export interface Suggestion {
    text?: string;
    link?: string;
  }

  export interface StatusPage {
    text?: string;
    link?: string;
  }

  export interface InnerPayReductionContent {
    rate?: number;
    suggestion?: GetBenefitPageResponse.Suggestion;
    statusPage?: GetBenefitPageResponse.StatusPage;
    bonusBoost?: boolean;
  }

  export interface OuterPayReductionContent {
    rate?: number;
    suggestion?: GetBenefitPageResponse.Suggestion;
    statusPage?: GetBenefitPageResponse.StatusPage;
    bonusBoost?: boolean;
  }
}

export interface CreateEntryTypeCampaignEntryRequest {
  promotionKey?: string;
}

export interface CreateEntryTypeCampaignEntryResponse {}

export interface GetRealcardInitialActivationBenefitMessageRequest {}

export interface GetRealcardInitialActivationBenefitMessageResponse {
  title?: string;
  imageUrl?: string;
  description?: GetRealcardInitialActivationBenefitMessageResponse.Description;
  url?: string;
}

export namespace GetRealcardInitialActivationBenefitMessageResponse {
  export interface Description {
    title?: string;
    body?: string;
  }
}

export interface GetRealcardAgeRequirementRequest {
  birthdate?: google_type_date.Date;
  featureFlags?: FeatureFlag[];
}

export interface GetRealcardAgeRequirementResponse {
  status?: GetRealcardAgeRequirementResponse.Status;
}

export namespace GetRealcardAgeRequirementResponse {
  export enum Status {
    UNKNOWN = "UNKNOWN",
    OK = "OK",
    NG_UNDER_20 = "NG_UNDER_20"
  }
}

export interface GetPreapprovedPaymentMethodRequest {}

export interface GetPreapprovedPaymentMethodResponse {
  selectedPaymentMethod?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentPreapprovedPaymentMethod;
  availablePaymentMethods?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentAvailablePaymentMethod[];
}

export interface UpdatePreapprovedPaymentMethodRequest {
  selectedPaymentMethod?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_netpayment.NetpaymentPreapprovedPaymentMethod;
}

export interface UpdatePreapprovedPaymentMethodResponse {}

export interface ListPreapprovedServicesRequest {}

export interface ListPreapprovedServicesResponse {
  services?: ListPreapprovedServicesResponse.PreapprovedService[];
}

export namespace ListPreapprovedServicesResponse {
  export interface PreapprovedService {
    shopName?: string;
    serviceName?: string;
    description?: string;
    serviceImageUrl?: string;
  }
}

export interface GetGoogleClientInfoRequest {}

export interface GetGoogleClientInfoResponse {
  clientId?: string;
  scope?: string;
}

export interface CreateGSPAssociationRequest {
  openId?: string;
  payload?: string;
  associationId?: string;
}

export interface CreateGSPAssociationResponse {
  paymentUrl?: string;
  responsePayload?: string;
}

export interface CreateParentalConsentRequest {
  consentType?: CreateParentalConsentRequest.ConsentType;
}

export namespace CreateParentalConsentRequest {
  export enum ConsentType {
    TYPE_UNKNOWN = "TYPE_UNKNOWN",
    TYPE_INDIVIDUAL = "TYPE_INDIVIDUAL",
    TYPE_COMPREHENSIVE = "TYPE_COMPREHENSIVE",
    TYPE_EASYPAY_LITE = "TYPE_EASYPAY_LITE"
  }
}

export interface CreateParentalConsentResponse {}

export interface InitializePostPayUserRequest {}

export interface InitializePostPayUserResponse {
  postPayEligibility?: PostPayEligibility;
}

export interface Address {
  zipCode1?: string;
  zipCode2?: string;
  prefecture?: string;
  prefectureCode?: string;
  city?: string;
  address1?: string;
  address2?: string;
}

export interface PaymentMethod {
  type?: PaymentMethod.Type;
  amount?: number;
}

export namespace PaymentMethod {
  export enum Type {
    UNKNOWN = "UNKNOWN",
    POINT = "POINT",
    BALANCE = "BALANCE",
    SALES = "SALES"
  }
}

export interface UserPaymentConfig {
  paymentMethodPriority?: UserPaymentConfig.PaymentMethodPriority;
  postPayPaymentMode?: UserPaymentConfig.PostPayPaymentMode;
}

export namespace UserPaymentConfig {
  export enum PaymentMethodPriority {
    PAYMENT_METHOD_PRIORITY_UNKNOWN = "PAYMENT_METHOD_PRIORITY_UNKNOWN",
    POINT = "POINT",
    FUNDS = "FUNDS"
  }

  export enum PostPayPaymentMode {
    MODE_UNKNOWN = "MODE_UNKNOWN",
    MODE_BALANCE = "MODE_BALANCE",
    MODE_POST_PAY = "MODE_POST_PAY"
  }
}

export interface DeleteUserBankAccountRequest {
  accountId?: string;
}

export interface SetDefaultBankAccountRequest {
  accountId?: string;
}

export interface CreateFundsRechargeRequest {
  accountId?: string;
  amount?: number;
  triggerType?: CreateFundsRechargeRequest.TriggerType;
  idempotencyKey?: string;
}

export namespace CreateFundsRechargeRequest {
  export enum TriggerType {
    TRIGGER_TYPE_UNKNOWN = "TRIGGER_TYPE_UNKNOWN",
    TRIGGER_TYPE_USER = "TRIGGER_TYPE_USER",
    TRIGGER_TYPE_DEFPAY_USER_RECHARGE = "TRIGGER_TYPE_DEFPAY_USER_RECHARGE",
    TRIGGER_TYPE_NETPAYMENT = "TRIGGER_TYPE_NETPAYMENT",
    TRIGGER_TYPE_NETPAYMENT_FUNDS = "TRIGGER_TYPE_NETPAYMENT_FUNDS",
    TRIGGER_TYPE_MERCOIN = "TRIGGER_TYPE_MERCOIN"
  }
}

export interface CreateBalanceWithdrawalRequest {
  accountId?: string;
  amount?: number;
}

export interface GetFundsRechargeStatusRequest {
  id?: string;
}

export interface GetFundsRechargeStatusResponse {
  status?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.FundsRechargeStatus;
  failure?: GetFundsRechargeStatusResponse.Failure;
}

export namespace GetFundsRechargeStatusResponse {
  export interface Failure {
    code?: number;
    message?: string;
  }
}

export interface GetFundsRechargeOptionsRequest {
  mode?: GetFundsRechargeOptionsRequest.Mode;
}

export namespace GetFundsRechargeOptionsRequest {
  export enum Mode {
    MODE_DEFAULT = "MODE_DEFAULT",
    MODE_DASHBOARD = "MODE_DASHBOARD",
    MODE_PURCHASE = "MODE_PURCHASE",
    MODE_MERCOIN = "MODE_MERCOIN"
  }
}

export interface GetFundsRechargeOptionsResponse {
  amounts?: number[];
  defaultAmount?: number;
  minAmount?: number;
  maxAmount?: number;
}

export interface GetBalanceWithdrawalRequest {
  id?: string;
}

export interface GetPersonalInformationForBankRegistrationRequest {}

export interface PersonalInformationForBankRegistration {
  birthday?: google_type_date.Date;
  address?: Address;
  job?: Job;
  gender?: Gender;
  familyName?: string;
  firstName?: string;
  familyNameKana?: string;
  firstNameKana?: string;
  purpose?: string;
  fatcaApplicable?: boolean;
  purposes?: PurposeOfUse[];
}

export interface GetAddressFromZipCodeRequest {
  zipCode1?: string;
  zipCode2?: string;
}

export interface ListUserRechargeProvidersRequest {}

export interface ListUserRechargeProvidersResponse {
  bankAccounts?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount[];
  topupProviders?: TopupProvider[];
  selectedBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  selectedTopupProvider?: TopupProvider;
}

export interface GetNFCMaintenanceRegistrationRequest {
  errorType?: GetNFCMaintenanceRegistrationRequest.ErrorType;
}

export namespace GetNFCMaintenanceRegistrationRequest {
  export enum ErrorType {
    ERROR_TYPE_DEFAULT = "ERROR_TYPE_DEFAULT",
    ERROR_TYPE_ONLINE_PAYMENT = "ERROR_TYPE_ONLINE_PAYMENT"
  }
}

export interface CreateVirtualCardContractRequest {}

export interface CreateVirtualCardContractResponse {
  lastFourOfFpan?: string;
}

export interface CreateApplePayDeviceRequest {
  nonce?: string;
  nonceSignature?: string;
  certificateChain?: string[];
  ivCert?: string;
}

export interface CreateApplePayDeviceResponse {
  oneTimePassword?: string;
  encryptionData?: string;
  encryptionKey?: string;
}

export interface SignAndroidNFCRequest {
  idm?: string;
  ivCert?: string;
}

export interface SignAndroidNFCResponse {
  jws?: string;
}

export interface InitNFCPINRequest {}

export interface InitNFCPINResponse {
  last4DigitsOfPhoneNumber?: string;
  birthday?: google_type_date.Date;
  publicKey?: string;
}

export interface UpdateNFCPINRequest {
  encryptedPin?: string;
}

export interface UpdateNFCPINResponse {}

export interface GetSMSStatusNFCRequest {
  ivCert?: string;
}

export interface GetSMSStatusNFCResponse {
  skippable?: boolean;
  phoneNumber?: string;
  userSmsConfirmation?: GetSMSStatusNFCResponse.UserSMSConfirmation;
}

export namespace GetSMSStatusNFCResponse {
  export interface UserSMSConfirmation {
    id?: number;
    isDone?: boolean;
    created?: string;
    updated?: string;
  }
}

export interface GetNFCRegistrationStatusRequest {}

export interface GetNFCRegistrationStatusResponse {
  nfcRegistrationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_nfc.NFCRegistrationStatus;
}

export interface GetOnlinePaymentSetupInfoRequest {}

export interface GetOnlinePaymentSetupInfoResponse {
  isActiveOnlinePayment?: boolean;
  hasFundsAccount?: boolean;
  userPaymentConfig?: UserPaymentConfig;
  usePasscode?: CodePaymentUsePasscode;
  isSmsConfirmationDone?: boolean;
  forcePostpay?: boolean;
}

export interface ActivateOnlinePaymentRequest {}

export interface ActivateOnlinePaymentResponse {}

export interface SwitchOnlinePaymentStatusRequest {
  isAvailableOnlinePayment?: boolean;
}

export interface SwitchOnlinePaymentStatusResponse {}

export interface RegisterCardKeyRequest {
  publicKey?: string;
}

export interface RegisterCardKeyResponse {}

export interface GetOnlinePaymentDetailRequest {
  publicKey?: string;
}

export interface GetOnlinePaymentDetailResponse {
  userPaymentConfig?: UserPaymentConfig;
  balance?: UserBalance;
  remainingCredit?: string;
  isAvailableOnlinePayment?: boolean;
  isCacheAvailable?: boolean;
}

export interface GetEncryptedCardURLRequest {
  publicKey?: string;
}

export interface GetEncryptedCardURLResponse {
  encryptedUrl?: string;
  encryptionKey?: string;
}

export interface GetDeferredPaymentUserRequest {}

export interface GetDeferredPaymentUserResponse {
  credit?: string;
  remainingCredit?: string;
  user?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentUser;
}

export interface GetDeferredPaymentBillsRequest {
  useMonths?: string[];
  isPaid?: boolean;
}

export interface GetDeferredPaymentBillsResponse {
  bills?: DeferredPaymentBill[];
}

export interface GetDeferredPaymentRepaymentRequest {
  repaymentId?: string;
}

export interface GetDeferredPaymentRepaymentResponse {
  repayment?: DeferredPaymentRepayment;
}

export interface PrepareDeferredPaymentRepaymentRequest {
  bills?: string[];
}

export interface PrepareDeferredPaymentRepaymentResponse {
  bills?: DeferredPaymentBill[];
  amount?: DeferredPaymentRepaymentAmount;
}

export interface GetDeferredPaymentRepaymentReminderRequest {}

export interface GetDeferredPaymentRepaymentReminderResponse {
  reminderType?: GetDeferredPaymentRepaymentReminderResponse.ReminderType;
  text?: string;
  targetUrl?: string;
}

export namespace GetDeferredPaymentRepaymentReminderResponse {
  export enum ReminderType {
    UNKNOWN = "UNKNOWN",
    NONE = "NONE",
    INFO = "INFO",
    WARNING = "WARNING",
    DANGER = "DANGER"
  }
}

export interface CreateDeferredPaymentRepaymentRequest {
  bills?: string[];
  amount?: DeferredPaymentRepaymentAmount;
  payType?: DeferredPaymentRepayment.PayType;
  storePaymentType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment.StorePaymentDetail.StorePaymentType;
}

export interface CreateDeferredPaymentRepaymentResponse {
  repaymentId?: string;
}

export interface UpdateDeferredPaymentRepaymentRequest {
  repaymentId?: string;
  amount?: DeferredPaymentRepaymentAmount;
  payType?: DeferredPaymentRepayment.PayType;
  storePaymentType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment.StorePaymentDetail.StorePaymentType;
}

export interface UpdateDeferredPaymentRepaymentResponse {}

export interface DeferredPaymentRepayment {
  id?: string;
  amount?: DeferredPaymentRepaymentAmount;
  payType?: DeferredPaymentRepayment.PayType;
  bills?: DeferredPaymentBill[];
  storePaymentDetail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment.StorePaymentDetail;
}

export namespace DeferredPaymentRepayment {
  export enum PayType {
    PAY_TYPE_CVS = "PAY_TYPE_CVS",
    PAY_TYPE_BANK = "PAY_TYPE_BANK"
  }
}

export interface DeferredPaymentRepaymentAmount {
  total?: string;
  payment?: string;
  funds?: string;
  sales?: string;
  points?: string;
  appropriationFunds?: string;
  appropriationSales?: string;
  appropriationPoints?: string;
  commission?: string;
  lateFee?: string;
}

export interface DeferredPaymentBill {
  id?: string;
  repaymentId?: string;
  useMonth?: string;
  amount?: string;
  status?: DeferredPaymentBill.Status;
  payments?: DeferredPaymentPayment[];
}

export namespace DeferredPaymentBill {
  export enum Status {
    NONE = "NONE",
    PAID = "PAID",
    OVER_DUE = "OVER_DUE",
    BANK_STANDING_ORDER_SELECTED = "BANK_STANDING_ORDER_SELECTED",
    BANK_STANDING_ORDER_CONNECT_ERROR = "BANK_STANDING_ORDER_CONNECT_ERROR",
    BANK_STANDING_ORDER_WITHDRAW_ERROR = "BANK_STANDING_ORDER_WITHDRAW_ERROR",
    CVS_CODE_ISSUED = "CVS_CODE_ISSUED",
    CVS_CODE_EXPIRED = "CVS_CODE_EXPIRED"
  }
}

export interface DeferredPaymentPayment {
  paymentId?: string;
  amount?: string;
  description?: string;
  purchaseAt?: string;
}

export interface UpdateDeferredPaymentScheduledRepaymentSettingRequest {
  setting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
}

export interface UpdateDeferredPaymentScheduledRepaymentSettingResponse {}

export interface GetPostPayConfigRequest {}

export interface GetPostPayConfigResponse {
  postPayConfig?: PostPayConfig;
}

export interface UpdatePostPayConfigRequest {
  amountLimit?: number;
}

export interface UpdatePostPayConfigAmountLimitRequest {
  amountLimit?: number;
  amountLimitNotSet?: boolean;
}

export interface UpdatePostPayConfigResponse {
  postPayConfig?: PostPayConfig;
}

export interface KYCPostPaySetupProgress {
  status?: KYCPostPaySetupProgress.Status;
}

export namespace KYCPostPaySetupProgress {
  export enum Status {
    UNKNOWN = "UNKNOWN",
    NONE = "NONE",
    FUNDS_CREATED = "FUNDS_CREATED",
    KYC_FAILED = "KYC_FAILED",
    KYC_FAILED_BY_AS = "KYC_FAILED_BY_AS",
    KYC_NOT_REQUESTED = "KYC_NOT_REQUESTED",
    KYC_IN_PROGRESS = "KYC_IN_PROGRESS"
  }
}

export interface GetKYCPostPaySetupProgressRequest {}

export interface GetKYCPostPaySetupProgressResponse {
  kycPostPaySetupProgress?: KYCPostPaySetupProgress;
}

export interface AgreeToUseOfPostPayRequest {}

export interface AgreeToUseOfPostPayResponse {}

export interface PostPayEligibility {
  eligibility?: PostPayEligibility.Eligibility;
}

export namespace PostPayEligibility {
  export enum Eligibility {
    UNKNOWN = "UNKNOWN",
    PP_ELIGIBLE = "PP_ELIGIBLE",
    PP_INELIGIBLE = "PP_INELIGIBLE",
    PP_INELIGIBLE_BY_AGE = "PP_INELIGIBLE_BY_AGE",
    NEED_PARENTAL_CONSENT = "NEED_PARENTAL_CONSENT"
  }
}

export interface GetPostPayEligibilityRequest {}

export interface GetPostPayEligibilityResponse {
  postPayEligibility?: PostPayEligibility;
}

export interface GetEasypayApplicationInformationRequest {}

export interface GetEasypayApplicationInformationResponse {
  personalInformation?: GetEasypayApplicationInformationResponse.PersonalInformation;
  usePurpose?: string;
  repaymentType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentRepaymentType;
  scheduledRepayment?: GetEasypayApplicationInformationResponse.ScheduledRepayment;
}

export namespace GetEasypayApplicationInformationResponse {
  export interface ScheduledRepayment {
    bankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
    scheduledRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
  }

  export interface PersonalInformationNotice {
    hasValidZipcode?: boolean;
    isPhoneNumberEmpty?: boolean;
  }

  export interface PersonalInformation {
    birthdate?: google_type_date.Date;
    address?: Address;
    phoneNumber?: string;
    isPhoneNumberEmpty?: boolean;
    job?: Job;
    gender?: Gender;
    familyName?: string;
    firstName?: string;
    familyNameKana?: string;
    firstNameKana?: string;
    notice?: GetEasypayApplicationInformationResponse.PersonalInformationNotice;
  }
}

export interface SaveEasypayApplicantInformationRequest {
  type?: SaveEasypayApplicantInformationRequest.WorkplaceTernary;
  workplace?: string;
}

export namespace SaveEasypayApplicantInformationRequest {
  export enum WorkplaceTernary {
    UNSPECIFIED = "UNSPECIFIED",
    EXIST = "EXIST",
    NONE = "NONE"
  }
}

export interface SaveEasypayApplicantInformationResponse {}

export interface GetEasypayRepaymentSettingRequest {}

export interface GetEasypayRepaymentSettingResponse {
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  isAvailableBankAccount?: boolean;
  scheduledRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
  defaultPayType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentRepaymentType;
  cvsRepaymentFee?: string;
  cvsRepaymentFeeLabel?: string;
  scheduledDayOptions?: number[];
  cvsRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentCVSRepaymentSetting;
  cvsRepaymentScheduledDayOptions?: number[];
  scheduledRepaymentNotes?: string[];
}

export interface UpdateEasypayRepaymentSettingRequest {
  defaultPayType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentRepaymentType;
  scheduledRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
  cvsRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentCVSRepaymentSetting;
}

export interface UpdateEasypayRepaymentSettingResponse {
  defaultPayType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentRepaymentType;
  scheduledRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
  cvsRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentCVSRepaymentSetting;
}

export interface SaveTemporalEasypayRepaymentSettingRequest {
  setting?: TemporalEasypayRepaymentSetting;
}

export interface SaveTemporalEasypayRepaymentSettingResponse {
  setting?: TemporalEasypayRepaymentSetting;
}

export interface TemporalEasypayRepaymentSetting {
  type?: TemporalEasypayRepaymentSetting.Type;
  scheduledSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
}

export namespace TemporalEasypayRepaymentSetting {
  export enum Type {
    TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
    SCHEDULED = "SCHEDULED",
    UNSELECTED = "UNSELECTED"
  }
}

export interface GetTemporalEasypayRepaymentSettingRequest {}

export interface GetTemporalEasypayRepaymentSettingResponse {
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  isAvailableBankAccount?: boolean;
  setting?: TemporalEasypayRepaymentSetting;
  requireScheduledRepayment?: boolean;
  scheduledDayOptions?: number[];
  scheduledRepaymentNotes?: string[];
}

export interface ApplyEasypayRequest {
  applyRoute?: ApplyEasypayRequest.ApplyRoute;
  itemId?: string;
}

export namespace ApplyEasypayRequest {
  export enum ApplyRoute {
    APPLY_ROUTE_UNSPECIFIED = "APPLY_ROUTE_UNSPECIFIED",
    ITEM_DETAILS = "ITEM_DETAILS"
  }
}

export interface ApplyEasypayResponse {
  messages?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage[];
  inquiryKey?: string;
}

export interface GetEasypayApplicantConditionRequest {}

export interface GetEasypayApplicantConditionResponse {
  hasFundsAccount?: boolean;
  needsParentalConsent?: boolean;
  usingScheduledRepayment?: boolean;
}

export interface GetEasypayApplicabilityRequest {}

export interface GetEasypayApplicabilityResponse {
  applicability?: boolean;
  needsKyc?: boolean;
}

export interface CreateEasypayApplicationReservationRequest {
  reservationRoute?: CreateEasypayApplicationReservationRequest.ReservationRoute;
  birthdate?: google_type_date.Date;
  workplaceType?: SaveEasypayApplicantInformationRequest.WorkplaceTernary;
  workplace?: string;
}

export namespace CreateEasypayApplicationReservationRequest {
  export enum ReservationRoute {
    UNKNOWN = "UNKNOWN",
    EKYC = "EKYC",
    REALCARD = "REALCARD"
  }
}

export interface CreateEasypayApplicationReservationResponse {}

export interface GetEasypayAgeRequirementRequest {
  birthdate?: google_type_date.Date;
}

export interface GetEasypayAgeRequirementResponse {
  status?: GetEasypayAgeRequirementResponse.Status;
  showDialogFor18?: boolean;
}

export namespace GetEasypayAgeRequirementResponse {
  export enum Status {
    UNKNOWN = "UNKNOWN",
    OK = "OK",
    NG_UNDER_20 = "NG_UNDER_20",
    NG_UNDER_18 = "NG_UNDER_18",
    OK_WITH_PARENT_CONSENT = "OK_WITH_PARENT_CONSENT"
  }
}

export interface CancelEasypayContractRequest {}

export interface CancelEasypayContractResponse {}

export interface GetEasypayStartToUsePasscodeStatusRequest {}

export interface GetEasypayStartToUsePasscodeStatusResponse {
  status?: GetEasypayStartToUsePasscodeStatusResponse.Status;
}

export namespace GetEasypayStartToUsePasscodeStatusResponse {
  export enum Status {
    UNKNOWN = "UNKNOWN",
    NOT_REQUIRED = "NOT_REQUIRED",
    REQUIRED = "REQUIRED",
    VERIFIED = "VERIFIED"
  }
}

export interface VerifyEasypayStartToUsePasscodeStatusRequest {}

export interface VerifyEasypayStartToUsePasscodeStatusResponse {}

export interface UpdateEasypayMonthlyInstallmentsRequest {
  easypayMonthlyInstallments?: number;
  notUseWithConvertMontlyClearToEasypay?: boolean;
  paymentId?: string;
  paymentIds?: string[];
}

export interface UpdateEasypayMonthlyInstallmentsResponse {
  postPayConfig?: PostPayConfig;
}

export interface GetEasypayRepaymentTopRequest {
  useMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
}

export interface GetEasypayRepaymentTopResponse {
  messages?: GetEasypayRepaymentTopResponse.LocalizedMessageWithType[];
  storePaymentDetail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment.StorePaymentDetail;
  invoices?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.Invoice[];
  defaultPayType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentRepaymentType;
  setting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentScheduledRepaymentSetting;
  monthlyRepaymentPlan?: GetEasypayRepaymentTopResponse.MonthlyRepaymentPlan;
  deferredPaymentUser?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentUser;
  easypayMonthlyInstallments?: string;
  easypayNumberOfMonthToRepaymentEnd?: string;
  totalEasypayAmount?: string;
  isEasypayRepaymentPlanCalculated?: boolean;
  easypayBanner?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBanner;
  campaignInfo?: GetEasypayRepaymentTopResponse.ApplicableCampaignInfo;
  cvsRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentCVSRepaymentSetting;
  cvsRepaymentFeeLabel?: string;
  oldestUnpaidInvoiceUseMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
}

export namespace GetEasypayRepaymentTopResponse {
  export interface LocalizedMessageWithType {
    status?: GetEasypayRepaymentTopResponse.LocalizedMessageWithType.Status;
    message?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage;
  }

  export namespace LocalizedMessageWithType {
    export enum Status {
      UNKNOWN = "UNKNOWN",
      ERROR = "ERROR",
      WARN = "WARN",
      INFO = "INFO"
    }
  }

  export interface MonthlyRepaymentPlan {
    monthlyPredictionRepayments?: GetEasypayRepaymentTopResponse.MonthlyRepaymentPlan.MonthlyPredictionRepayment[];
    maximumRepaymentAmountRange?: string;
    totalRemainingRepaymentAmount?: string;
  }

  export namespace MonthlyRepaymentPlan {
    export interface MonthlyPredictionRepayment {
      repaymentMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
      totalRemainingRepaymentAmount?: string;
    }
  }

  export interface ApplicableCampaignInfo {
    campaignIds?: string[];
    easypayCampaignText?: string;
  }
}

export interface ConvertMonthlyClearToEasypayRequest {
  paymentId?: string;
  paymentIds?: string[];
  applyCampaignInfo?: ConvertMonthlyClearToEasypayRequest.ApplyCampaignInfoEntry[];
}

export namespace ConvertMonthlyClearToEasypayRequest {
  export interface ApplyCampaignInfoEntry {
    key?: string;
    value?: ApplyEasypayCampaignInfo;
  }
}

export interface ApplyEasypayCampaignInfo {
  campaignIds?: string[];
}

export interface ConvertMonthlyClearToEasypayResponse {
  easypayPayment?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayPayment;
  scheduledRepaymentMonth?: ConvertMonthlyClearToEasypayResponse.ScheduledRepaymentMonth;
}

export namespace ConvertMonthlyClearToEasypayResponse {
  export enum ScheduledRepaymentMonth {
    UNKNOWN = "UNKNOWN",
    THIS_MONTH = "THIS_MONTH",
    NEXT_MONTH = "NEXT_MONTH"
  }
}

export interface CanConvertMonthlyClearToEasypayRequest {}

export interface CanConvertMonthlyClearToEasypayResponse {
  canConvert?: boolean;
  easypayMonthlyInstallments?: string;
  convertibleCount?: string;
  easypayConvertedCount?: string;
  convertibleMaxCount?: string;
}

export interface GetEasypayAgreementRequest {}

export interface GetEasypayAgreementResponse {
  easypayAgreement?: EasypayAgreement;
}

export interface EasypayAgreement {
  signedAt?: string;
  initialCredit?: string;
}

export interface GetEasypayInvoiceRepaymentInformationForFundRequest {
  invoiceId?: string;
}

export interface GetEasypayInvoiceRepaymentInformationForFundResponse {
  repaymentFee?: string;
  balance?: UserBalance;
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
}

export interface GetEasypayInvoiceRepaymentInformationForCVSRequest {
  invoiceId?: string;
}

export interface GetEasypayInvoiceRepaymentInformationForCVSResponse {
  repaymentFee?: string;
  balance?: UserBalance;
  cvsList?: CVS[];
  amount?: string;
  authorizedRepaymentExists?: boolean;
  authorizedFreePoints?: string;
}

export interface GetInvoiceRepaymentInformationForOnDemandRequest {}

export interface GetInvoiceRepaymentInformationForOnDemandResponse {
  balance?: UserBalance;
  notificationMessages?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage[];
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  minRechargeAmount?: number;
  maxRechargeAmount?: number;
}

export interface CreateEasypayInvoiceRepaymentForFundRequest {
  invoiceId?: string;
  amount?: EasypayRepaymentAmount;
}

export interface CreateEasypayInvoiceRepaymentForFundResponse {}

export interface CreateEasypayInvoiceRepaymentForCVSRequest {
  invoiceId?: string;
  amount?: EasypayRepaymentAmount;
  storePaymentType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment.StorePaymentDetail.StorePaymentType;
}

export interface CreateEasypayInvoiceRepaymentForCVSResponse {}

export interface CreateInvoiceRepaymentForOnDemandRequest {
  paymentIds?: string[];
  amount?: EasypayRepaymentAmount;
}

export interface CreateInvoiceRepaymentForOnDemandResponse {}

export interface EasypayRepaymentAmount {
  amount?: string;
  easypayFee?: string;
  repaymentFee?: string;
  demandFee?: string;
  lateFee?: string;
  freePoint?: string;
}

export interface GetEasypayPaymentRequest {
  easypayDebtId?: string;
  paymentId?: string;
}

export interface GetEasypayPaymentResponse {
  easypayPayment?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayPayment;
  inquiry?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage;
}

export interface GetEasypayBillRequest {
  billingMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
}

export interface GetEasypayBillResponse {
  easypayBill?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBill;
  hasEasypayFee?: boolean;
  message?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage;
  lateFee?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.LateFee;
  totalAmount?: string;
}

export interface GetEasypayHistoryTopRequest {
  useMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  isUseMonthSpecified?: boolean;
}

export interface GetEasypayHistoryTopResponse {
  invoiceUseMonths?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth[];
  invoice?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.Invoice;
  monthlyClearPayments?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.MonthlyClearPayment[];
  easypayMonthlyInstallments?: string;
  easypayMonthlyInstallmentsOptions?: string[];
  deferredPaymentUser?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.DeferredPaymentUser;
  easypayBanner?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBanner;
  monthlyClearLateFee?: string;
  easypayLateFee?: string;
  easypayAmount?: string;
}

export interface GetEasypayConvertiblePaymentListRequest {}

export interface GetEasypayConvertiblePaymentListResponse {
  monthlyConvertiblePayments?: GetEasypayConvertiblePaymentListResponse.MonthlyConvertiblePayments[];
  easypayBanner?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBanner;
  remainingEasypayPrincipalAmount?: string;
  easypayCampaignSummary?: string;
}

export namespace GetEasypayConvertiblePaymentListResponse {
  export interface MonthlyConvertiblePayments {
    useMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
    monthlyClearPayments?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.MonthlyClearPayment[];
    paymentCampaignInfo?: GetEasypayConvertiblePaymentListResponse.MonthlyConvertiblePayments.PaymentCampaignInfoEntry[];
  }

  export namespace MonthlyConvertiblePayments {
    export interface PaymentCampaignInfoEntry {
      key?: string;
      value?: GetEasypayConvertiblePaymentListResponse.ApplicableCampaignInfo;
    }
  }

  export interface ApplicableCampaignInfo {
    campaignIds?: string[];
    campaignText?: string;
  }
}

export interface GetEasypayTopRequest {}

export interface GetEasypayTopResponse {
  easypayBill?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBill;
  numberOfMonthToRepaymentEnd?: string;
  repaymentEndMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  totalRemainingRepaymentAmount?: string;
  totalRemainingFeeAmount?: string;
  easypayDebts?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayDebt[];
  isEasypayRepaymentPlanCalculated?: boolean;
  easypayBanner?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBanner;
}

export interface UpdateZipCodeRequest {
  zipCode1?: string;
  zipCode2?: string;
}

export interface UpdateZipCodeResponse {}

export interface CalcEasypayRepaymentPlanRequest {
  easypayMonthlyInstallments?: string;
  isEasypayMonthlyInstallmentsSelected?: boolean;
  paymentId?: string;
  prePurchasePrice?: string;
  paymentIds?: string[];
  applyCampaignInfo?: CalcEasypayRepaymentPlanRequest.ApplyCampaignInfoEntry[];
  useMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  autoRaiseMonthlyInstallments?: boolean;
}

export namespace CalcEasypayRepaymentPlanRequest {
  export interface ApplyCampaignInfoEntry {
    key?: string;
    value?: ApplyEasypayCampaignInfo;
  }
}

export interface CalcEasypayRepaymentPlanResponse {
  monthlyPredictionRepayment?: CalcEasypayRepaymentPlanResponse.MonthlyPredictionRepayment[];
  totalEasypayPrincipalAmount?: string;
  totalEasypayFeeAmount?: string;
  easypayMonthlyInstallments?: string;
  easypayMonthlyInstallmentsOptions?: number[];
  endMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  assetsSimulationUrl?: string;
  numberOfMonthToRepaymentEnd?: string;
  easypayBanner?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.EasypayBanner;
  easypayConvertedCount?: string;
  totalEasypayConvertedAmount?: string;
  easypayConvertingCount?: string;
  totalEasypayConvertingAmount?: string;
  campaignInfo?: CalcEasypayRepaymentPlanResponse.ApplicableCampaignInfo;
  beforeAndAfter?: CalcEasypayRepaymentPlanResponse.BeforeAndAfter;
  easypayFeeReward?: CalcEasypayRepaymentPlanResponse.EasypayFeeReward;
}

export namespace CalcEasypayRepaymentPlanResponse {
  export interface MonthlyPredictionRepayment {
    repaymentMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
    totalEasypayPrincipalAmount?: string;
    totalEasypayFeeAmount?: string;
  }

  export interface BeforeAndAfter {
    invoiceMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
    beforeAmount?: string;
    beforeEasypayBillAmount?: string;
    afterAmount?: string;
    afterEasypayBillAmount?: string;
  }

  export interface ApplicableCampaignInfo {
    campaignIds?: string[];
    totalEasypayFeeAmountDescription?: string;
    extraText?: string;
    extraLinkText?: string;
    extraLinkUrl?: string;
    prePurchaseFeeAlternativeText?: string;
    prePurchaseFeeAnnotation?: string;
  }

  export interface EasypayFeeReward {
    enabled?: boolean;
    rewardAvailable?: boolean;
    rewardLimit?: string;
    totalRewardsIncludingEstimates?: string;
  }
}

export interface GetLendingUserRequest {}

export interface GetLendingUserResponse {
  lendingUser?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingUser;
}

export interface GetLendingApplicationConditionRequest {}

export interface GetLendingApplicationConditionResponse {
  applicationCondition?: LendingApplicationCondition;
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  scheduledRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingScheduledRepaymentSetting;
  scheduledDayOptions?: number[];
}

export interface GetLendingProvisionalLoanableAmountRequest {
  loanableAmount?: string;
  declaredExternalLoanAmount?: string;
  annualIncome?: string;
}

export interface GetLendingProvisionalLoanableAmountResponse {
  provisionalLoanableAmount?: string;
  carryoverPrincipalAmount?: string;
  minNewLoanAmount?: string;
}

export interface ListLendingMonthlyRepayAmountChoicesRequest {
  loanAmount?: LendingMonthlyRepayAmountCriteriaLoanAmount;
  userDebtAmount?: LendingMonthlyRepayAmountCriteriaUserDebtAmount;
}

export interface ListLendingMonthlyRepayAmountChoicesResponse {
  choices?: string[];
}

export interface LendingRepaymentSimulationConditionUniversal {
  interestRate?: string;
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
}

export interface LendingRepaymentSimulationConditionNewLoan {
  interestRate?: string;
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
}

export interface LendingRepaymentSimulationConditionChangeMonthlyRepay {
  monthlyRepayAmount?: string;
}

export interface GetLendingRepaymentSimulationRequest {
  universal?: LendingRepaymentSimulationConditionUniversal;
  newLoan?: LendingRepaymentSimulationConditionNewLoan;
  changeMonthlyRepay?: LendingRepaymentSimulationConditionChangeMonthlyRepay;
}

export interface GetLendingRepaymentSimulationResponse {
  repaymentCompleteMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  totalRepaymentAmount?: string;
  totalInterestAmount?: string;
  interestRate?: string;
}

export interface GetLendingRepaymentHistorySimulationRequest {
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
}

export interface GetLendingRepaymentHistorySimulationResponse {
  hasContract?: boolean;
  repaymentCompleteMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  totalRepaymentAmount?: string;
  carryoverPrincipalAmount?: string;
  totalInterestAmount?: string;
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
}

export interface GetLendingPrecontractDocumentRequest {
  interestRate?: string;
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
  kycInquiredAt?: string;
}

export interface GetLendingPrecontractDocumentResponse {
  document?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingContractDocument;
  issuedAt?: string;
}

export interface CreateLendingApplicationRequest {
  loanableAmountLimit?: string;
  loanableAmount?: string;
  interestRate?: string;
  transactionPurpose?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingTransactionPurpose;
  declaredExternalLoanAmount?: string;
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
  inquiryInformation?: LendingInquiryInformation;
  repaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingScheduledRepaymentSetting;
  documentFormatVersion?: string;
  documentIssuedAt?: string;
  statedLoanAmount?: string;
  statedCarryoverPrincipalAmount?: string;
}

export interface CreateLendingApplicationResponse {
  application?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingApplication;
  screeningPeriodText?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.LocalizedMessage;
}

export interface UpdateLendingApplicationRequest {
  applicationId?: string;
  interestRate?: string;
  newLoanAmount?: string;
  monthlyRepayAmount?: string;
  kycInquiredAt?: string;
  documentFormatVersion?: string;
  documentIssuedAt?: string;
  statedLoanAmount?: string;
  statedCarryoverPrincipalAmount?: string;
}

export interface UpdateLendingApplicationResponse {
  application?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingApplication;
}

export interface CancelLendingApplicationRequest {
  applicationId?: string;
}

export interface CancelLendingApplicationResponse {}

export interface GetLendingContractDocumentRequest {
  contractRecordId?: string;
  asArticle16?: boolean;
}

export interface GetLendingContractDocumentResponse {
  document?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingContractDocument;
}

export interface LendingApplicationCondition {
  loanableAmountLimit?: string;
  loanableAmount?: string;
  interestRate?: string;
  inquiryInformation?: LendingInquiryInformation;
  hasContract?: boolean;
  hasRemainingPrincipal?: boolean;
}

export interface LendingInquiryInformation {
  familyNameKana?: string;
  firstNameKana?: string;
  birthdate?: google_type_date.Date;
  phoneNumber?: string;
  landlineNumber?: string;
  zipCode?: string;
  familyName?: string;
  firstName?: string;
  address?: string;
  job?: Job;
  workplace?: string;
  annualIncome?: string;
  driversLicenseNumber?: string;
  kycInquiredAt?: string;
}

export interface LendingMonthlyRepayAmountCriteriaLoanAmount {
  interestRate?: string;
  loanAmount?: string;
}

export interface LendingMonthlyRepayAmountCriteriaUserDebtAmount {}

export interface GetLendingApplicationRequest {
  applicationId?: string;
}

export interface GetLendingApplicationResponse {
  application?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingApplication;
  inquiryInformation?: LendingInquiryInformation;
  user?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingUser;
  hasContract?: boolean;
}

export interface GetLendingTopRequest {}

export interface GetLendingTopResponse {
  user?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingUser;
  currentContractInformation?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingCurrentContractInformation;
  newestUnpaidInvoice?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingInvoice;
  repaymentFailureInformation?: LendingRepaymentFailureInformation;
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  scheduledRepaymentSetting?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingScheduledRepaymentSetting;
  hasRemainingDebt?: boolean;
  scheduledDayOptions?: number[];
  oldestOverdueRepaymentDueDate?: google_type_date.Date;
  totalOverdueRepayAmount?: string;
  nextScheduledRepaymentDate?: google_type_date.Date;
  totalNextRepayAmount?: string;
  repaymentAreaDetail?: GetLendingTopResponse.RepaymentAreaDetail;
  lendingNote?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingNote;
}

export namespace GetLendingTopResponse {
  export interface RepaymentAreaDetail {
    overdueAreaDetail?: GetLendingTopResponse.RepaymentAreaDetail.OverdueAreaDetail;
    currentRepaymentAreaDetail?: GetLendingTopResponse.RepaymentAreaDetail.CurrentRepaymentAreaDetail;
    nextRepaymentAreaDetail?: GetLendingTopResponse.RepaymentAreaDetail.NextRepaymentAreaDetail;
  }

  export namespace RepaymentAreaDetail {
    export interface OverdueAreaDetail {
      hasOverdueInvoices?: GetLendingTopResponse.RepaymentAreaDetail.OverdueAreaDetail.HasOverdueInvoices;
    }

    export namespace OverdueAreaDetail {
      export interface HasOverdueInvoices {
        totalOverdueAmount?: string;
      }
    }

    export interface CurrentRepaymentAreaDetail {
      hasOverdueInvoices?: GetLendingTopResponse.RepaymentAreaDetail.CurrentRepaymentAreaDetail.HasOverdueInvoices;
      beforeLastScheduledRepaymentOfMonth?: GetLendingTopResponse.RepaymentAreaDetail.CurrentRepaymentAreaDetail.BeforeLastScheduledRepaymentOfMonth;
      afterLastScheduledRepaymentOfMonth?: GetLendingTopResponse.RepaymentAreaDetail.CurrentRepaymentAreaDetail.AfterLastScheduledRepaymentOfMonth;
    }

    export namespace CurrentRepaymentAreaDetail {
      export interface HasOverdueInvoices {
        nextScheduledRepaymentDate?: google_type_date.Date;
        repayAmount?: string;
      }

      export interface BeforeLastScheduledRepaymentOfMonth {
        nextScheduledRepaymentDate?: google_type_date.Date;
        repayAmount?: string;
        invoice?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingInvoice;
      }

      export interface AfterLastScheduledRepaymentOfMonth {
        repaymentDueDate?: google_type_date.Date;
        repayAmount?: string;
        invoice?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingInvoice;
      }
    }

    export interface NextRepaymentAreaDetail {
      invoiceOfCurrentMonthRepaid?: GetLendingTopResponse.RepaymentAreaDetail.NextRepaymentAreaDetail.InvoiceOfCurrentMonthRepaid;
      noRemainingDebt?: GetLendingTopResponse.RepaymentAreaDetail.NextRepaymentAreaDetail.NoRemainingDebt;
    }

    export namespace NextRepaymentAreaDetail {
      export interface InvoiceOfCurrentMonthRepaid {
        nextScheduledRepaymentDate?: google_type_date.Date;
        totalNextRepayAmount?: string;
      }

      export interface NoRemainingDebt {}
    }
  }
}

export interface ListLendingUnpaidInvoicesRequest {}

export interface ListLendingUnpaidInvoicesResponse {
  invoices?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingInvoice[];
  repaymentFailureInformation?: LendingRepaymentFailureInformation;
}

export interface LendingRepaymentFailureInformation {
  repaymentDate?: google_type_date.Date;
  totalRepayAmount?: string;
  type?: LendingRepaymentFailureInformation.Type;
}

export namespace LendingRepaymentFailureInformation {
  export enum Type {
    UNSPECIFIED = "UNSPECIFIED",
    REPAYMENT_FAILED = "REPAYMENT_FAILED",
    OVERDUE = "OVERDUE",
    REPAYMENT_FAILED_AND_OVERDUE = "REPAYMENT_FAILED_AND_OVERDUE"
  }
}

export interface UpdateLendingMonthlyRepayAmountRequest {
  monthlyRepayAmount?: string;
}

export interface UpdateLendingMonthlyRepayAmountResponse {
  contractRecord?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingContractRecord;
}

export interface UpdateLendingRepaymentScheduledDayRequest {
  scheduledDay?: number;
}

export interface UpdateLendingRepaymentScheduledDayResponse {}

export interface UpdateLendingScheduledRepaymentTypeRequest {
  type?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingScheduledRepaymentType;
}

export interface UpdateLendingScheduledRepaymentTypeResponse {}

export interface ListLendingDocumentsRequest {
  options?: ListOption;
}

export interface ListLendingDocumentsResponse {
  documents?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingDocument[];
  currentContract?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingDocument;
  options?: Pager;
}

export interface GetLendingRepaymentDocumentRequest {
  invoiceRepaidResultId?: string;
}

export interface GetLendingRepaymentDocumentResponse {
  repaymentDocument?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingRepaymentDocument;
}

export interface GetLendingInvoiceRepaymentConfirmationForBalanceRequest {
  invoiceId?: string;
}

export interface GetLendingInvoiceRepaymentConfirmationForBalanceResponse {
  totalAmount?: string;
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  balance?: UserBalance;
}

export interface CreateLendingInvoiceRepaymentForBalanceRequest {
  invoiceId?: string;
  amount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingRepaymentAmount;
}

export interface CreateLendingInvoiceRepaymentForBalanceResponse {}

export interface GetLendingInvoiceRepaymentConfirmationForApplicationRequest {}

export interface GetLendingInvoiceRepaymentConfirmationForApplicationResponse {
  invoicesForApplication?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingInvoice[];
  totalAmount?: string;
  defaultBankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccount;
  balance?: UserBalance;
}

export interface CreateLendingInvoiceRepaymentForApplicationRequest {
  monthlyInvoiceId?: string;
  interestEarlyRepaymentInvoiceId?: string;
  amount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingRepaymentAmount;
}

export interface CreateLendingInvoiceRepaymentForApplicationResponse {}

export interface GetLendingServiceDescriptionRequest {}

export interface GetLendingServiceDescriptionResponse {
  descriptions?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_lending.LendingContractDocumentDescription[];
}

export interface GetMonthlyClearPaymentRequest {
  paymentId?: string;
}

export interface GetMonthlyClearPaymentResponse {
  monthlyClearPayment?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.MonthlyClearPayment;
  campaignInfo?: GetMonthlyClearPaymentResponse.ApplicableCampaignInfo;
}

export namespace GetMonthlyClearPaymentResponse {
  export interface ApplicableCampaignInfo {
    campaignIds?: string[];
    easypayCheckoutButtonLabel?: string;
    extraText?: string;
    extraLinkText?: string;
    extraLinkUrl?: string;
  }
}

export interface ListRepayableMonthlyClearPaymentsRequest {
  useMonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
}

export interface ListRepayableMonthlyClearPaymentsResponse {
  monthlyClearPayments?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_defpay.MonthlyClearPayment[];
}

export interface ListCVSRequest {}

export interface ListCVSResponse {
  cvsList?: CVS[];
}

export interface CVS {
  type?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_store_payment.StorePaymentDetail.StorePaymentType;
  name?: string;
  logoUrl?: string;
}

export interface AgreeToTermsAndConditionsBySceneRequest {
  scene?: AgreeToTermsAndConditionsBySceneRequest.AgreeToTermsAndConditionsScene;
}

export namespace AgreeToTermsAndConditionsBySceneRequest {
  export enum AgreeToTermsAndConditionsScene {
    UNKNOWN = "UNKNOWN",
    SIGNUP_TO_MERCARI = "SIGNUP_TO_MERCARI",
    NAZCA_PROVISIONING = "NAZCA_PROVISIONING",
    CONNECT_BANKS = "CONNECT_BANKS",
    CODE_PAYMENT = "CODE_PAYMENT",
    NET_PAYMENT = "NET_PAYMENT",
    POST_PAY = "POST_PAY",
    EASYPAY_LITE = "EASYPAY_LITE",
    VIRTUAL_CARD = "VIRTUAL_CARD",
    LENDING = "LENDING",
    REALCARD = "REALCARD",
    REALCARD_PRIVACY = "REALCARD_PRIVACY"
  }
}

export interface AgreeToTermsAndConditionsBySceneResponse {}

export interface AgreeToTermsAndConditionsByTypesRequest {
  typeStrings?: string[];
}

export interface AgreeToTermsAndConditionsByTypesResponse {}

export interface ListTermsAndConditionsToAgreeRequest {}

export interface ListTermsAndConditionsToAgreeResponse {
  termsAndConditions?: TermsAndConditions[];
}

export interface ListTermsAndConditionsToMustAgreeForFeatureRequest {
  scene?: AgreeToTermsAndConditionsBySceneRequest.AgreeToTermsAndConditionsScene;
}

export interface ListTermsAndConditionsToMustAgreeForFeatureResponse {
  termsAndConditions?: TermsAndConditions[];
}

export interface TermsAndConditions {
  typeString?: string;
  url?: string;
  title?: string;
}

export interface LatestUserAgreedTermsAndConditions {
  termsAndConditions?: TermsAndConditions;
  version?: string;
  createdAt?: string;
}

export interface GetCodePaymentStatusRequest {}

export interface GetCodePaymentStatusResponse {
  usePasscode?: CodePaymentUsePasscode;
  listTermsAndConditionsToAgree?: TermsAndConditions[];
  hasFundsAccount?: boolean;
  passcodeVerificationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_passcode.PasscodeVerificationStatus;
}

export interface GetCodePaymentAvailabilityRequest {}

export interface GetCodePaymentAvailabilityResponse {
  listTermsAndConditionsToAgree?: TermsAndConditions[];
  latestSucceededCodePaymentCreateTime?: string;
}

export interface SetCodePaymentUsePasscodeRequest {
  usePasscode?: CodePaymentUsePasscode;
}

export interface SetCodePaymentUsePasscodeResponse {}

export interface GetUserEventRequest {
  eventTypes?: UserEventType[];
}

export interface GetUserEventResponse {
  createTime?: string;
  succeededCodePayment?: SucceededCodePayment;
  requiredCharge?: RequiredCharge;
  failedCodePayment?: FailedCodePayment;
}

export interface SucceededCodePayment {
  chargeId?: string;
  shopName?: string;
  amount?: string;
  fundsAmount?: string;
  pointAmount?: string;
  captureTime?: string;
  brandName?: string;
  appeal?: SucceededCodePayment.Appeal;
}

export namespace SucceededCodePayment {
  export interface Appeal {
    boldText?: string;
    normalText?: string;
    iconUrl?: string;
    linkUrl?: string;
    logInfo?: SucceededCodePayment.Appeal.LogInfo;
    variantType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_design_system.DesignSystemStyleV3.InformationBubbleVariantType;
  }

  export namespace Appeal {
    export interface LogInfo {
      props?: SucceededCodePayment.Appeal.LogInfo.PropsEntry[];
    }

    export namespace LogInfo {
      export interface PropsEntry {
        key?: string;
        value?: string;
      }
    }
  }

  export interface AppealConfigList {
    list?: SucceededCodePayment.AppealConfigList.AppealConfig[];
  }

  export namespace AppealConfigList {
    export interface AppealConfig {
      priority?: number;
      filter?: SucceededCodePayment.AppealConfigList.AppealConfig.Filter;
      candidates?: SucceededCodePayment.AppealConfigList.AppealConfig.Candidate[];
    }

    export namespace AppealConfig {
      export interface Filter {
        timeframe?: SucceededCodePayment.AppealConfigList.AppealConfig.Period;
        onceEveryNDays?: number;
      }

      export interface Period {
        startTime?: string;
        endTime?: string;
      }

      export interface Candidate {
        id?: string;
        boldText?: string;
        normalText?: string;
        iconUrl?: string;
        linkUrl?: string;
        variantType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_design_system.DesignSystemStyleV3.InformationBubbleVariantType;
        condition?: SucceededCodePayment.AppealConfigList.AppealConfig.Candidate.Condition;
      }

      export namespace Candidate {
        export interface Condition {
          hasCard?: boolean;
          paymentMethod?: SucceededCodePayment.AppealConfigList.AppealConfig.Candidate.PaymentMethod;
          ekycStatus?: SucceededCodePayment.AppealConfigList.AppealConfig.Candidate.EKYCStatus;
        }

        export enum PaymentMethod {
          PAYMENT_METHOD_ALL = "PAYMENT_METHOD_ALL",
          PAYMENT_METHOD_BALANCE = "PAYMENT_METHOD_BALANCE",
          PAYMENT_METHOD_DEFERRED = "PAYMENT_METHOD_DEFERRED"
        }

        export enum EKYCStatus {
          EKYC_STATUS_ALL = "EKYC_STATUS_ALL",
          EKYC_STATUS_NOT_AUDITED = "EKYC_STATUS_NOT_AUDITED",
          EKYC_STATUS_ACCEPTED = "EKYC_STATUS_ACCEPTED"
        }
      }
    }
  }
}

export interface RequiredCharge {
  balanceDue?: number;
}

export interface FailedCodePayment {
  title?: string;
  body?: string;
}

export interface RaiseErrorRequest {
  errorNumber?: number;
}

export interface RaiseErrorResponse {}

export interface GetUserPayoutRequest {
  userPayoutId?: string;
}

export interface CreateUserPayoutRequest {
  userCustomerId?: string;
  balanceType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_payment.BalanceType;
  amount?: string;
  payoutMethod?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_payment.PayoutMethod;
  idempotencyKey?: string;
  metadata?: CreateUserPayoutRequest.MetadataEntry[];
}

export namespace CreateUserPayoutRequest {
  export interface MetadataEntry {
    key?: string;
    value?: string;
  }
}

export interface CreateUserPayoutResponse {}

export interface CancelUserPayoutRequest {
  userPayoutId?: string;
  billId?: string;
}

export interface CancelUserPayoutResponse {
  messageToUser?: string;
}

export interface ListUserPayoutSchedulesRequest {}

export interface ListUserPayoutSchedulesResponse {
  useApiForDefaultStartDatetime?: string;
  nextScheduleForDaily?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule;
  nextScheduleForDailyDefaultForBank?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule;
  nextScheduleForDailyDefaultForYucho?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule;
  nextScheduleForDailyExceptYuchoForBank?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule;
  nextScheduleForDailyExceptYuchoForYucho?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule;
  schedulesForDaily?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule[];
  schedulesForDailyDefaultForBank?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule[];
  schedulesForDailyDefaultForYucho?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule[];
  schedulesForDefaultExceptYuchoForBank?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule[];
  schedulesForDefaultExceptYuchoForYucho?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutSchedule[];
}

export interface GetUserPayoutInformationRequest {}

export interface GetUserPayoutInformationResponse {
  balance?: UserBalance;
  currentSales?: string;
  paymentFee?: string;
  jpDailyPaymentFee?: string;
  numTicket?: string;
  freePaymentFeeMinAmount?: string;
  feeDiscountReason?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_payment.PayoutMethod.FeeDiscountReason;
}

export interface GetUserPayoutBankAccountRequest {}

export interface GetUserPayoutBankAccountResponse {
  bankAccount?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutBankAccount;
  bank?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutBank;
}

export interface SetUserPayoutBankAccountRequest {
  bankCode?: string;
  branchCode?: string;
  accountType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.BankAccountType;
  accountNumber?: string;
  familyNameKana?: string;
  firstNameKana?: string;
}

export interface SetUserPayoutBankAccountResponse {
  bankAccountId?: string;
}

export interface ListUserPayoutsRequest {
  pageSize?: number;
  pageToken?: string;
}

export interface ListUserPayoutsResponse {
  userPayouts?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayout[];
  nextPageToken?: string;
}

export interface ListUserPayoutBanksRequest {}

export interface ListUserPayoutBanksResponse {
  mainBanks?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutBank[];
  bankGroups?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_bank_entity.UserPayoutBankKanaGroup[];
}

export interface RedeemQRCodeCampaignRequest {
  code?: string;
}

export interface RedeemQRCodeCampaignResponse {
  receivedPoints?: string;
}

export interface PaymentStatus {
  balance?: string;
  point?: string;
  freePoint?: string;
  prepaidPoint?: string;
  hasFundsAccount?: boolean;
  remainingCredit?: string;
  amountLimit?: number;
  deferredPaymentServiceStatus?: PaymentStatus.DeferredPaymentServiceStatus;
  isPostPaySetup?: boolean;
  arrearage?: string;
  postPayPaymentMode?: UserPaymentConfig.PostPayPaymentMode;
}

export namespace PaymentStatus {
  export enum DeferredPaymentServiceStatus {
    STATUS_UNKNOWN = "STATUS_UNKNOWN",
    STATUS_NORMAL = "STATUS_NORMAL",
    STATUS_MAINTENANCE = "STATUS_MAINTENANCE",
    STATUS_UNAVAILABLE = "STATUS_UNAVAILABLE"
  }
}

export interface GetPaymentStatusRequest {}

export interface GetPaymentStatusResponse {
  paymentStatus?: PaymentStatus;
}

export interface TopupProvider {
  id?: string;
  name?: string;
  nameKana?: string;
  logoImageUrl?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ListTopupProvidersRequest {}

export interface ListTopupProvidersResponse {
  topupProviders?: TopupProvider[];
}

export interface CreateSecureInformationForSevenBankRequest {
  qrCodeInformation?: string;
}

export interface CreateSecureInformationForSevenBankResponse {
  companyCode?: string;
}

export interface PersonalInformation {
  birthdate?: google_type_date.Date;
  address?: Address;
  phoneNumber?: string;
  job?: Job;
  gender?: Gender;
  familyName?: string;
  firstName?: string;
  familyNameKana?: string;
  firstNameKana?: string;
  subjectToFatca?: Trilean;
  nationality?: Nationality;
  visaType?: VisaType;
  cardExpiration?: google_type_date.Date;
}

export interface GetPersonalInformationRequest {
  mask?: string;
}

export interface GetPersonalInformationResponse {
  personalInformation?: PersonalInformation;
}

export interface CheckIDKYCAllowedRequest {}

export interface CheckIDKYCAllowedResponse {
  allowed?: boolean;
  requestedAuditInProgressAt?: string;
}

export interface UpdateIDPhotosRequest {
  idType?: IDType;
  photo1?: string;
  photo2?: string;
}

export interface UpdateIDPhotosResponse {}

export interface UpdateSupplementaryDocumentRequest {
  photo?: string;
}

export interface UpdateSupplementaryDocumentResponse {}

export interface RequestIDKYCAuditRequest {
  requester?: string;
  personalInformation?: PersonalInformation;
}

export interface RequestIDKYCAuditResponse {
  auditId?: string;
}

export interface UpdateEKYCIDPhotoRequest {
  type?: EKYCIDPhotoType;
  idType?: IDType;
  photo?: string;
  featureFlag?: KYCFeatureFlag;
}

export interface UpdateEKYCIDPhotoResponse {}

export interface UpdateEKYCSupplementaryDocumentRequest {
  photo?: string;
}

export interface UpdateEKYCSupplementaryDocumentResponse {}

export interface RequestEKYCAuditRequest {
  requester?: string;
  purposesOfUse?: PurposeOfUse[];
  notificationGroupType?: KYCNotificationGroupType;
  featureFlag?: KYCFeatureFlag;
}

export interface RequestEKYCAuditResponse {}

export interface RequestICChipKYCAuditRequest {
  personalInformation?: PersonalInformation;
  icData?: RequestICChipKYCAuditRequest.ICData;
  sign?: RequestICChipKYCAuditRequest.DigitalSignature;
  requester?: string;
  purposesOfUse?: PurposeOfUse[];
  notificationGroupType?: KYCNotificationGroupType;
  featureFlag?: KYCFeatureFlag;
}

export namespace RequestICChipKYCAuditRequest {
  export interface ICData {
    name?: string;
    birthDate?: string;
    address?: string;
  }

  export interface DigitalSignature {
    signature?: string;
    signingCert?: string;
    hashAlgOid?: string;
    expectedHash?: string;
  }
}

export interface RequestICChipKYCAuditResponse {
  approved?: boolean;
}

export interface GetICChipTextToBeSignedRequest {}

export interface GetICChipTextToBeSignedResponse {
  textToBeSigned?: string;
  createdAt?: string;
}

export interface CheckICChipKYCAllowedRequest {}

export interface CheckICChipKYCAllowedResponse {
  allowed?: boolean;
}

export interface UpdatePersonalInformationRequest {
  personalInformation?: PersonalInformation;
  featureFlag?: KYCFeatureFlag;
}

export interface UpdatePersonalInformationResponse {}

export interface CheckEKYCAllowedRequest {}

export interface CheckEKYCAllowedResponse {
  allowed?: boolean;
}

export interface GetPersonalInformationAndUserAttributeRequest {}

export interface GetPersonalInformationAndUserAttributeResponse {
  job?: Job;
  subjectToFatca?: Trilean;
  easypayStatus?: GetPersonalInformationAndUserAttributeResponse.EasypayStatus;
  workplace?: string;
  landlineNumber?: string;
}

export namespace GetPersonalInformationAndUserAttributeResponse {
  export enum EasypayStatus {
    EASYPAY_STATUS_UNSPECIFIED = "EASYPAY_STATUS_UNSPECIFIED",
    NOSIGN = "NOSIGN",
    REVIEWING = "REVIEWING",
    SIGNED = "SIGNED",
    CANCELED = "CANCELED"
  }
}

export interface UpdatePersonalInformationAndUserAttributeRequest {
  job?: Job;
  subjectToFatca?: Trilean;
  workplace?: string;
  landlineNumber?: string;
}

export interface UpdatePersonalInformationAndUserAttributeResponse {}

export interface Shop {
  name?: string;
  address?: string;
  location?: google_type_latlng.LatLng;
  markerImageType?: Shop.ImageType;
  markerImageUrl?: string;
  focusMarkerImageType?: Shop.ImageType;
  focusMarkerImageUrl?: string;
  logoImageType?: Shop.ImageType;
  logoImageUrl?: string;
  campaignLabel?: string;
  campaignUrl?: string;
  subCategory?: Shop.SubCategory;
  id?: string;
  phoneNumber?: string;
}

export namespace Shop {
  export enum SubCategory {
    SUB_CATEGORY_NONE = "SUB_CATEGORY_NONE",
    MEAL_RESTAURANT = "MEAL_RESTAURANT",
    MEAL_FASTFOOD = "MEAL_FASTFOOD",
    MEAL_RAMEN = "MEAL_RAMEN",
    MEAL_BAR = "MEAL_BAR",
    MEAL_PUB = "MEAL_PUB",
    MEAL_DELIVERY = "MEAL_DELIVERY",
    MEAL_VENDING_MACHINE = "MEAL_VENDING_MACHINE",
    MEAL_OTHER = "MEAL_OTHER",
    SHOPPING_FOOD_BEVERAGES = "SHOPPING_FOOD_BEVERAGES",
    SHOPPING_SUPERMARKET = "SHOPPING_SUPERMARKET",
    SHOPPING_CONVENIENCE_STORE = "SHOPPING_CONVENIENCE_STORE",
    SHOPPING_DEPARTMENT_STORE = "SHOPPING_DEPARTMENT_STORE",
    SHOPPING_HOMECENTER = "SHOPPING_HOMECENTER",
    SHOPPING_DRUGSTORE = "SHOPPING_DRUGSTORE",
    SHOPPING_COSMETIC = "SHOPPING_COSMETIC",
    SHOPPING_FASHION = "SHOPPING_FASHION",
    SHOPPING_CHILDREN = "SHOPPING_CHILDREN",
    SHOPPING_GLASSES = "SHOPPING_GLASSES",
    SHOPPING_SPORTS_GOODS = "SHOPPING_SPORTS_GOODS",
    SHOPPING_OTHER = "SHOPPING_OTHER",
    LIVING_REPAIR = "LIVING_REPAIR",
    LIVING_POST_OFFICE = "LIVING_POST_OFFICE",
    LIVING_GROCERY = "LIVING_GROCERY",
    LIVING_WELFARE = "LIVING_WELFARE",
    LIVING_FITNESS = "LIVING_FITNESS",
    LIVING_OTHER = "LIVING_OTHER",
    HOBBY_MUSIC = "HOBBY_MUSIC",
    HOBBY_BOOK = "HOBBY_BOOK",
    HOBBY_TECHNOLOGY = "HOBBY_TECHNOLOGY",
    HOBBY_OTHER = "HOBBY_OTHER",
    ENTERTAINMENT_MOVIE = "ENTERTAINMENT_MOVIE",
    ENTERTAINMENT_KARAOKE = "ENTERTAINMENT_KARAOKE",
    ENTERTAINMENT_POOL = "ENTERTAINMENT_POOL",
    ENTERTAINMENT_GAMECENTER = "ENTERTAINMENT_GAMECENTER",
    ENTERTAINMENT_NETCAFE = "ENTERTAINMENT_NETCAFE",
    ENTERTAINMENT_AMUSEMENT_PARK = "ENTERTAINMENT_AMUSEMENT_PARK",
    ENTERTAINMENT_PLANETARIUM = "ENTERTAINMENT_PLANETARIUM",
    ENTERTAINMENT_ZOO = "ENTERTAINMENT_ZOO",
    ENTERTAINMENT_TRAVEL = "ENTERTAINMENT_TRAVEL",
    ENTERTAINMENT_SPORTS = "ENTERTAINMENT_SPORTS",
    ENTERTAINMENT_OTHER = "ENTERTAINMENT_OTHER",
    BEAUTY_NAIL_SALON = "BEAUTY_NAIL_SALON",
    BEAUTY_MASSAGE = "BEAUTY_MASSAGE",
    BEAUTY_ALOMA_THERAPY = "BEAUTY_ALOMA_THERAPY",
    BEAUTY_ESTHETIC_SALON = "BEAUTY_ESTHETIC_SALON",
    BEAUTY_SALON = "BEAUTY_SALON",
    BEAUTY_OTHER = "BEAUTY_OTHER",
    PET_HOSPITAL = "PET_HOSPITAL",
    PET_SHOP = "PET_SHOP",
    PET_GOODS = "PET_GOODS",
    PET_SALON = "PET_SALON",
    PET_OTHER = "PET_OTHER",
    SCHOOL_SCHOOL = "SCHOOL_SCHOOL",
    SCHOOL_CLAM_SCHOOL = "SCHOOL_CLAM_SCHOOL",
    SCHOOL_CULTURE_AND_SPROTS = "SCHOOL_CULTURE_AND_SPROTS",
    MEDICAL_HOSPITAL = "MEDICAL_HOSPITAL",
    MEDICAL_DENTAL_CLINIC = "MEDICAL_DENTAL_CLINIC",
    MEDICAL_COSMETIC_CLINIC = "MEDICAL_COSMETIC_CLINIC",
    MEDICAL_CHIROPRACTIC = "MEDICAL_CHIROPRACTIC",
    MEDICAL_OTHER = "MEDICAL_OTHER",
    OTHER_CAR = "OTHER_CAR",
    OTHER_SERVICE_STATION_FOR_GAS = "OTHER_SERVICE_STATION_FOR_GAS",
    OTHER_CAR_STATION = "OTHER_CAR_STATION",
    OTHER_BANK = "OTHER_BANK",
    OTHER_PAWNSHOP = "OTHER_PAWNSHOP",
    OTHER_SHRINE = "OTHER_SHRINE",
    OTHER_PUBLIC_FACILITY = "OTHER_PUBLIC_FACILITY",
    OTHER_GYMNASIUM = "OTHER_GYMNASIUM",
    OTHER_BRIDAL = "OTHER_BRIDAL",
    OTHER_MARRIGE_AGENCY = "OTHER_MARRIGE_AGENCY",
    OTHER_FUNERAL = "OTHER_FUNERAL",
    OTHER_UNDEFINED = "OTHER_UNDEFINED"
  }

  export enum ImageType {
    UNKNOWN = "UNKNOWN",
    CATEGORY = "CATEGORY",
    CAMPAIGN = "CAMPAIGN",
    URL = "URL"
  }
}

export interface SearchShopsRequest {
  location?: google_type_latlng.LatLng;
  subCategory?: Shop.SubCategory;
}

export interface SearchShopsResponse {
  shops?: Shop[];
}

export interface UpdateP2PTransferUserProfileRequest {
  screenName?: string;
}

export interface UpdateP2PTransferUserProfileResponse {}

export interface UpdateP2PTransferUserProfileImageRequest {
  image?: string;
}

export interface UpdateP2PTransferUserProfileImageResponse {}

export interface GetP2PTransferUserProfileRequest {}

export interface P2PTransferUserProfile {
  screenName?: string;
  imageUrl?: string;
}

export interface GetP2PTransferUserProfileResponse {
  profileConfigured?: boolean;
  profile?: P2PTransferUserProfile;
}

export interface P2PTransfer {
  id?: string;
  senderProfile?: P2PTransferUserProfile;
  recipientProfile?: P2PTransferUserProfile;
  state?: P2PTransfer.State;
  inquiryId?: InquiryID;
  sentFunds?: number;
  sentPrepaidPoint?: number;
  sentFreePoint?: number;
  receivedFunds?: number;
  receivedPrepaidPoint?: number;
  receivedFreePoint?: number;
  createdAt?: string;
  expiresAt?: string;
  message?: string;
  card?: P2PTransferMessageCard;
}

export namespace P2PTransfer {
  export enum State {
    STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
    STATE_CREATED = "STATE_CREATED",
    STATE_WAITING_FOR_CONFIRMATION = "STATE_WAITING_FOR_CONFIRMATION",
    STATE_WAITING_FOR_FTA_CREATION = "STATE_WAITING_FOR_FTA_CREATION",
    STATE_CAPTURE_REQUESTED = "STATE_CAPTURE_REQUESTED",
    STATE_CAPTURED = "STATE_CAPTURED",
    STATE_CANCEL_REQUESTED = "STATE_CANCEL_REQUESTED",
    STATE_CANCELED = "STATE_CANCELED",
    STATE_VOID_REQUESTED = "STATE_VOID_REQUESTED",
    STATE_VOIDED = "STATE_VOIDED",
    STATE_EXPIRED = "STATE_EXPIRED"
  }
}

export interface CreateP2PTransferRequest {
  funds?: number;
  prepaidPoint?: number;
  freePoint?: number;
  message?: string;
  cardId?: number;
}

export interface CreateP2PTransferResponse {
  url?: string;
  shareText?: string;
}

export interface ReceiveP2PTransferRequest {
  token?: string;
}

export interface ReceiveP2PTransferResponse {
  ftaCreationRequired?: boolean;
}

export interface CancelP2PTransferRequest {
  transferId?: string;
}

export interface CancelP2PTransferResponse {}

export interface GetP2PTransferRequest {
  transferId?: string;
}

export interface GetP2PTransferResponse {
  transfer?: P2PTransfer;
  isSender?: boolean;
}

export interface GetP2PTransferByTokenRequest {
  token?: string;
}

export interface GetP2PTransferByTokenResponse {
  transfer?: P2PTransfer;
  isSender?: boolean;
}

export interface ConfirmP2PTransferRecipientRequest {
  transferId?: string;
}

export interface ConfirmP2PTransferRecipientResponse {}

export interface ListP2PTransfersSentRequest {
  pageSize?: number;
  pageToken?: string;
}

export interface ListP2PTransfersSentResponse {
  transfers?: P2PTransfer[];
  nextPageToken?: string;
}

export interface ListP2PTransfersReceivedRequest {
  pageSize?: number;
  pageToken?: string;
}

export interface ListP2PTransfersReceivedResponse {
  transfers?: P2PTransfer[];
  nextPageToken?: string;
}

export interface ListP2PTransferMessageCardsByCategoryRequest {}

export interface P2PTransferMessageCardCategory {
  id?: number;
  name?: string;
  cards?: P2PTransferMessageCard[];
}

export interface P2PTransferMessageCard {
  categoryId?: number;
  id?: number;
  url?: string;
  textColor?: string;
}

export interface ListP2PTransferMessageCardsByCategoryResponse {
  categories?: P2PTransferMessageCardCategory[];
}

export interface CreateDocomoConnectionRequest {
  idToken?: string;
}

export interface CreateDocomoConnectionResponse {
  userId?: string;
  subjectId?: string;
}

export interface CreateDocomoNonceRequest {}

export interface CreateDocomoNonceResponse {
  nonce?: string;
}

export interface GetDocomoConnectionRequest {
  idToken?: string;
}

export interface DocomoConnection {
  userId?: string;
  subjectId?: string;
  createTimestamp?: string;
  sessionId?: string;
}

export interface GetDocomoConnectionResponse {
  connection?: DocomoConnection;
}

export interface DeleteDocomoConnectionRequest {}

export interface GetMyPageRequest {}

export interface GetMyPageResponse {
  paymentStatus?: PaymentStatus;
  repaymentReminder?: GetMyPageResponse.RepaymentReminder;
  easypayUserInfo?: GetMyPageResponse.EasypayUserInfo;
}

export namespace GetMyPageResponse {
  export interface RepaymentReminder {
    titleTextTypeAttention?: GetMyPageResponse.RepaymentReminder.AttentionLabel;
    titleTextTypeNormal?: GetMyPageResponse.RepaymentReminder.NormalLabel;
    mainText?: string;
    subText?: string;
    colorType?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_design_system.DesignSystemStyle.IconColorType;
  }

  export namespace RepaymentReminder {
    export interface AttentionLabel {
      text?: string;
    }

    export interface NormalLabel {
      text?: string;
    }
  }

  export interface EasypayUserInfo {
    easypayStatus?: GetMyPageResponse.EasypayUserInfo.EasypayStatus;
    completedInvoiceMigration?: boolean;
  }

  export namespace EasypayUserInfo {
    export enum EasypayStatus {
      EASYPAY_STATUS_UNSPECIFIED = "EASYPAY_STATUS_UNSPECIFIED",
      NOT_SIGNED = "NOT_SIGNED",
      SIGNED = "SIGNED",
      CANCELED = "CANCELED"
    }
  }
}

export interface GetMerpaySettingRequest {}

export interface GetMerpaySettingResponse {
  hasFundsAccount?: boolean;
  hasComprehensiveContract?: boolean;
  isEasypaySigned?: boolean;
  hasLendingContract?: boolean;
  hasNetpaymentSetting?: boolean;
  hasPaymentAmountLimitation?: boolean;
  hasNfcRegistration?: boolean;
  hasPasscodeSetting?: boolean;
  hasAvailableRealcard?: boolean;
  postPayPaymentMode?: string;
  postPayConfigAmountLimitNotSet?: boolean;
  postPayConfigAmountLimit?: number;
  smartpayRepaymentSetting?: string;
  smartpaySectionName?: string;
  isPostPaySetup?: boolean;
}

export interface GetEasypayDestinationForApplicationRequest {
  mercariItemId?: string;
  price?: string;
}

export interface GetEasypayDestinationForApplicationResponse {
  destination?: GetEasypayDestinationForApplicationResponse.Destination;
}

export namespace GetEasypayDestinationForApplicationResponse {
  export enum Destination {
    DESTINATION_UNSPECIFIED = "DESTINATION_UNSPECIFIED",
    EASYPAY_LP = "EASYPAY_LP",
    REPAYMENT_PLAN = "REPAYMENT_PLAN"
  }
}

export interface ActivateRealcardRequest {
  issuingId?: string;
}

export interface ActivateRealcardResponse {
  isFirstActivation?: boolean;
}

export interface GetWaitActivationRealcardRequest {
  cardId?: string;
  issuingId?: string;
}

export interface GetWaitActivationRealcardResponse {
  cardId?: string;
}

export interface OpenRealcardLandingPageRequest {}

export interface OpenRealcardLandingPageResponse {}

export interface GetRealcardApplicationStatusRequest {
  featureFlags?: FeatureFlag[];
}

export interface GetRealcardApplicationStatusResponse {
  applicationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardApplicationStatus;
  requireParentalConsent?: string;
}

export interface GetRealcardDetailRequest {
  cardId?: string;
  publicKey?: string;
  issuingId?: string;
}

export interface GetRealcardDetailResponse {
  detail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardDetail;
  hasOtherValidCards?: boolean;
  isCacheAvailable?: boolean;
  gmopgMaintenanceMessage?: google_rpc_error_details.LocalizedMessage;
  hasPaymentLimitation?: boolean;
  postPayConfigAmountLimitNotSet?: boolean;
  postPayConfigAmountLimit?: number;
  smartpayRepaymentSetting?: string;
  smartpayRepaymentSettingNotSet?: boolean;
}

export interface GetRealcardApplicationInformationRequest {
  sourceCardId?: string;
}

export interface GetRealcardApplicationInformationResponse {
  personalInformation?: PersonalInformationForRealcard;
  birthdate?: google_type_date.Date;
  phoneNumber?: string;
  job?: Job;
  workplace?: string;
  sourceCardId?: string;
  cardHolder?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.CardHolder;
}

export interface GetRealcardApplicationSMSVerificationRequest {}

export interface GetRealcardApplicationSMSVerificationResponse {}

export interface PreValidateRealcardApplicationRequest {
  personalInformation?: PersonalInformationForRealcard;
  cardHolder?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.CardHolder;
}

export interface PreValidateRealcardApplicationResponse {}

export interface ValidateRealcardApplicationRequest {
  sequenceId?: string;
  personalInformation?: PersonalInformationForRealcard;
  cardHolder?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.CardHolder;
  sourceCardId?: string;
  reissueReason?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardReissueReasonType;
  featureFlags?: FeatureFlag[];
}

export interface ValidateRealcardApplicationResponse {}

export interface InitiateRealcardApplicationRequest {
  sequenceId?: string;
  inquiryKey?: string;
}

export interface InitiateRealcardApplicationResponse {
  cardId?: string;
}

export interface CreateRealcardApplicationRequest {
  cardId?: string;
  sequenceId?: string;
}

export interface CreateRealcardApplicationResponse {
  applicationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardApplicationStatus;
}

export interface GetRealcardTokenRequest {
  cardId?: string;
  publicKey?: string;
  scope?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardTokenScope;
}

export interface GetRealcardTokenResponse {
  encryptedToken?: string;
}

export interface ListRealcardRequest {
  listOption?: ListOption;
}

export interface ListRealcardResponse {
  availableDetail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardDetail;
  canceledDetail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardDetail;
}

export interface PersonalInformationForRealcard {
  address?: Address;
  familyName?: string;
  firstName?: string;
  familyNameKana?: string;
  firstNameKana?: string;
}

export interface RegisterPublicKeyRequest {
  publicKey?: string;
}

export interface RegisterPublicKeyResponse {}

export interface CancelRealcardRequest {
  cardId?: string;
}

export interface CancelRealcardResponse {}

export interface UpdateRealcardLockRequest {
  cardId?: string;
  lock?: boolean;
}

export interface UpdateRealcardLockResponse {
  detail?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardDetail;
}

export interface GetRealcardReissueReasonsRequest {}

export interface GetRealcardReissueReasonsResponse {
  reasons?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardReissueReason[];
}

export interface GetRealcardApplicationDeliveryStatusRequest {}

export interface GetRealcardApplicationDeliveryStatusResponse {
  cardId?: string;
  issuingId?: string;
  status?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_realcard.RealcardShippingStatus;
  personalInformation?: PersonalInformationForRealcard;
  addressName?: string;
}

export interface Get3DSTransactionInfoRequest {
  stepupRequestId?: string;
}

export interface Get3DSTransactionInfoResponse {
  transactionId?: string;
  lastFourOfFpan?: string;
  cardBrand?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_vcas.VCASCardBrand;
  merchantName?: string;
  transactionTimeStamp?: string;
  transactionAmount?: string;
  transactionCurrency?: string;
  authenticationStatus?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_vcas.VCASAuthenticationStatus;
}

export interface Confirm3DSRequest {
  stepupRequestId?: string;
}

export interface Confirm3DSResponse {}

export interface Cancel3DSRequest {
  stepupRequestId?: string;
}

export interface Cancel3DSResponse {}

export interface GetPaymentAmountSummaryRequest {}

export interface GetPaymentAmountSummaryResponse {
  monthlyPaymentAmountLimitation?: string;
  dailyPaymentAmountLimitation?: string;
  totalMonthlyUsageAmount?: string;
  totalDailyUsageAmount?: string;
  monthlyRemainingAvailableAmount?: string;
  dailyRemainingAvailableAmount?: string;
  yearmonth?: mercari_platform_proto_microservices_merpay_api_jp_api_v1_entity.YearMonth;
  hasDailyLimitation?: boolean;
  hasMonthlyLimitation?: boolean;
}

export interface UpdatePaymentAmountLimitationRequest {
  monthlyPaymentAmountLimitation?: string;
  dailyPaymentAmountLimitation?: string;
  disableMonthlyPaymentAmountLimitation?: boolean;
  disableDailyPaymentAmountLimitation?: boolean;
}

export interface UpdatePaymentAmountLimitationResponse {}

export interface ConfirmCreditCardThreeDomainSecurePaymentRequest {
  sourceId?: string;
}

export interface ConfirmCreditCardThreeDomainSecurePaymentResponse {}

export interface InitializeCreditCardThreeDomainSecureForWebRequest {
  creditCardId?: string;
  callbackUrl?: string;
  recipientType?: InitializeCreditCardThreeDomainSecureForWebRequest.RecipientType;
}

export namespace InitializeCreditCardThreeDomainSecureForWebRequest {
  export enum RecipientType {
    RECIPIENT_UNKNOWN = "RECIPIENT_UNKNOWN",
    RECIPIENT_CHARGE = "RECIPIENT_CHARGE",
    RECIPIENT_ESCROW = "RECIPIENT_ESCROW",
    RECIPIENT_POINT_RECHARGE = "RECIPIENT_POINT_RECHARGE",
    RECIPIENT_FUNDS_RECHARGE = "RECIPIENT_FUNDS_RECHARGE",
    RECIPIENT_DEFERRED_PAYMENT = "RECIPIENT_DEFERRED_PAYMENT",
    RECIPIENT_REPAYMENT = "RECIPIENT_REPAYMENT"
  }
}

export interface InitializeCreditCardThreeDomainSecureForWebResponse {
  redirectUrl?: string;
  status?: InitializeCreditCardThreeDomainSecureForWebResponse.Status;
}

export namespace InitializeCreditCardThreeDomainSecureForWebResponse {
  export enum Status {
    STATUS_UNKNOWN = "STATUS_UNKNOWN",
    STATUS_INIT_SUCCESS = "STATUS_INIT_SUCCESS",
    STATUS_NO_3DS = "STATUS_NO_3DS"
  }
}

export interface GetSmartpayOnboardingRequest {}

export interface GetSmartpayOnboardingResponse {
  needToPlayEasypayOnboarding?: boolean;
}

export interface UpdateSmartpayOnboardingRequest {
  isDonePostpayOnboarding?: boolean;
  isDoneEasypayOnboarding?: boolean;
}

export interface EntryTypeCampaignEntryEligibility {
  isRealcardActive?: boolean;
}

export interface EntryTypeCampaignPromotion {
  promotionKey?: string;
  isEntered?: boolean;
  promotionStartTime?: string;
  promotionEndTime?: string;
  promotionPeriodStatus?: EntryTypeCampaignPromotion.PeriodStatus;
  entryStartTime?: string;
  entryEndTime?: string;
  entryPeriodStatus?: EntryTypeCampaignPromotion.PeriodStatus;
  isEntryRequired?: boolean;
  campaignType?: EntryTypeCampaignPromotion.CampaignType;
}

export namespace EntryTypeCampaignPromotion {
  export enum PeriodStatus {
    PERIOD_STATUS_UNSPECIFIED = "PERIOD_STATUS_UNSPECIFIED",
    PERIOD_STATUS_BEFORE_THE_PERIOD = "PERIOD_STATUS_BEFORE_THE_PERIOD",
    PERIOD_STATUS_DURING_THE_PERIOD = "PERIOD_STATUS_DURING_THE_PERIOD",
    PERIOD_STATUS_AFTER_THE_PERIOD = "PERIOD_STATUS_AFTER_THE_PERIOD"
  }

  export enum CampaignType {
    CAMPAIGN_TYPE_UNSPECIFIED = "CAMPAIGN_TYPE_UNSPECIFIED",
    ENTRY_TYPE = "ENTRY_TYPE",
    ADMISSION = "ADMISSION"
  }
}

export interface BankKYCClosureNotTargeted {}

export interface BeforeBankKYCClosureLimitationData {
  limitationDate?: google_type_date.Date;
  transitionUrl?: string;
}

export interface AfterBankKYCClosureLimitationData {
  transitionUrl?: string;
}

export interface GetBankKYCClosureStatusRequest {}

export interface GetBankKYCClosureStatusResponse {
  notTargeted?: BankKYCClosureNotTargeted;
  beforeLimitation?: BeforeBankKYCClosureLimitationData;
  afterLimitation?: AfterBankKYCClosureLimitationData;
}

export interface GetEKYCRecordsRequest {}

export interface GetEKYCRecordsResponse {
  records?: VerificationRecord[];
}

export interface VerificationRecord {
  targetPerson?: string;
  purposesOfUseFta?: PurposeOfUse[];
  auditId?: string;
  familyName?: string;
  firstName?: string;
  familyNameKana?: string;
  firstNameKana?: string;
  address?: Address;
  birthdate?: google_type_date.Date;
  job?: Job;
  createdAt?: string;
  identityVerificationType?: string;
  idType?: IDType;
  nationality?: Nationality;
  visaType?: VisaType;
  cardExpiration?: google_type_date.Date;
  supplementaryDocumentType?: SupplementaryDocumentType;
  identityCardIssuer?: string;
  identityCardValidityStartDate?: google_type_date.Date;
  identityCardValidityEndDate?: google_type_date.Date;
  recordId?: string;
}

export enum PurposeOfUse {
  PURPOSE_OF_USE_NONE = "PURPOSE_OF_USE_NONE",
  PURPOSE_OF_USE_PAYMENTS = "PURPOSE_OF_USE_PAYMENTS",
  PURPOSE_OF_USE_MONEY_TRANSFER = "PURPOSE_OF_USE_MONEY_TRANSFER"
}

export enum DashboardPayAreaStyleType {
  DASHBOARD_PAY_AREA_STYLE_TYPE_UNSPECIFIED = "DASHBOARD_PAY_AREA_STYLE_TYPE_UNSPECIFIED",
  DARK = "DARK",
  LIGHT = "LIGHT"
}

export enum ImagesByUserConditionType {
  IMAGES_BY_USER_CONDITION_TYPE_UNSPECIFIED = "IMAGES_BY_USER_CONDITION_TYPE_UNSPECIFIED",
  IMAGES_BY_USER_CONDITION_TYPE_BANK_LOGO = "IMAGES_BY_USER_CONDITION_TYPE_BANK_LOGO"
}

export enum FundsHistoryType {
  FUNDS_HISTORY_TYPE_UNKNOWN = "FUNDS_HISTORY_TYPE_UNKNOWN",
  FUNDS_HISTORY_TYPE_TRANSACTION_OPERATION = "FUNDS_HISTORY_TYPE_TRANSACTION_OPERATION",
  FUNDS_HISTORY_TYPE_MERCARI_ESCROW = "FUNDS_HISTORY_TYPE_MERCARI_ESCROW",
  FUNDS_HISTORY_TYPE_MERCARI_ESCROW_CANCELED = "FUNDS_HISTORY_TYPE_MERCARI_ESCROW_CANCELED",
  FUNDS_HISTORY_TYPE_NFC_CHARGE = "FUNDS_HISTORY_TYPE_NFC_CHARGE",
  FUNDS_HISTORY_TYPE_NFC_CHARGE_CANCELED = "FUNDS_HISTORY_TYPE_NFC_CHARGE_CANCELED",
  FUNDS_HISTORY_TYPE_MERCHANT_CODE_CHARGE = "FUNDS_HISTORY_TYPE_MERCHANT_CODE_CHARGE",
  FUNDS_HISTORY_TYPE_MERCHANT_CODE_CHARGE_CANCELED = "FUNDS_HISTORY_TYPE_MERCHANT_CODE_CHARGE_CANCELED",
  FUNDS_HISTORY_TYPE_RECHARGE = "FUNDS_HISTORY_TYPE_RECHARGE",
  FUNDS_HISTORY_TYPE_PAYOUT = "FUNDS_HISTORY_TYPE_PAYOUT",
  FUNDS_HISTORY_TYPE_PAYOUT_FAILED = "FUNDS_HISTORY_TYPE_PAYOUT_FAILED",
  FUNDS_HISTORY_TYPE_PAYOUT_CANCELED = "FUNDS_HISTORY_TYPE_PAYOUT_CANCELED",
  FUNDS_HISTORY_TYPE_NETPAYMENT_CHARGE = "FUNDS_HISTORY_TYPE_NETPAYMENT_CHARGE",
  FUNDS_HISTORY_TYPE_NETPAYMENT_CHARGE_CANCELED = "FUNDS_HISTORY_TYPE_NETPAYMENT_CHARGE_CANCELED",
  FUNDS_HISTORY_TYPE_PERSONAL_TRANSFER = "FUNDS_HISTORY_TYPE_PERSONAL_TRANSFER",
  FUNDS_HISTORY_TYPE_PERSONAL_TRANSFER_CANCELED = "FUNDS_HISTORY_TYPE_PERSONAL_TRANSFER_CANCELED",
  FUNDS_HISTORY_TYPE_CONNECT_CHARGE = "FUNDS_HISTORY_TYPE_CONNECT_CHARGE",
  FUNDS_HISTORY_TYPE_CONNECT_CHARGE_CANCELED = "FUNDS_HISTORY_TYPE_CONNECT_CHARGE_CANCELED",
  FUNDS_HISTORY_TYPE_CONNECT_TRANSFER = "FUNDS_HISTORY_TYPE_CONNECT_TRANSFER",
  FUNDS_HISTORY_TYPE_REALCARD = "FUNDS_HISTORY_TYPE_REALCARD"
}

export enum PointHistoryType {
  POINT_HISTORY_TYPE_UNKNOWN = "POINT_HISTORY_TYPE_UNKNOWN",
  POINT_HISTORY_TYPE_TRANSACTION_OPERATION = "POINT_HISTORY_TYPE_TRANSACTION_OPERATION",
  POINT_HISTORY_TYPE_MERCARI_ESCROW = "POINT_HISTORY_TYPE_MERCARI_ESCROW",
  POINT_HISTORY_TYPE_MERCARI_ESCROW_CANCELED = "POINT_HISTORY_TYPE_MERCARI_ESCROW_CANCELED",
  POINT_HISTORY_TYPE_NFC_CHARGE = "POINT_HISTORY_TYPE_NFC_CHARGE",
  POINT_HISTORY_TYPE_NFC_CHARGE_CANCELED = "POINT_HISTORY_TYPE_NFC_CHARGE_CANCELED",
  POINT_HISTORY_TYPE_MERCHANT_CODE_CHARGE = "POINT_HISTORY_TYPE_MERCHANT_CODE_CHARGE",
  POINT_HISTORY_TYPE_MERCHANT_CODE_CHARGE_CANCELED = "POINT_HISTORY_TYPE_MERCHANT_CODE_CHARGE_CANCELED",
  POINT_HISTORY_TYPE_NETPAYMENT_CHARGE = "POINT_HISTORY_TYPE_NETPAYMENT_CHARGE",
  POINT_HISTORY_TYPE_NETPAYMENT_CHARGE_CANCELED = "POINT_HISTORY_TYPE_NETPAYMENT_CHARGE_CANCELED",
  POINT_HISTORY_TYPE_CAMPAIGN = "POINT_HISTORY_TYPE_CAMPAIGN",
  POINT_HISTORY_TYPE_RECHARGE = "POINT_HISTORY_TYPE_RECHARGE",
  POINT_HISTORY_TYPE_PERSONAL_TRANSFER = "POINT_HISTORY_TYPE_PERSONAL_TRANSFER",
  POINT_HISTORY_TYPE_PERSONAL_TRANSFER_CANCELED = "POINT_HISTORY_TYPE_PERSONAL_TRANSFER_CANCELED",
  POINT_HISTORY_TYPE_CONNECT_CHARGE = "POINT_HISTORY_TYPE_CONNECT_CHARGE",
  POINT_HISTORY_TYPE_CONNECT_CHARGE_CANCELED = "POINT_HISTORY_TYPE_CONNECT_CHARGE_CANCELED",
  POINT_HISTORY_TYPE_CONNECT_TRANSFER = "POINT_HISTORY_TYPE_CONNECT_TRANSFER",
  POINT_HISTORY_TYPE_REALCARD = "POINT_HISTORY_TYPE_REALCARD"
}

export enum QRCodeType {
  CODE_TYPE_UNKNOWN = "CODE_TYPE_UNKNOWN",
  CODE_TYPE_STATIC_MPM = "CODE_TYPE_STATIC_MPM",
  CODE_TYPE_NETPAYMENT_DEEPLINK = "CODE_TYPE_NETPAYMENT_DEEPLINK",
  CODE_TYPE_7BANK_ATM = "CODE_TYPE_7BANK_ATM",
  CODE_TYPE_OPEN_BROWSER = "CODE_TYPE_OPEN_BROWSER"
}

export enum StaticMPMViolationType {
  UNSPECIFIED = "UNSPECIFIED",
  EXPIRED_SESSION = "EXPIRED_SESSION",
  REACH_AMOUNT_LIMIT = "REACH_AMOUNT_LIMIT",
  REACH_CHARGE_COUNT_LIMIT_PER_DAY = "REACH_CHARGE_COUNT_LIMIT_PER_DAY",
  NOT_ENOUGH_CUSTOMER_BALANCE = "NOT_ENOUGH_CUSTOMER_BALANCE",
  FORBIDDEN_CUSTOMER = "FORBIDDEN_CUSTOMER",
  FORBIDDEN_PARTNER = "FORBIDDEN_PARTNER",
  DETECTED_DUPLICATE_CHARGE_REQUEST = "DETECTED_DUPLICATE_CHARGE_REQUEST",
  DEFERRED_EXCEED_CREDIT_LIMIT = "DEFERRED_EXCEED_CREDIT_LIMIT",
  DEFERRED_RESTRICTED_USER = "DEFERRED_RESTRICTED_USER",
  DAILY_AMOUNT_LIMITATION_EXCEEDED = "DAILY_AMOUNT_LIMITATION_EXCEEDED",
  MONTHLY_AMOUNT_LIMITATION_EXCEEDED = "MONTHLY_AMOUNT_LIMITATION_EXCEEDED",
  DAILY_MONTHLY_AMOUNT_LIMITATION_EXCEEDED = "DAILY_MONTHLY_AMOUNT_LIMITATION_EXCEEDED"
}

export enum PasscodeViolationType {
  PASSCODE_VIOLATION_TYPE_UNSPECIFIED = "PASSCODE_VIOLATION_TYPE_UNSPECIFIED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_LOCKED = "PASSCODE_VIOLATION_TYPE_PASSCODE_LOCKED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_VERIFICATION_TEMPORARY_DISABLED = "PASSCODE_VIOLATION_TYPE_PASSCODE_VERIFICATION_TEMPORARY_DISABLED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_NOT_CREATED = "PASSCODE_VIOLATION_TYPE_PASSCODE_NOT_CREATED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_NOT_MATCHED = "PASSCODE_VIOLATION_TYPE_PASSCODE_NOT_MATCHED",
  PASSCODE_VIOLATION_TYPE_PHONE_NUMBER_NOT_REGISTERED = "PASSCODE_VIOLATION_TYPE_PHONE_NUMBER_NOT_REGISTERED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_RESET_OTP_EXPIRED = "PASSCODE_VIOLATION_TYPE_PASSCODE_RESET_OTP_EXPIRED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_RESET_OTP_NOT_MATCHED = "PASSCODE_VIOLATION_TYPE_PASSCODE_RESET_OTP_NOT_MATCHED",
  PASSCODE_VIOLATION_TYPE_PASSCODE_RESET_OTP_VERIFICATION_TEMPORARY_DISABLED = "PASSCODE_VIOLATION_TYPE_PASSCODE_RESET_OTP_VERIFICATION_TEMPORARY_DISABLED"
}

export enum PasscodeFieldViolationType {
  PASSCODE_FIELD_VIOLATION_TYPE_UNSPECIFIED = "PASSCODE_FIELD_VIOLATION_TYPE_UNSPECIFIED",
  PASSCODE_FIELD_VIOLATION_TYPE_PASSCODE_MALFORMAT = "PASSCODE_FIELD_VIOLATION_TYPE_PASSCODE_MALFORMAT",
  PASSCODE_FIELD_VIOLATION_TYPE_PASSCODE_BIRTHDAY = "PASSCODE_FIELD_VIOLATION_TYPE_PASSCODE_BIRTHDAY",
  PASSCODE_FIELD_VIOLATION_TYPE_PASSCODE_CONSECUTIVE = "PASSCODE_FIELD_VIOLATION_TYPE_PASSCODE_CONSECUTIVE",
  PASSCODE_FIELD_VIOLATION_TYPE_BANK_ACCOUNT_NUMBER_INVALID = "PASSCODE_FIELD_VIOLATION_TYPE_BANK_ACCOUNT_NUMBER_INVALID"
}

export enum ClientDashboardType {
  CLIENT_DASHBOARD_TYPE_UNSPECIFIED = "CLIENT_DASHBOARD_TYPE_UNSPECIFIED",
  SALES = "SALES",
  BALANCE = "BALANCE",
  POST_PAY = "POST_PAY"
}

export enum CouponPaymentType {
  PAYMENT_TYPE_UNSPECIFIED = "PAYMENT_TYPE_UNSPECIFIED",
  PAYMENT_TYPE_NAZCA = "PAYMENT_TYPE_NAZCA",
  PAYMENT_TYPE_CODE = "PAYMENT_TYPE_CODE",
  PAYMENT_TYPE_NETPAYMENT = "PAYMENT_TYPE_NETPAYMENT"
}

export enum MercariHomeHeaderButton {
  MERCARI_HOME_HEADER_BUTTON_UNSPECIFIED = "MERCARI_HOME_HEADER_BUTTON_UNSPECIFIED",
  MERCARI_HOME_HEADER_BUTTON_CODE_PAYMENT = "MERCARI_HOME_HEADER_BUTTON_CODE_PAYMENT",
  MERCARI_HOME_HEADER_BUTTON_NFC = "MERCARI_HOME_HEADER_BUTTON_NFC"
}

export enum Job {
  JOB_NOT_SELECTED = "JOB_NOT_SELECTED",
  JOB_EMPLOYEE = "JOB_EMPLOYEE",
  JOB_EXECUTIVE = "JOB_EXECUTIVE",
  JOB_PUBLIC_EMPLOYEE = "JOB_PUBLIC_EMPLOYEE",
  JOB_SELF_EMPLOYED = "JOB_SELF_EMPLOYED",
  JOB_CONTRACT_EMPLOYEE = "JOB_CONTRACT_EMPLOYEE",
  JOB_PART_TIMER = "JOB_PART_TIMER",
  JOB_STAY_AT_HOME = "JOB_STAY_AT_HOME",
  JOB_PENSIONER = "JOB_PENSIONER",
  JOB_STUDENT = "JOB_STUDENT",
  JOB_NOT_IN_EMPLOYMENT = "JOB_NOT_IN_EMPLOYMENT",
  JOB_DOCTOR = "JOB_DOCTOR",
  JOB_SPECIALIST = "JOB_SPECIALIST",
  JOB_TEACHER = "JOB_TEACHER"
}

export enum KYCFeatureFlag {
  FEATURE_FLAG_UNSPECIFIED = "FEATURE_FLAG_UNSPECIFIED",
  FEATURE_FLAG_NON_JP_NATIONALS_CORRESPONDENCE = "FEATURE_FLAG_NON_JP_NATIONALS_CORRESPONDENCE"
}

export enum Gender {
  GENDER_NOT_SELECTED = "GENDER_NOT_SELECTED",
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum UserUndeletableReason {
  USER_UNDELETABLE_REASON_STATUS_UNKNOWN = "USER_UNDELETABLE_REASON_STATUS_UNKNOWN",
  USER_UNDELETABLE_REASON_STATUS_RECHARGE_PROCESSING = "USER_UNDELETABLE_REASON_STATUS_RECHARGE_PROCESSING",
  USER_UNDELETABLE_REASON_STATUS_WITHDRAWAL_PROCESSING = "USER_UNDELETABLE_REASON_STATUS_WITHDRAWAL_PROCESSING",
  USER_UNDELETABLE_REASON_STATUS_BALANCE_REMAINS = "USER_UNDELETABLE_REASON_STATUS_BALANCE_REMAINS"
}

export enum UpdateZipCodeViolationType {
  UPDATE_ZIP_CODE_VIOLATION_TYPE_UNSPECIFIED = "UPDATE_ZIP_CODE_VIOLATION_TYPE_UNSPECIFIED",
  UPDATE_ZIP_CODE_VIOLATION_TYPE_INVALID = "UPDATE_ZIP_CODE_VIOLATION_TYPE_INVALID",
  UPDATE_ZIP_CODE_VIOLATION_TYPE_DUPLICATED = "UPDATE_ZIP_CODE_VIOLATION_TYPE_DUPLICATED"
}

export enum TermsAndConditionsType {
  T_AND_C_UNKNOWN = "T_AND_C_UNKNOWN",
  T_AND_C_MERCARI = "T_AND_C_MERCARI",
  T_AND_C_PREPAID_POINT = "T_AND_C_PREPAID_POINT",
  T_AND_C_MERCHANT = "T_AND_C_MERCHANT",
  T_AND_C_MERCARI_PAY = "T_AND_C_MERCARI_PAY",
  T_AND_C_MERPAY_PRIVACY_POLICY = "T_AND_C_MERPAY_PRIVACY_POLICY",
  T_AND_C_EASYPAY_LITE = "T_AND_C_EASYPAY_LITE",
  T_AND_C_EASYPAY_PRIVACY_POLICY = "T_AND_C_EASYPAY_PRIVACY_POLICY",
  T_AND_C_LENDING = "T_AND_C_LENDING",
  T_AND_C_LENDING_PRIVACY_POLICY = "T_AND_C_LENDING_PRIVACY_POLICY",
  T_AND_C_LENDING_DIGITAL_AGREEMENT = "T_AND_C_LENDING_DIGITAL_AGREEMENT",
  T_AND_C_MERPAY_REALCARD_AGREEMENT = "T_AND_C_MERPAY_REALCARD_AGREEMENT",
  T_AND_C_MERPAY_3DSECURE_AGREEMENT = "T_AND_C_MERPAY_3DSECURE_AGREEMENT"
}

export enum CodePaymentUsePasscode {
  CODE_PAYMENT_USE_PASSCODE_UNSPECIFIED = "CODE_PAYMENT_USE_PASSCODE_UNSPECIFIED",
  CODE_PAYMENT_USE_PASSCODE_ON = "CODE_PAYMENT_USE_PASSCODE_ON",
  CODE_PAYMENT_USE_PASSCODE_OFF = "CODE_PAYMENT_USE_PASSCODE_OFF"
}

export enum UserEventType {
  USER_EVENT_TYPE_UNSPECIFIED = "USER_EVENT_TYPE_UNSPECIFIED",
  USER_EVENT_TYPE_LATEST_SUCCEEDED_CODE_PAYMENT = "USER_EVENT_TYPE_LATEST_SUCCEEDED_CODE_PAYMENT",
  USER_EVENT_TYPE_LATEST_REQUIRED_CHARGE = "USER_EVENT_TYPE_LATEST_REQUIRED_CHARGE",
  USER_EVENT_TYPE_LATEST_FAILED_CODE_PAYMENT = "USER_EVENT_TYPE_LATEST_FAILED_CODE_PAYMENT"
}

export enum EKYCIDPhotoType {
  EKYC_ID_PHOTO_TYPE_UNSPECIFIED = "EKYC_ID_PHOTO_TYPE_UNSPECIFIED",
  EKYC_ID_PHOTO_TYPE_FRONT = "EKYC_ID_PHOTO_TYPE_FRONT",
  EKYC_ID_PHOTO_TYPE_BACK = "EKYC_ID_PHOTO_TYPE_BACK",
  EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_1 = "EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_1",
  EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_2 = "EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_2",
  EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_3 = "EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_3",
  EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_4 = "EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_4",
  EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_5 = "EKYC_ID_PHOTO_TYPE_VIDEO_CUT_FRAME_5",
  EKYC_ID_PHOTO_TYPE_PASSPORT_FACE_PHOTO_PAGE = "EKYC_ID_PHOTO_TYPE_PASSPORT_FACE_PHOTO_PAGE",
  EKYC_ID_PHOTO_TYPE_PASSPORT_INFORMATION_ON_BEARER_PAGE = "EKYC_ID_PHOTO_TYPE_PASSPORT_INFORMATION_ON_BEARER_PAGE",
  EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_1 = "EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_1",
  EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_2 = "EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_2",
  EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_3 = "EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_3",
  EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_4 = "EKYC_ID_PHOTO_TYPE_PASSPORT_FRONT_COVER_4",
  EKYC_ID_PHOTO_TYPE_PASSPORT_FACE_PHOTO_PAGE_OUTER_CAMERA = "EKYC_ID_PHOTO_TYPE_PASSPORT_FACE_PHOTO_PAGE_OUTER_CAMERA",
  EKYC_ID_PHOTO_TYPE_PASSPORT_INFORMATION_ON_BEARER_PAGE_OUTER_CAMERA = "EKYC_ID_PHOTO_TYPE_PASSPORT_INFORMATION_ON_BEARER_PAGE_OUTER_CAMERA"
}

export enum IDType {
  ID_TYPE_NONE = "ID_TYPE_NONE",
  ID_TYPE_DRIVERS_LICENSE = "ID_TYPE_DRIVERS_LICENSE",
  ID_TYPE_PASSPORT = "ID_TYPE_PASSPORT",
  ID_TYPE_JUKI_CARD = "ID_TYPE_JUKI_CARD",
  ID_TYPE_HEALTH_INSURANCE_CARD = "ID_TYPE_HEALTH_INSURANCE_CARD",
  ID_TYPE_HEALTH_INSURANCE_PAPER_CARD = "ID_TYPE_HEALTH_INSURANCE_PAPER_CARD",
  ID_TYPE_PENSION_BOOK = "ID_TYPE_PENSION_BOOK",
  ID_TYPE_WELFARE_NOTEBOOK = "ID_TYPE_WELFARE_NOTEBOOK",
  ID_TYPE_SEAL_REGISTRATION_CERTIFICATE = "ID_TYPE_SEAL_REGISTRATION_CERTIFICATE",
  ID_TYPE_RESIDENCE_CARD = "ID_TYPE_RESIDENCE_CARD",
  ID_TYPE_PERMANENT_RESIDENT_CERTIFICATE = "ID_TYPE_PERMANENT_RESIDENT_CERTIFICATE",
  ID_TYPE_RESIDENCE_CERTIFICATE = "ID_TYPE_RESIDENCE_CERTIFICATE",
  ID_TYPE_MY_NUMBER_CARD = "ID_TYPE_MY_NUMBER_CARD"
}

export enum Trilean {
  TRILEAN_UNKNOWN = "TRILEAN_UNKNOWN",
  TRILEAN_TRUE = "TRILEAN_TRUE",
  TRILEAN_FALSE = "TRILEAN_FALSE"
}

export enum Status {
  NOT_AUDITED = "NOT_AUDITED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED"
}

export enum AuditState {
  NOT_REQUESTED = "NOT_REQUESTED",
  REQUESTED = "REQUESTED",
  COMPLETED = "COMPLETED",
  UPDATED = "UPDATED"
}

export enum KYCNotificationGroupType {
  KYC_NOTIFICATION_GROUP_TYPE_UNSPECIFIED = "KYC_NOTIFICATION_GROUP_TYPE_UNSPECIFIED",
  KYC_NOTIFICATION_GROUP_TYPE_MERPAY = "KYC_NOTIFICATION_GROUP_TYPE_MERPAY",
  KYC_NOTIFICATION_GROUP_TYPE_MERCOIN = "KYC_NOTIFICATION_GROUP_TYPE_MERCOIN"
}

export enum Nationality {
  NATIONALITY_UNSPECIFIED = "NATIONALITY_UNSPECIFIED",
  NATIONALITY_JAPAN = "NATIONALITY_JAPAN",
  NATIONALITY_AFGHANISTAN = "NATIONALITY_AFGHANISTAN",
  NATIONALITY_ALBANIA = "NATIONALITY_ALBANIA",
  NATIONALITY_ALGERIA = "NATIONALITY_ALGERIA",
  NATIONALITY_ANDORRA = "NATIONALITY_ANDORRA",
  NATIONALITY_ANGOLA = "NATIONALITY_ANGOLA",
  NATIONALITY_ANTIGUA_AND_BARBUDA = "NATIONALITY_ANTIGUA_AND_BARBUDA",
  NATIONALITY_ARGENTINA = "NATIONALITY_ARGENTINA",
  NATIONALITY_ARMENIA = "NATIONALITY_ARMENIA",
  NATIONALITY_AUSTRALIA = "NATIONALITY_AUSTRALIA",
  NATIONALITY_AUSTRIA = "NATIONALITY_AUSTRIA",
  NATIONALITY_AZERBAIJAN = "NATIONALITY_AZERBAIJAN",
  NATIONALITY_BAHAMAS = "NATIONALITY_BAHAMAS",
  NATIONALITY_BAHRAIN = "NATIONALITY_BAHRAIN",
  NATIONALITY_BANGLADESH = "NATIONALITY_BANGLADESH",
  NATIONALITY_BARBADOS = "NATIONALITY_BARBADOS",
  NATIONALITY_BELARUS = "NATIONALITY_BELARUS",
  NATIONALITY_BELGIUM = "NATIONALITY_BELGIUM",
  NATIONALITY_BELIZE = "NATIONALITY_BELIZE",
  NATIONALITY_BENIN = "NATIONALITY_BENIN",
  NATIONALITY_BHUTAN = "NATIONALITY_BHUTAN",
  NATIONALITY_BOLIVIA = "NATIONALITY_BOLIVIA",
  NATIONALITY_BOSNIA_AND_HERZEGOVINA = "NATIONALITY_BOSNIA_AND_HERZEGOVINA",
  NATIONALITY_BOTSWANA = "NATIONALITY_BOTSWANA",
  NATIONALITY_BRAZIL = "NATIONALITY_BRAZIL",
  NATIONALITY_BRUNEI_DARUSSALAM = "NATIONALITY_BRUNEI_DARUSSALAM",
  NATIONALITY_BULGARIA = "NATIONALITY_BULGARIA",
  NATIONALITY_BURKINA_FASO = "NATIONALITY_BURKINA_FASO",
  NATIONALITY_BURUNDI = "NATIONALITY_BURUNDI",
  NATIONALITY_CABO_VERDE = "NATIONALITY_CABO_VERDE",
  NATIONALITY_CAMBODIA = "NATIONALITY_CAMBODIA",
  NATIONALITY_CAMEROON = "NATIONALITY_CAMEROON",
  NATIONALITY_CANADA = "NATIONALITY_CANADA",
  NATIONALITY_CENTRAL_AFRICAN_REPUBLIC = "NATIONALITY_CENTRAL_AFRICAN_REPUBLIC",
  NATIONALITY_CHAD = "NATIONALITY_CHAD",
  NATIONALITY_CHILE = "NATIONALITY_CHILE",
  NATIONALITY_CHINA = "NATIONALITY_CHINA",
  NATIONALITY_COLOMBIA = "NATIONALITY_COLOMBIA",
  NATIONALITY_COMOROS = "NATIONALITY_COMOROS",
  NATIONALITY_CONGO = "NATIONALITY_CONGO",
  NATIONALITY_COOK_ISLANDS = "NATIONALITY_COOK_ISLANDS",
  NATIONALITY_COSTA_RICA = "NATIONALITY_COSTA_RICA",
  NATIONALITY_COTE_D_IVOIRE = "NATIONALITY_COTE_D_IVOIRE",
  NATIONALITY_CROATIA = "NATIONALITY_CROATIA",
  NATIONALITY_CUBA = "NATIONALITY_CUBA",
  NATIONALITY_CYPRUS = "NATIONALITY_CYPRUS",
  NATIONALITY_CZECHIA = "NATIONALITY_CZECHIA",
  NATIONALITY_DEMOCRATIC_REPUBLIC_OF_CONGO = "NATIONALITY_DEMOCRATIC_REPUBLIC_OF_CONGO",
  NATIONALITY_DENMARK = "NATIONALITY_DENMARK",
  NATIONALITY_DJIBOUTI = "NATIONALITY_DJIBOUTI",
  NATIONALITY_DOMINICA = "NATIONALITY_DOMINICA",
  NATIONALITY_DOMINICAN_REPUBLIC = "NATIONALITY_DOMINICAN_REPUBLIC",
  NATIONALITY_ECUADOR = "NATIONALITY_ECUADOR",
  NATIONALITY_EGYPT = "NATIONALITY_EGYPT",
  NATIONALITY_EL_SALVADOR = "NATIONALITY_EL_SALVADOR",
  NATIONALITY_EQUATORIAL_GUINEA = "NATIONALITY_EQUATORIAL_GUINEA",
  NATIONALITY_ERITREA = "NATIONALITY_ERITREA",
  NATIONALITY_ESTONIA = "NATIONALITY_ESTONIA",
  NATIONALITY_ESWATINI = "NATIONALITY_ESWATINI",
  NATIONALITY_ETHIOPIA = "NATIONALITY_ETHIOPIA",
  NATIONALITY_FIJI = "NATIONALITY_FIJI",
  NATIONALITY_FINLAND = "NATIONALITY_FINLAND",
  NATIONALITY_FRANCE = "NATIONALITY_FRANCE",
  NATIONALITY_GABON = "NATIONALITY_GABON",
  NATIONALITY_GAMBIA = "NATIONALITY_GAMBIA",
  NATIONALITY_GEORGIA = "NATIONALITY_GEORGIA",
  NATIONALITY_GERMANY = "NATIONALITY_GERMANY",
  NATIONALITY_GHANA = "NATIONALITY_GHANA",
  NATIONALITY_GREECE = "NATIONALITY_GREECE",
  NATIONALITY_GRENADA = "NATIONALITY_GRENADA",
  NATIONALITY_GUATEMALA = "NATIONALITY_GUATEMALA",
  NATIONALITY_GUINEA = "NATIONALITY_GUINEA",
  NATIONALITY_GUINEA_BISSAU = "NATIONALITY_GUINEA_BISSAU",
  NATIONALITY_GUYANA = "NATIONALITY_GUYANA",
  NATIONALITY_HAITI = "NATIONALITY_HAITI",
  NATIONALITY_HOLY_SEE = "NATIONALITY_HOLY_SEE",
  NATIONALITY_HONDURAS = "NATIONALITY_HONDURAS",
  NATIONALITY_HUNGARY = "NATIONALITY_HUNGARY",
  NATIONALITY_ICELAND = "NATIONALITY_ICELAND",
  NATIONALITY_INDIA = "NATIONALITY_INDIA",
  NATIONALITY_INDONESIA = "NATIONALITY_INDONESIA",
  NATIONALITY_IRAN = "NATIONALITY_IRAN",
  NATIONALITY_IRAQ = "NATIONALITY_IRAQ",
  NATIONALITY_IRELAND = "NATIONALITY_IRELAND",
  NATIONALITY_ISRAEL = "NATIONALITY_ISRAEL",
  NATIONALITY_ITALY = "NATIONALITY_ITALY",
  NATIONALITY_JAMAICA = "NATIONALITY_JAMAICA",
  NATIONALITY_JORDAN = "NATIONALITY_JORDAN",
  NATIONALITY_KAZAKHSTAN = "NATIONALITY_KAZAKHSTAN",
  NATIONALITY_KENYA = "NATIONALITY_KENYA",
  NATIONALITY_KIRIBATI = "NATIONALITY_KIRIBATI",
  NATIONALITY_KUWAIT = "NATIONALITY_KUWAIT",
  NATIONALITY_KYRGYZSTAN = "NATIONALITY_KYRGYZSTAN",
  NATIONALITY_LAO_PEOPLES_DEMOCRATIC_REPUBLIC = "NATIONALITY_LAO_PEOPLES_DEMOCRATIC_REPUBLIC",
  NATIONALITY_LATVIA = "NATIONALITY_LATVIA",
  NATIONALITY_LEBANON = "NATIONALITY_LEBANON",
  NATIONALITY_LESOTHO = "NATIONALITY_LESOTHO",
  NATIONALITY_LIBERIA = "NATIONALITY_LIBERIA",
  NATIONALITY_LIBYA = "NATIONALITY_LIBYA",
  NATIONALITY_LIECHTENSTEIN = "NATIONALITY_LIECHTENSTEIN",
  NATIONALITY_LITHUANIA = "NATIONALITY_LITHUANIA",
  NATIONALITY_LUXEMBOURG = "NATIONALITY_LUXEMBOURG",
  NATIONALITY_MACAO = "NATIONALITY_MACAO",
  NATIONALITY_MADAGASCAR = "NATIONALITY_MADAGASCAR",
  NATIONALITY_MALAWI = "NATIONALITY_MALAWI",
  NATIONALITY_MALAYSIA = "NATIONALITY_MALAYSIA",
  NATIONALITY_MALDIVES = "NATIONALITY_MALDIVES",
  NATIONALITY_MALI = "NATIONALITY_MALI",
  NATIONALITY_MALTA = "NATIONALITY_MALTA",
  NATIONALITY_MARSHALL_ISLANDS = "NATIONALITY_MARSHALL_ISLANDS",
  NATIONALITY_MAURITANIA = "NATIONALITY_MAURITANIA",
  NATIONALITY_MAURITIUS = "NATIONALITY_MAURITIUS",
  NATIONALITY_MEXICO = "NATIONALITY_MEXICO",
  NATIONALITY_FEDERATED_STATES_OF_MICRONESIA = "NATIONALITY_FEDERATED_STATES_OF_MICRONESIA",
  NATIONALITY_MONACO = "NATIONALITY_MONACO",
  NATIONALITY_MONGOLIA = "NATIONALITY_MONGOLIA",
  NATIONALITY_MONTENEGRO = "NATIONALITY_MONTENEGRO",
  NATIONALITY_MOROCCO = "NATIONALITY_MOROCCO",
  NATIONALITY_MOZAMBIQUE = "NATIONALITY_MOZAMBIQUE",
  NATIONALITY_MYANMAR = "NATIONALITY_MYANMAR",
  NATIONALITY_NAMIBIA = "NATIONALITY_NAMIBIA",
  NATIONALITY_NAURU = "NATIONALITY_NAURU",
  NATIONALITY_NEPAL = "NATIONALITY_NEPAL",
  NATIONALITY_NETHERLANDS = "NATIONALITY_NETHERLANDS",
  NATIONALITY_NEW_ZEALAND = "NATIONALITY_NEW_ZEALAND",
  NATIONALITY_NICARAGUA = "NATIONALITY_NICARAGUA",
  NATIONALITY_NIGER = "NATIONALITY_NIGER",
  NATIONALITY_NIGERIA = "NATIONALITY_NIGERIA",
  NATIONALITY_NIUE = "NATIONALITY_NIUE",
  NATIONALITY_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA = "NATIONALITY_DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA",
  NATIONALITY_NORWAY = "NATIONALITY_NORWAY",
  NATIONALITY_OMAN = "NATIONALITY_OMAN",
  NATIONALITY_PAKISTAN = "NATIONALITY_PAKISTAN",
  NATIONALITY_PALAU = "NATIONALITY_PALAU",
  NATIONALITY_PALESTINE = "NATIONALITY_PALESTINE",
  NATIONALITY_PANAMA = "NATIONALITY_PANAMA",
  NATIONALITY_PAPUA_NEW_GUINEA = "NATIONALITY_PAPUA_NEW_GUINEA",
  NATIONALITY_PARAGUAY = "NATIONALITY_PARAGUAY",
  NATIONALITY_PERU = "NATIONALITY_PERU",
  NATIONALITY_PHILIPPINES = "NATIONALITY_PHILIPPINES",
  NATIONALITY_POLAND = "NATIONALITY_POLAND",
  NATIONALITY_PORTUGAL = "NATIONALITY_PORTUGAL",
  NATIONALITY_QATAR = "NATIONALITY_QATAR",
  NATIONALITY_REPUBLIC_OF_KOREA = "NATIONALITY_REPUBLIC_OF_KOREA",
  NATIONALITY_REPUBLIC_OF_KOSOVO = "NATIONALITY_REPUBLIC_OF_KOSOVO",
  NATIONALITY_REPUBLIC_OF_MOLDOVA = "NATIONALITY_REPUBLIC_OF_MOLDOVA",
  NATIONALITY_REPUBLIC_OF_NORTH_MACEDONIA = "NATIONALITY_REPUBLIC_OF_NORTH_MACEDONIA",
  NATIONALITY_REPUBLIC_OF_SERBIA = "NATIONALITY_REPUBLIC_OF_SERBIA",
  NATIONALITY_ROMANIA = "NATIONALITY_ROMANIA",
  NATIONALITY_RUSSIAN_FEDERATION = "NATIONALITY_RUSSIAN_FEDERATION",
  NATIONALITY_RWANDA = "NATIONALITY_RWANDA",
  NATIONALITY_SAINT_KITTS_AND_NEVIS = "NATIONALITY_SAINT_KITTS_AND_NEVIS",
  NATIONALITY_SAINT_LUCIA = "NATIONALITY_SAINT_LUCIA",
  NATIONALITY_SAINT_VINCENT_AND_THE_GRENADINES = "NATIONALITY_SAINT_VINCENT_AND_THE_GRENADINES",
  NATIONALITY_SAMOA = "NATIONALITY_SAMOA",
  NATIONALITY_SAN_MARINO = "NATIONALITY_SAN_MARINO",
  NATIONALITY_SAO_TOME_AND_PRINCIPE = "NATIONALITY_SAO_TOME_AND_PRINCIPE",
  NATIONALITY_SAUDI_ARABIA = "NATIONALITY_SAUDI_ARABIA",
  NATIONALITY_SENEGAL = "NATIONALITY_SENEGAL",
  NATIONALITY_SEYCHELLES = "NATIONALITY_SEYCHELLES",
  NATIONALITY_SIERRA_LEONE = "NATIONALITY_SIERRA_LEONE",
  NATIONALITY_SINGAPORE = "NATIONALITY_SINGAPORE",
  NATIONALITY_SLOVAKIA = "NATIONALITY_SLOVAKIA",
  NATIONALITY_SLOVENIA = "NATIONALITY_SLOVENIA",
  NATIONALITY_SOLOMON_ISLANDS = "NATIONALITY_SOLOMON_ISLANDS",
  NATIONALITY_SOMALIA = "NATIONALITY_SOMALIA",
  NATIONALITY_SOUTH_AFRICA = "NATIONALITY_SOUTH_AFRICA",
  NATIONALITY_SOUTH_SUDAN = "NATIONALITY_SOUTH_SUDAN",
  NATIONALITY_SPAIN = "NATIONALITY_SPAIN",
  NATIONALITY_SRI_LANKA = "NATIONALITY_SRI_LANKA",
  NATIONALITY_SUDAN = "NATIONALITY_SUDAN",
  NATIONALITY_SURINAME = "NATIONALITY_SURINAME",
  NATIONALITY_SWEDEN = "NATIONALITY_SWEDEN",
  NATIONALITY_SWITZERLAND = "NATIONALITY_SWITZERLAND",
  NATIONALITY_SYRIAN_ARAB_REPUBLIC = "NATIONALITY_SYRIAN_ARAB_REPUBLIC",
  NATIONALITY_TAIWAN = "NATIONALITY_TAIWAN",
  NATIONALITY_TAJIKISTAN = "NATIONALITY_TAJIKISTAN",
  NATIONALITY_THAILAND = "NATIONALITY_THAILAND",
  NATIONALITY_TIMOR_LESTE = "NATIONALITY_TIMOR_LESTE",
  NATIONALITY_TOGO = "NATIONALITY_TOGO",
  NATIONALITY_TONGA = "NATIONALITY_TONGA",
  NATIONALITY_TRINIDAD_AND_TOBAGO = "NATIONALITY_TRINIDAD_AND_TOBAGO",
  NATIONALITY_TUNISIA = "NATIONALITY_TUNISIA",
  NATIONALITY_TURKEY = "NATIONALITY_TURKEY",
  NATIONALITY_TURKMENISTAN = "NATIONALITY_TURKMENISTAN",
  NATIONALITY_TUVALU = "NATIONALITY_TUVALU",
  NATIONALITY_UGANDA = "NATIONALITY_UGANDA",
  NATIONALITY_UKRAINE = "NATIONALITY_UKRAINE",
  NATIONALITY_UNITED_ARAB_EMIRATES = "NATIONALITY_UNITED_ARAB_EMIRATES",
  NATIONALITY_UNITED_KINGDOM = "NATIONALITY_UNITED_KINGDOM",
  NATIONALITY_UNITED_REPUBLIC_OF_TANZANIA = "NATIONALITY_UNITED_REPUBLIC_OF_TANZANIA",
  NATIONALITY_UNITED_STATES_OF_AMERICA = "NATIONALITY_UNITED_STATES_OF_AMERICA",
  NATIONALITY_URUGUAY = "NATIONALITY_URUGUAY",
  NATIONALITY_UZBEKISTAN = "NATIONALITY_UZBEKISTAN",
  NATIONALITY_VANUATU = "NATIONALITY_VANUATU",
  NATIONALITY_VENEZUELA = "NATIONALITY_VENEZUELA",
  NATIONALITY_VIETNAM = "NATIONALITY_VIETNAM",
  NATIONALITY_YEMEN = "NATIONALITY_YEMEN",
  NATIONALITY_ZAMBIA = "NATIONALITY_ZAMBIA",
  NATIONALITY_ZIMBABWE = "NATIONALITY_ZIMBABWE"
}

export enum VisaType {
  VISA_TYPE_UNSPECIFIED = "VISA_TYPE_UNSPECIFIED",
  VISA_TYPE_ARTIST = "VISA_TYPE_ARTIST",
  VISA_TYPE_BUSINESS_MANAGER = "VISA_TYPE_BUSINESS_MANAGER",
  VISA_TYPE_CULTURAL_ACTIVITIES = "VISA_TYPE_CULTURAL_ACTIVITIES",
  VISA_TYPE_DEPENDENT = "VISA_TYPE_DEPENDENT",
  VISA_TYPE_DESIGNATED_ACTIVITIES = "VISA_TYPE_DESIGNATED_ACTIVITIES",
  VISA_TYPE_DIPLOMAT = "VISA_TYPE_DIPLOMAT",
  VISA_TYPE_ENGINEER_SPECIALIST_IN_HUMANITIES_INTERNATIONAL_SERVICES = "VISA_TYPE_ENGINEER_SPECIALIST_IN_HUMANITIES_INTERNATIONAL_SERVICES",
  VISA_TYPE_ENTERTAINER = "VISA_TYPE_ENTERTAINER",
  VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_I_A = "VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_I_A",
  VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_I_B = "VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_I_B",
  VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_I_C = "VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_I_C",
  VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_II = "VISA_TYPE_HIGHLY_SKILLED_PROFESSIONAL_II",
  VISA_TYPE_INSTRUCTOR = "VISA_TYPE_INSTRUCTOR",
  VISA_TYPE_INTRA_COMPANY_TRANSFEREE = "VISA_TYPE_INTRA_COMPANY_TRANSFEREE",
  VISA_TYPE_JOURNALIST = "VISA_TYPE_JOURNALIST",
  VISA_TYPE_LEGAL_ACCOUNTING_SERVICES = "VISA_TYPE_LEGAL_ACCOUNTING_SERVICES",
  VISA_TYPE_LONG_TERM_RESIDENT = "VISA_TYPE_LONG_TERM_RESIDENT",
  VISA_TYPE_MEDICAL_SERVICES = "VISA_TYPE_MEDICAL_SERVICES",
  VISA_TYPE_NURSING_CARE = "VISA_TYPE_NURSING_CARE",
  VISA_TYPE_OFFICIAL = "VISA_TYPE_OFFICIAL",
  VISA_TYPE_PERMANENT_RESIDENT = "VISA_TYPE_PERMANENT_RESIDENT",
  VISA_TYPE_PROFESSOR = "VISA_TYPE_PROFESSOR",
  VISA_TYPE_RELIGIOUS_ACTIVITIES = "VISA_TYPE_RELIGIOUS_ACTIVITIES",
  VISA_TYPE_RESEARCHER = "VISA_TYPE_RESEARCHER",
  VISA_TYPE_SKILLED_LABOR = "VISA_TYPE_SKILLED_LABOR",
  VISA_TYPE_SPECIAL_PERMANENT_RESIDENT = "VISA_TYPE_SPECIAL_PERMANENT_RESIDENT",
  VISA_TYPE_SPECIFIED_SKILLED_WORKER_I = "VISA_TYPE_SPECIFIED_SKILLED_WORKER_I",
  VISA_TYPE_SPECIFIED_SKILLED_WORKER_II = "VISA_TYPE_SPECIFIED_SKILLED_WORKER_II",
  VISA_TYPE_SPOUSE_OR_CHILD_OF_JAPANESE_NATIONAL = "VISA_TYPE_SPOUSE_OR_CHILD_OF_JAPANESE_NATIONAL",
  VISA_TYPE_SPOUSE_OR_CHILD_OF_PERMANENT_RESIDENT = "VISA_TYPE_SPOUSE_OR_CHILD_OF_PERMANENT_RESIDENT",
  VISA_TYPE_STUDENT = "VISA_TYPE_STUDENT",
  VISA_TYPE_TECHNICAL_INTERN_TRAINING_I_A = "VISA_TYPE_TECHNICAL_INTERN_TRAINING_I_A",
  VISA_TYPE_TECHNICAL_INTERN_TRAINING_I_B = "VISA_TYPE_TECHNICAL_INTERN_TRAINING_I_B",
  VISA_TYPE_TECHNICAL_INTERN_TRAINING_II_A = "VISA_TYPE_TECHNICAL_INTERN_TRAINING_II_A",
  VISA_TYPE_TECHNICAL_INTERN_TRAINING_II_B = "VISA_TYPE_TECHNICAL_INTERN_TRAINING_II_B",
  VISA_TYPE_TECHNICAL_INTERN_TRAINING_III_A = "VISA_TYPE_TECHNICAL_INTERN_TRAINING_III_A",
  VISA_TYPE_TECHNICAL_INTERN_TRAINING_III_B = "VISA_TYPE_TECHNICAL_INTERN_TRAINING_III_B",
  VISA_TYPE_TRAINEE = "VISA_TYPE_TRAINEE"
}

export enum SupplementaryDocumentType {
  SUPPLEMENTARY_DOCUMENT_TYPE_NONE = "SUPPLEMENTARY_DOCUMENT_TYPE_NONE",
  SUPPLEMENTARY_DOCUMENT_TYPE_UTILITY_RECEIPT = "SUPPLEMENTARY_DOCUMENT_TYPE_UTILITY_RECEIPT",
  SUPPLEMENTARY_DOCUMENT_TYPE_TAX_RECEIPT = "SUPPLEMENTARY_DOCUMENT_TYPE_TAX_RECEIPT",
  SUPPLEMENTARY_DOCUMENT_TYPE_TAX_CERTIFICATE = "SUPPLEMENTARY_DOCUMENT_TYPE_TAX_CERTIFICATE",
  SUPPLEMENTARY_DOCUMENT_TYPE_SOCIAL_INSURANCE_RECEIPT = "SUPPLEMENTARY_DOCUMENT_TYPE_SOCIAL_INSURANCE_RECEIPT",
  SUPPLEMENTARY_DOCUMENT_TYPE_INVALID = "SUPPLEMENTARY_DOCUMENT_TYPE_INVALID"
}
