import Bridge from 'mewkit-native-bridge';

export interface CouponDeviceInfo {
  deviceSupportsNfc: boolean;
}

export interface InitCouponResponse {
  deviceInfo: CouponDeviceInfo;
}

export interface GetProvisioningStatusResponse {
  success: boolean;
  isNfcMaintenance?: boolean;
}

export interface DefaultResponse {
  success: boolean;
}

export class CouponBridge extends Bridge {
  public notSupportedDevice = false;

  public navigateTo(url: string) {
    const m = url.match(/\/\/[^/]+(\/.*)/);
    if (m) {
      location.href = m[1];
    }
  }

  public recharge(): Promise<DefaultResponse> {
    return this.request('recharge', {});
  }

  public buyPoints(pointsToBuy: number, sales: number, point: number): Promise<DefaultResponse> {
    return this.request('buyPoints', {
      pointsToBuy,
      sales,
      point,
    });
  }

  public connectBank(): Promise<DefaultResponse> {
    return this.request('connectBank', {});
  }

  public initCoupon(
    itemId: string | null,
    expiresAt: number | null,
    featureType: string | null,
    shareText: string,
  ): Promise<InitCouponResponse> {
    if (this.notSupportedDevice) {
      return Promise.resolve({ deviceInfo: { deviceSupportsNfc: false } });
    }

    return this.request('initCoupon', {
      itemId,
      expiresAt,
      featureType,
      shareText,
    });
  }

  public openCodePay(): Promise<DefaultResponse> {
    return this.request('openCodePay', {});
  }

  public openPay(lastFourOfFpan: string): Promise<DefaultResponse> {
    return this.request('openPay', { lastFourOfFpan });
  }

  public openUrl(url: string): Promise<DefaultResponse> {
    return this.request('openUrl', {
      url,
    });
  }

  public getProvisioningStatus(lastFourOfFPAN: string): Promise<GetProvisioningStatusResponse> {
    return this.request('getProvisioningStatus', {
      lastFourOfFPAN,
    });
  }

  public sendEventLog(
    category: string,
    eventId: string,
    property?: { [key: string]: any },
    value?: number,
  ): Promise<DefaultResponse> {
    if (this.notSupportedDevice) {
      return Promise.resolve({ success: false });
    }

    return this.request('sendEventLog', {
      category,
      eventId,
      value,
      property,
    });
  }

  public setupMercariPay(lastFourOfFpan: string): Promise<DefaultResponse> {
    return this.request('setupMercariPay', { lastFourOfFpan });
  }

  public push(url: string): Promise<void> {
    if (this.platform() === 'desktop' || this.notSupportedDevice) {
      location.href = url;
      return Promise.resolve();
    }

    const a = document.createElement('a');
    a.href = url;
    return super.push(a.href);
  }
}

export class CouponBridgeImplementation {
  public initCoupon(): Promise<InitCouponResponse> {
    return Promise.resolve({
      deviceInfo: {
        deviceSupportsNfc: false,
      },
    });
  }

  public getProvisioningStatus(): Promise<GetProvisioningStatusResponse> {
    return Promise.resolve({
      success: true,
      isNfcMaintenance: false,
    });
  }

  public setupMercariPay(): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }

  public openPay(): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }

  public openCodePay(): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }

  public openUrl(args: { [key: string]: any }): Promise<DefaultResponse> {
    window.open(args.url, '_blank');
    return Promise.resolve({
      success: true,
    });
  }

  public recharge(): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }

  public buyPoints(): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }

  public connectBank(): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }

  public sendEventLog(_: { [key: string]: any }): Promise<DefaultResponse> {
    return Promise.resolve({
      success: true,
    });
  }
}
