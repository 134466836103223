import { DateTime } from 'luxon';
import {
  BrandForCoupon,
  Coupon,
  CouponPaymentType,
  LotteryCouponStatus,
  PaymentStatus,
  UserPaymentConfig,
} from '~/types/gen/mercari/platform/proto/microservices/merpay-api-jp/api/v1/api_pb';
import { CouponDeviceInfo } from '~/plugins/native-bridge/CouponBridge';

export enum LotteryState {
  NonLottery,
  Drawable,
  Win,
  Retriable,
  Expired,
}

export function isCovered(coupon: Coupon): boolean {
  return Boolean(coupon.invalidationConditionTime);
}

export function isIssued(coupon: Coupon): boolean {
  let result = false;

  const { invalidationConditionTime, invalidationConditionPointback, isPointback } = coupon;

  if (isPointback && invalidationConditionPointback) {
    const { hasEntry, isUpperLimit } = invalidationConditionPointback;

    if (hasEntry && !isUpperLimit) {
      result = true;
    }
  } else if (invalidationConditionTime) {
    const { expireTime, remainingDuration } = invalidationConditionTime;

    if (expireTime !== null && remainingDuration !== null) {
      result = true;
    }
  }

  return result;
}

export function isReissued(coupon: Coupon): boolean {
  const { invalidationConditionTime } = coupon;

  if (invalidationConditionTime) {
    const { expireTime, remainingDuration } = invalidationConditionTime;

    if (expireTime === null && remainingDuration !== null) {
      // already used, but reissued by CS
      return true;
    }
  }

  return false;
}

export function isExpired(coupon: Coupon, currentTime: number): boolean {
  const { invalidationConditionTime } = coupon;

  /* eslint-disable no-else-return */
  if (invalidationConditionTime) {
    // will be issued or already issued
    const { expireTime, remainingDuration } = invalidationConditionTime;

    if (remainingDuration === '0s') {
      // if remainingDuration is '0s', already expired
      return true;
    } else if (remainingDuration !== null && expireTime === null) {
      // if expireTime is not null, reissued
      return false;
    }

    if (expireTime && Date.parse(expireTime) < currentTime) {
      // if expireTime exists, already issued
      // if now is after expireTime, already expired
      return true;
    }
  }
  /* eslint-enable */

  return false;
}

/**
 * Check pointBack coupon is has been used or not.
 */
export function isPointBackUpperLimit(coupon: Coupon): boolean {
  const isUpperLimit = coupon.invalidationConditionPointback?.isUpperLimit || false;
  return isPointBack(coupon) && isUpperLimit;
}

/**
 * Check netpayment coupon is has been used or not.
 */
export function isNetpaymentUpperLimit(coupon: Coupon): boolean {
  const isUpperLimit = coupon.invalidationConditionPointback?.isUpperLimit || false;
  return isNetpayment(coupon) && isUpperLimit;
}

export function hasTimeLimit(coupon: Coupon): boolean {
  return Boolean(coupon.invalidationConditionTime);
}

export function isDuringTheTerm({ startTime, endTime }: Coupon, currentTime: number): boolean {
  const start = Date.parse(startTime || '');
  const end = Date.parse(endTime || '');

  if (start <= currentTime && currentTime < end) {
    return true;
  }

  return false;
}

export function isDisabled(coupon: Coupon, deviceInfo: CouponDeviceInfo, currentTime: number): boolean {
  if (!isDuringTheTerm(coupon, currentTime)) {
    return true;
  }

  // if expired
  if (isExpired(coupon, currentTime)) {
    return true;
  }

  // if NFC is not supported and this coupon is nazca only
  if (!deviceInfo.deviceSupportsNfc && isNazcaOnly(coupon)) {
    return true;
  }

  // if reached to usage limit and not reissued by CS
  if (coupon.isLimitReached && !isIssued(coupon) && !isReissued(coupon)) {
    return true;
  }

  return false;
}

export function isCodepaySupported(coupon: Coupon): boolean {
  const { paymentTypes } = coupon;

  if (!Array.isArray(paymentTypes)) {
    return false;
  }

  return paymentTypes.includes(CouponPaymentType.PAYMENT_TYPE_CODE);
}

export function isNazcaSupported(coupon: Coupon): boolean {
  const { paymentTypes } = coupon;

  if (!Array.isArray(paymentTypes)) {
    return false;
  }

  return paymentTypes.includes(CouponPaymentType.PAYMENT_TYPE_NAZCA);
}

export function isNazcaOnly(coupon: Coupon): boolean {
  return isNazcaSupported(coupon) && !isCodepaySupported(coupon);
}

export function isNetpayment(coupon: Coupon): boolean {
  const { paymentTypes } = coupon;

  if (!Array.isArray(paymentTypes)) {
    return false;
  }

  return paymentTypes.includes(CouponPaymentType.PAYMENT_TYPE_NETPAYMENT);
}

export function isPremium(coupon: Coupon): boolean {
  return coupon.featureType === Coupon.FeatureType.FEATURE_TYPE_PREMIUM;
}

export function isStandard(coupon: Coupon): boolean {
  return coupon.featureType === Coupon.FeatureType.FEATURE_TYPE_STANDARD;
}

export function isPointBack(coupon: Coupon): boolean {
  return coupon.isPointback || false;
}

/**
 * Check coupon is available or not.
 */
export function isAvailable(coupon: Coupon, deviceInfo: CouponDeviceInfo): boolean {
  return !isUnavailable(coupon, deviceInfo);
}

export function isAvailableNotDeviceInfo(coupon: Coupon): boolean {
  return !isUnavailable(coupon);
}

/**
 * Check coupon is unavailable or not.
 */
export function isUnavailable(coupon: Coupon, deviceInfo?: CouponDeviceInfo): boolean {
  /* eslint-disable no-else-return */
  if (coupon.isLimitReached && (!isIssued(coupon) || !isReissued(coupon))) {
    if (coupon.isLottery) {
      if (coupon.lotteryStatus && coupon.lotteryStatus.isWin && !isExpired(coupon, Date.now())) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  /* eslint-enable */

  if (isPointBackUpperLimit(coupon)) {
    return true;
  }

  if (isNetpaymentUpperLimit(coupon)) {
    return true;
  }

  if (isExpired(coupon, Date.now())) {
    return true;
  }

  const lotteryState = getLotteryState(coupon, Date.now());
  if (lotteryState === LotteryState.Expired) {
    return true;
  }

  if (deviceInfo && !deviceInfo.deviceSupportsNfc && isNazcaOnly(coupon)) {
    return true;
  }

  return false;
}

export function isProvisioningRequired(
  coupon: Coupon,
  isNeverProvisioned: boolean,
  isDeviceProvisioned: boolean,
  isAndroid: boolean,
  isIos: boolean,
): boolean {
  if (skipCheckingProvisioningStatus(coupon)) {
    return false;
  }

  const codepaySupported = isCodepaySupported(coupon);
  const nazcaSupported = isNazcaSupported(coupon);

  /* eslint-disable no-else-return */
  if (codepaySupported && !nazcaSupported) {
    // if only codepay is supported on selected coupon
    return false;
  } else if (!codepaySupported && nazcaSupported) {
    // if only nazca is supported on selected coupon
    if (isAndroid) {
      if (isNeverProvisioned) {
        // Provisioning is required if never provisioned
        return true;
      } else {
        // Check again on clicking Pay button if ever provisioned
        // so provisioning is not required
        return false;
      }
    } else if (isIos) {
      if (isNeverProvisioned) {
        // Provisioning is required if never provisioned
        return true;
      }

      if (!isDeviceProvisioned) {
        // Provisioning is not required if already provisioned
        return true;
      }
    } else {
      return true;
    }
  } else if (codepaySupported && nazcaSupported) {
    // If codepay and nazca are supported on selected coupon
    // codepay will be selected if not provisioned yet
    return false;
  }
  /* eslint-enable */

  return false;
}

// Whether it is an SP limited coupon
export function isSpLimitedCoupon(coupon: Coupon): boolean {
  return coupon.isSmartpayLimited || false;
}

// Whether SP has been set
export function isPostPaySetup(paymentStatus: PaymentStatus): boolean {
  return paymentStatus.isPostPaySetup || false;
}

// Whether SP mode is selected
export function isPaymentModePostPay(paymentStatus: PaymentStatus): boolean {
  return paymentStatus.postPayPaymentMode === UserPaymentConfig.PostPayPaymentMode.MODE_POST_PAY;
}

export function shouldSwitchSp(coupon: Coupon, paymentStatus: PaymentStatus): boolean {
  return isSpLimitedCoupon(coupon) && isPostPaySetup(paymentStatus) && !isPaymentModePostPay(paymentStatus);
}

export function isSpSettingRequired(coupon: Coupon, paymentStatus: PaymentStatus): boolean {
  return isSpLimitedCoupon(coupon) && !isPostPaySetup(paymentStatus);
}

export function getLotteryState(coupon: Coupon, currentTime: number): LotteryState {
  if (!coupon.isLottery) {
    return LotteryState.NonLottery;
  }

  const lotteryStatus = coupon.lotteryStatus as LotteryCouponStatus;

  if (lotteryStatus.isWin) {
    return LotteryState.Win;
  }

  const current = DateTime.fromMillis(currentTime);
  const lotteryStart = DateTime.fromISO(coupon.startTime || '');

  // endTime: 2019-09-29 23:59:59
  // lotteryEnd: 2019-09-28 23:59:59
  // lotteryEndBuffer: 2019-09-28 00:00:00
  const lotteryEnd = DateTime.fromISO(coupon.endTime || '').minus({
    days: 1,
  });
  // To omit hour and minute and second
  const lotteryEndOmitted = DateTime.fromObject({
    year: lotteryEnd.year,
    month: lotteryEnd.month,
    day: lotteryEnd.day,
  });

  if (lotteryStart <= current && current < lotteryEnd) {
    if (!lotteryStatus.isDrawn) {
      return LotteryState.Drawable;
    }
  }

  if (lotteryStart <= current && current < lotteryEndOmitted) {
    if (lotteryStatus.nextDrawableAt) {
      return LotteryState.Retriable;
    }
  }

  return LotteryState.Expired;
}

/**
 * Get rid of duplicate coupons.
 *
 * @param {Coupon[]} The source coupons
 * @param {Coupon[]} The compared coupons
 * @return Duplicate-free version of source coupons.
 */
export function uniqueCoupons(a: Coupon[], b: Coupon[]): Coupon[] {
  return a.filter((c: Coupon) => {
    let result = true;

    b.forEach((d: Coupon) => {
      if (c.id === d.id) {
        result = false;
      }
    });

    return result;
  });
}

/**
 * Check coupon is same brand.
 */
export function isSameBrand(coupon: Coupon, selectCoupon: Coupon): boolean {
  if (!coupon.couponBrands || coupon.couponBrands.length === 0) {
    return false;
  }
  if (!selectCoupon.couponBrands || selectCoupon.couponBrands.length === 0) {
    return false;
  }
  if (
    coupon.couponBrands[0].brand &&
    selectCoupon.couponBrands[0].brand &&
    coupon.couponBrands[0].brand.id === selectCoupon.couponBrands[0].brand.id
  ) {
    return true;
  }
  return false;
}

/**
 * Filter drawn coupons.
 */
export function filterDrawnCoupons(coupons: Coupon[], deviceInfo: CouponDeviceInfo, brand?: BrandForCoupon): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => {
      const lotteryState = getLotteryState(coupon, Date.now());

      if (lotteryState === LotteryState.Win) {
        return true;
      }

      return false;
    })
    .filter((coupon) => isAvailable(coupon, deviceInfo));
}

/**
 * Filter coupons that can be drawn.
 * The following conditions are met:
 *
 * * This coupon can be drawn.
 * * This coupon was drawn once, but can be drawn again.
 */
export function filterLotteryCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => {
      const lotteryState = getLotteryState(coupon, Date.now());

      if (lotteryState === LotteryState.Drawable) {
        return true;
      }

      if (lotteryState === LotteryState.Retriable) {
        return true;
      }

      return false;
    })
    .filter((coupon) => isAvailable(coupon, deviceInfo));
}

/**
 * Filter premium coupons.
 */
export function filterPremiumCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => !isNetpayment(coupon))
    .filter((coupon) => {
      return isPremium(coupon);
    })
    .filter((coupon) => !coupon.isLottery)
    .filter((coupon) => isAvailable(coupon, deviceInfo));
}

/**
 * Filter standard coupons.
 */
export function filterStandardCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => !isNetpayment(coupon))
    .filter((coupon) => isStandard(coupon))
    .filter((coupon) => !coupon.isLottery)
    .filter((coupon) => isAvailable(coupon, deviceInfo));
}

/**
 * Filter netpayment coupons.
 */
export function filterNetpaymentCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => isNetpayment(coupon))
    .filter((coupon) => !coupon.isLottery)
    .filter((coupon) => isAvailable(coupon, deviceInfo));
}

/**
 * Filter recommend coupons.
 */
export function filterRecommendCoupons(
  coupons: Coupon[],
  selectCoupon: Coupon,
  deviceInfo: CouponDeviceInfo,
): Coupon[] {
  return coupons
    .filter((coupon) => coupon.id !== selectCoupon.id)
    .filter((coupon) => !coupon.isLottery)
    .filter((coupon) => isSameBrand(coupon, selectCoupon))
    .filter((coupon) => isAvailable(coupon, deviceInfo));
}

/**
 * Filter recommend coupons not deviceInfo.
 */
export function filterRecommendCouponsNotDeviceInfo(coupons: Coupon[], selectCoupon: Coupon): Coupon[] {
  return coupons
    .filter((coupon) => coupon.id !== selectCoupon.id)
    .filter((coupon) => !coupon.isLottery)
    .filter((coupon) => isSameBrand(coupon, selectCoupon))
    .filter((coupon) => isAvailableNotDeviceInfo(coupon));
}

/**
 * Filter unavailable coupons.
 */
export function filterUnavailableCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => !isNetpayment(coupon))
    .filter((coupon) => !isStandard(coupon))
    .filter((coupon) => isUnavailable(coupon, deviceInfo));
}

/**
 * Filter unavailable standard coupons.
 */
export function filterUnavailableStandardCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons
    .filter((coupon) => !isNetpayment(coupon))
    .filter((coupon) => isStandard(coupon))
    .filter((coupon) => isUnavailable(coupon, deviceInfo));
}

/**
 * Filter unavailable netpayment coupons.
 */
export function filterUnavailableNetpaymentCoupons(
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  if (brand) {
    coupons = filterCouponsByBrand(coupons, brand);
  }

  return coupons.filter((coupon) => isNetpayment(coupon)).filter((coupon) => isUnavailable(coupon, deviceInfo));
}

export function isAllCouponsUnavailable(coupons: Coupon[], deviceInfo: CouponDeviceInfo): boolean {
  if (coupons.length === 0) {
    return false;
  }

  const items = coupons
    .filter((coupon) => isUnavailable(coupon, deviceInfo))
    .filter((coupon) => isDuringTheTerm(coupon, Date.now()));

  return items.length === coupons.length;
}

export function isAllCouponsExpired(coupons: Coupon[]): boolean {
  if (coupons.length === 0) {
    return false;
  }

  const items = coupons.filter((coupon) => isExpired(coupon, Date.now()));

  return items.length === coupons.length;
}

export enum CouponFilterType {
  Drawn,
  Lottery,
  Premium,
  Standard,
  Netpayment,
  Unavailable,
  UnavailableStandard,
  UnavailableNetpayment,
}

/**
 * Filter coupons by filterType.
 *
 * @param filterType
 * @param coupons
 * @param deviceInfo
 * @param brand - Used for brand filtering
 */
export function filterCoupons(
  filterType: CouponFilterType,
  coupons: Coupon[],
  deviceInfo: CouponDeviceInfo,
  brand?: BrandForCoupon,
): Coupon[] {
  switch (filterType) {
    case CouponFilterType.Drawn:
      return filterDrawnCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.Lottery:
      return filterLotteryCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.Premium:
      return filterPremiumCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.Standard:
      return filterStandardCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.Netpayment:
      return filterNetpaymentCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.Unavailable:
      return filterUnavailableCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.UnavailableStandard:
      return filterUnavailableStandardCoupons(coupons, deviceInfo, brand);
    case CouponFilterType.UnavailableNetpayment:
      return filterUnavailableNetpaymentCoupons(coupons, deviceInfo, brand);
  }
}

/**
 * Filter coupons by brand.
 *
 * @param coupons - Source list to filter
 * @param brand - The brand object to compare
 */
export function filterCouponsByBrand(coupons: Coupon[], brand: BrandForCoupon): Coupon[] {
  return coupons.filter((coupon) => {
    return coupon.couponBrands && coupon.couponBrands.length > 0 && coupon.couponBrands[0].brand?.id === brand.id;
  });
}

/**
 * Check the coupon list is only iD-only coupons, or not.
 *
 * @param coupons
 */
export function isUnavailableCoupons(coupons: Coupon[], deviceInfo: CouponDeviceInfo): boolean {
  return coupons.filter((coupon) => isUnavailable(coupon, deviceInfo)).length === coupons.length;
}

/**
 * Skip checking balance if specified coupons.
 *
 * @param coupon
 * @see https://mercari.slack.com/archives/CGRDFFJDA/p1601612120167800?thread_ts=1601612088.167700&cid=CGRDFFJDA
 */
export function skipCheckingBalance(coupon: Coupon): boolean {
  const materialCoupons = [
    '93854312-dd59-4fa3-a31b-780c25ac959e',
    'eadc27ba-cf94-450b-a30c-d45e906ce7ba',
    '36817077-9328-4dbe-8bde-689f6f182894',
    '21fcba39-dc61-4c2d-8982-8251dc0a3bfa',
    'b26a3897-4bd9-4e4f-9866-fb4defae7b85',
    '0c7baef9-b30c-4976-8977-90a9aec9ef50',
    'acdbe08e-9ee9-4069-9146-65cfd40c91ac',
    '0ba8ab37-b5df-4adb-b5ec-df3913fad8a4',
    'ed380b65-f218-4d48-9b21-8b0fe7b94952',
    '64e56adb-df87-4344-b9b5-ca8f3a2db948',
    'fbc0142b-00b7-414d-af59-b4672270a3de',
    '4d49a59f-bfbd-48a6-a6a0-66b857217fd1',
    'bd153dd2-949f-4392-9ffa-403bdb683537',
  ];

  return materialCoupons.includes(coupon.id || '');
}

/**
 * Skip checking provisioning status if specified coupons.
 *
 * @param coupon
 */
export function skipCheckingProvisioningStatus(coupon: Coupon): boolean {
  const coupons = ['b26a3897-4bd9-4e4f-9866-fb4defae7b85'];

  return coupons.includes(coupon.id || '');
}

/**
 * Check the brand ID passed by args is included in disable list, or not.
 *
 * @param {string | undefined} The brand ID
 * @see https://mercari.atlassian.net/wiki/x/LoM_Jg#id-[Spec]Coupon1.0-3.クーポン詳細
 */
export function isIncludedMapSearchDisableList(brandId?: string): boolean {
  if (!brandId) {
    return false;
  }

  const brandIds = [
    'e018c01a-f63e-44f3-9597-56888f1f4b4f', // りゅうせき
    'be17a25e-cb9d-4008-b418-8f0c5d10f1b7', // ドトール/エクセシオール
  ];

  return brandIds.includes(brandId);
}
