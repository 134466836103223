import { CouponBridge, CouponBridgeImplementation } from './native-bridge/CouponBridge';
import { PascalLog } from './native-bridge/PascalLog';
import { detectWebView } from '~/utils/webview';

const BRIDGE_NAMESPACE = 'MercariNativeBridge';
const CHROME_DESKTOP =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/73.0.3683.86 Safari/537.36';

export default defineNuxtPlugin((nuxtApp) => {
  const device = useDevice();

  const userAgent = detectWebView(device) ? undefined : CHROME_DESKTOP;
  const impl = new CouponBridgeImplementation();
  const couponBridge = new CouponBridge(BRIDGE_NAMESPACE, impl, userAgent);
  const pascalLog = new PascalLog(couponBridge);

  nuxtApp.vueApp.config.globalProperties.$couponBridge = couponBridge;
  nuxtApp.vueApp.config.globalProperties.$pascalLog = pascalLog;

  nuxtApp.provide('pascalLog', pascalLog);
});
