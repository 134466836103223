export enum EventId {
  DetailBankAnnounceModalDisplay = 'merpay_coupon_bank_announce_modal_display',
  DetailCodeDisplay = 'merpay_coupon_code_display',
  DetailCodeTurnOverDisplay = 'merpay_coupon_code_turn_over_display',
  DetailDisplay = 'merpay_coupon_detail_display',
  DetailFundsShortageModalDisplay = 'merpay_coupon_funds_shortage_modal_display',
  DetailLotteryWinningDisplay = 'merpay_coupon_lottery_detail_winning_display',
  DetailLotteryMissDisplay = 'merpay_coupon_lottery_detail_miss_display',
  DetailLotteryTap = 'merpay_coupon_lottery_detail_lottery_tap',
  DetailLotteryTutorialDisplay = 'merpay_coupon_lottery_tutorial_1_display',
  DetailMerchantLogoTap = 'merpay_coupon_detail_merchant_logo_tap',
  DetailNetpayUsageTap = 'merpay_coupon_detail_netpay_usage_tap',
  DetailPointShortageModalDisplay = 'merpay_coupon_point_shortage_modal_display',
  DetailPreProvisioningDisplay = 'merpay_coupon_detail_pre_provisioning_display',
  DetailPreProvisioningAddPointDisplay = 'merpay_coupon_detail_pre_provisioning_add_point_display',
  DetailConditionSeeallTap = 'merpay_coupon_detail_condition_seeall_tap',
  DetailSettingProvisioningStartTap = 'merpay_coupon_detail_setting_provisioning_start_tap',
  DetailUseTap = 'merpay_coupon_detail_use_tap',
  ListTap = 'merpay_coupon_list_tap',
  ListTopLogoTap = 'merpay_coupon_list_top_logo_tap',
  ListFilterCloseTap = 'merpay_coupon_list_filter_close_tap',
  ListFilterNearbyTap = 'merpay_coupon_list_filter_nearby_tap',
  ListTopDisplay = 'merpay_coupon_list_top_display',
  ListDisplayView = 'merpay_coupon_list_display_view',
  LpDisplayView = 'lp_display_view',
  LpDisplayTap = 'lp_display_tap',
  CodeProviStartTap = 'merpay_coupon_code_provi_start_tap',
  CodeCloseTap = 'merpay_coupon_code_close_tap',
  CodeDiaplay = 'merpay_coupon_code_display',
  CodePaymentTap = 'merpay_coupon_code_payment_tap',
  NetPayBannerTap = 'merpay_coupon_netpay_banner_tap',
  DetailMerchantRechargeTap = 'merpay_coupon_detail_merchant_recharge_tap',
  DetailWithRecoDisplay = 'merpay_coupon_detail_with_reco_display',
  RecommendCouponTap = 'merpay_recommend_coupon_tap',
  RecomendHeadTapFloatDisplay = 'merpay_coupon_recomend_head_tap_float_display',
  RecomendScrollFloatDisplay = 'merpay_coupon_recomend_scroll_float_display',
  DetailRecommendHeadDisplay = 'merpay_coupon_detail_recommend_head_display',
  DetailMerchantLogoWithRecoTap = 'merpay_coupon_detail_merchant_logo_with_reco_tap',
  DetailUseWithRecoTap = 'merpay_coupon_detail_use_with_reco_tap',
  DetailConditionSeeallWithRecoTap = 'merpay_coupon_detail_condition_seeall_with_reco_tap',
  DetailMerchantRechargeWithRecoTap = 'merpay_coupon_detail_merchant_recharge_with_reco_tap',
  FundsShortageModalDisplay = 'merpay_coupon_funds_shortage_modal_display',
  FundsShortageModalCloseTap = 'merpay_coupon_funds_shortage_modal_close_tap',
  BankAnnounceModalTap = 'merpay_coupon_bank_announce_modal_tap',
  BankAnnounceModalCloseTap = 'merpay_coupon_bank_announce_modal_close_tap',
  PointShortageModalTap = 'merpay_coupon_point_shortage_modal_tap',
  PointShortageModalCloseTap = 'merpay_coupon_point_shortage_modal_close_tap',
  LotteryTutorialOkTap = 'merpay_coupon_lottery_tutorial_ok_tap',
  LotteryTutorialCloseTap = 'merpay_coupon_lottery_tutorial_close_tap',
  ChangeSpTap = 'merpay_coupon_change_sp_tap',
  ChangeSpModeModalDisplay = 'merpay_coupon_change_sp_mode_modal_display',
  ChangeSpModeModalTap = 'merpay_coupon_change_sp_mode_modal_tap',
  ChangeSpModeModalCloseTap = 'merpay_coupon_change_sp_mode_modal_close_tap',
  DetailSpSettingDisplay = 'merpay_coupon_detail_sp_setting_display',
  DetailSpSettingTap = 'merpay_coupon_detail_sp_setting_tap',
}
